import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import { createContext, useContext, useEffect, useState } from "react";
import {
  AUTHENTICATE_MUTATION,
  TIMEZONE_KEY,
  TOKEN_KEY,
  USER_QUERY,
} from "./queries";
import useException from "./use-exception";
import moment from "moment-timezone";
import { useLocation } from "react-router-dom";
import MessageAlert, { TYPE_MSG } from "../components/Message/message";
import { useToaster } from "rsuite";

const authContext = createContext();

function useProvideAuth() {
  const toaster = useToaster();
  const [authenticate, { loading: authLoading }] = useMutation(
    AUTHENTICATE_MUTATION
  );

  const [loadUser, { loading: userLoading, error, data, called }] =
    useLazyQuery(USER_QUERY);

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();
  const { setException } = useException();
  const token = localStorage.getItem(TOKEN_KEY);
  const client = useApolloClient();
  const location = useLocation();

  const removeUser = () => {
    localStorage.removeItem(TIMEZONE_KEY);
    localStorage.removeItem(TOKEN_KEY);
    setUser();
    setLoading(false);
    moment.tz.setDefault();
  };

  useEffect(() => {
    const localToken = localStorage.getItem(TOKEN_KEY);
    if (localToken) {
      loadUser();
    } else {
      removeUser();
    }
  }, [location.pathname]); // eslint-disable-line

  useEffect(() => {
    if (called) {
      if (error !== undefined || (!userLoading && !data?.currentUserClient)) {
        setUser();
      } else if (data && data.currentUserClient) {
        localStorage.setItem(
          TIMEZONE_KEY,
          data?.currentUserClient?.company?.timezone
        );
        moment.tz.setDefault(data?.currentUserClient?.company?.timezone);
        setUser(data.currentUserClient);
        setLoading(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [called, userLoading, error, data]);

  async function signin({ phone, password, schema }) {
    try {
      setLoading(true);
      const { data: authData } = await authenticate({
        variables: {
          phone,
          password,
          schema,
        },
      });
      toaster.push(
        MessageAlert("Login realizado com sucesso.", TYPE_MSG.SUCCESS)
      );
      setUser(authData?.authenticateClient?.user);
      localStorage.setItem(TOKEN_KEY, authData?.authenticateClient?.token);
      setLoading(false);
      return true;
    } catch (error) {
      removeUser();
      setException(error);
    }
  }

  const logout = () => {
    removeUser();
    client.resetStore();
    moment.tz.setDefault();
  };

  return {
    user,
    token,
    signin,
    logout,
    loadUser,
    loading: loading || authLoading || userLoading,
  };
}

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};
