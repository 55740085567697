import React from "react";
import { UilBill, UilEye } from "@iconscout/react-unicons";

function getStatus(data) {
  return data?.totalValue > 0 ? "border-green-600" : "border-red-600";
}

export default function CardPoint({ data, onShowPoint, isMasterOrManager }) {
  return (
    <div
      className={`flex flex-col border-l-4 ${getStatus(
        data
      )} bg-white w-full rounded-md p-4 gap-4`}
    >
      <div className="flex items-center justify-between w-full gap-4">
        <div className="w-14">
          <img
            src="/./ios/180.png"
            alt=""
            width={50}
            className="object-contain"
          />
        </div>
        <div className="flex justify-between w-full">
          <div className="flex flex-col w-full">
            <strong className="text-primary">
              {isMasterOrManager ? data?.name : data?.companyName}
            </strong>
            {data?.totalValue >= 0 && (
              <div className="flex items-center w-full gap-2">
                <span className="text-slate-400">
                  <UilBill size={20} />
                </span>
                <span className="text-slate-400">{data?.totalValue}</span>
              </div>
            )}
          </div>
        </div>
        <button onClick={onShowPoint}>
          <span className="text-slate-400">
            <UilEye size={25} />
          </span>
        </button>
      </div>
    </div>
  );
}
