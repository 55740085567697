import {
  Calendar,
  CheckCircle,
  Clock,
  ClockCounterClockwise,
  HandPalm,
  Lock,
  XCircle,
} from "@phosphor-icons/react";
import moment from "moment";
import React from "react";
import { useAuth } from "../../hooks/use-auth";
import ADateText from "../a-date-text";
import { UilGift, UilTagAlt } from "@iconscout/react-unicons";

function getStatus(data) {
  if (data.canceledAt) {
    return "border-red-600";
  }
  if (moment(data?.date).isBefore(moment())) {
    return "border-green-600";
  }
  return "border-yellow-600";
}

function getName(data) {
  return (
    <div className="flex gap-2">
      {data?.order?.orderService?.map((currentValue, index) => (
        <React.Fragment key={index}>
          {index > 0 && <span>-</span>}
          {currentValue.plan ? (
            <div className="flex gap-2 text-green-500">
              <UilTagAlt />
              <span>{currentValue?.service?.name || currentValue?.name}</span>
            </div>
          ) : (
            currentValue?.service?.name || currentValue?.name
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

function getDuration(data) {
  const min = data?.order?.orderService?.map(({ duration }) => duration);
  return min?.reduce((acc, currentValue) => acc + currentValue, 0);
}

export default function Card({ data, onDiscart, onDiscartRecurrence }) {
  const { user } = useAuth();
  return (
    <div
      className={`flex flex-col border-l-4 ${getStatus(
        data
      )} bg-white w-full rounded-md p-4 gap-4`}
    >
      <div className="flex items-center justify-between w-full pb-4 border-b">
        <div className="flex flex-col justify-center gap-2">
          <div className="flex items-center gap-2">
            <strong>
              <Calendar size={20} weight="bold" />
            </strong>
            <strong>{moment(data?.date).format("DD-MM-YYYY")}</strong>
            <strong>
              <Clock size={20} weight="bold" />
            </strong>
            <strong>
              <ADateText date={data?.date} format="HH:mm" />
            </strong>
            <strong>-</strong>
            <strong>
              <ADateText
                date={data?.date}
                format="HH:mm"
                add={getDuration(data)}
                addType="minute"
              />
            </strong>
          </div>
          <span className="text-slate-400">{getName(data)}</span>
        </div>
        <div className="flex flex-col gap-4">
          {user?.manager && !data?.canceledAt && (
            <button onClick={onDiscart} className="text-red-700">
              <HandPalm size={20} />
            </button>
          )}
          {!!onDiscart &&
            data?.active &&
            !data?.canceledAt &&
            !user?.manager && (
              <button onClick={onDiscart} className="text-red-700">
                <HandPalm size={20} />
              </button>
            )}
          {data?.recurrence && !data?.canceledAt && (
            <button onClick={onDiscartRecurrence}>
              <ClockCounterClockwise size={20} />
            </button>
          )}
        </div>
      </div>
      <div className="flex items-center justify-between w-full gap-4">
        <div className="w-14">
          <img
            src="/./ios/180.png"
            alt=""
            width={50}
            className="object-contain"
          />
        </div>
        <div className="flex flex-col w-full">
          <strong className="text-primary">{data?.company?.name}</strong>
          <span className="text-slate-400">{data?.employee?.name}</span>
        </div>
        <div className="flex flex-col gap-4">
          {!data?.canceledAt &&
            !data?.active &&
            data?.order?.type !== "BLOCKED" &&
            moment().isSameOrAfter(
              moment(data?.date).add(getDuration(data), "minute")
            ) && <CheckCircle size={20} className="text-green-700" />}
          {data?.canceledAt && <XCircle size={20} className="text-red-700" />}
          {data?.order?.type === "BLOCKED" && (
            <Lock size={20} className="text-primary" />
          )}
        </div>
      </div>
    </div>
  );
}
