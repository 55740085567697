import React, { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

import {
  Calendar,
  ClockCounterClockwise,
  Coins,
  CurrencyDollar,
  House,
  Package,
  UserCircle,
} from "@phosphor-icons/react";
import LinkButton from "../Button/LinkButton";
import { useContextValue } from "../../context";
import { useAuth } from "../../hooks/use-auth";

export const routerList = [
  {
    icon: <House size={25} />,
    name: "Início",
    path: "",
    className:
      "flex -mt-10 items-center justify-center rounded-full border-2 border-primary bg-white xs:w-[50px] xs:h-[50px] sm:w-[50px] sm:h-[50px] h-[60px] w-[60px] text-white",
  },
  {
    icon: <CurrencyDollar size={30} />,
    name: "Financeiro",
    path: "/finance",
    className:
      "flex -mt-10 items-center justify-center rounded-full border-2 border-primary bg-white xs:w-[50px] xs:h-[50px] sm:w-[50px] sm:h-[50px] h-[60px] w-[60px] text-white",
    private: true,
    employee: true,
  },
  {
    icon: <ClockCounterClockwise size={30} />,
    name: "Históricos",
    path: "/history",
    className:
      "flex -mt-10 items-center justify-center rounded-full border-2 border-primary bg-white xs:w-[50px] xs:h-[50px] sm:w-[50px] sm:h-[50px] h-[60px] w-[60px] text-white",
    private: true,
  },
  {
    icon: <Calendar size={30} />,
    name: "Agenda",
    path: "/appointment",
    private: true,
    className:
      "flex -mt-10 items-center justify-center rounded-full border-2 border-primary bg-white xs:w-[50px] xs:h-[50px] sm:w-[50px] sm:h-[50px] h-[60px] w-[60px] text-white",
  },
  {
    icon: <Package size={30} />,
    name: "Produtos",
    path: "/list",
    className:
      "flex -mt-10 items-center justify-center rounded-full border-2 border-primary bg-white xs:w-[50px] xs:h-[50px] sm:w-[50px] sm:h-[50px] h-[60px] w-[60px] text-white",
    private: true,
  },
  {
    icon: <UserCircle size={30} />,
    name: "Perfil",
    path: "/profile",
    className:
      "flex -mt-10 items-center justify-center rounded-full border-2 border-primary bg-white xs:w-[50px] xs:h-[50px] sm:w-[50px] sm:h-[50px] h-[60px] w-[60px] text-white",
    private: true,
  },
];

export default function TabBottom() {
  const { user, loading } = useAuth();
  const { setPath, setOpenLogin } = useContextValue();
  const location = useLocation();
  const { company } = useParams();

  const isMasterOrManager = useMemo(
    () => (!loading && user?.master) || user?.manager,
    [user, loading]
  );

  const activeRoute = useMemo(() => {
    return location.pathname.replace(`/${company}`, "");
  }, [location, company]);

  const router = useMemo(() => {
    if (user?.isEmployee) {
      return routerList;
    }
    return routerList.filter((res) => !res.employee);
  }, [user]);

  return (
    <div className="fixed bottom-0 flex items-center justify-between w-full h-16 px-6 mt-auto sm:px-1 bg-primary">
      {router?.map((item) => (
        <LinkButton
          key={item?.name}
          name={
            !isMasterOrManager && item.name === "Produtos"
              ? "Pontos"
              : item.name
          }
          path={company + item?.path}
          active={company + activeRoute}
          icon={item?.icon}
          className={item?.className}
          routePrivate={item?.private}
          routeEmployee={item?.employee}
          onAction={() => {
            setOpenLogin(true);
            setPath(company + item?.path);
          }}
        />
      ))}
    </div>
  );
}
