import React, { useMemo } from "react";
import { useToaster } from "rsuite";
import * as Yup from "yup";
import { useFormikContext } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";
import {
  CREATE_PRODUCT_MUTATION,
  GET_PRODUCT,
  GET_PRODUCTS,
  UPDATE_PRODUCT_MUTATION,
} from "../queries";
import FormFields from "../../../components/Form/form-fields";
import { InputTypes } from "../../../components/Input";
import Button from "../../../components/Button";
import Spinner from "../../../components/Spinner";
import useException from "../../../hooks/use-exception";
import MessageAlert, { TYPE_MSG } from "../../../components/Message/message";
import Modal from "../../../components/Modal";
import AForm from "../../../components/Form";
import CurrencyMask from "../../../components/Mask/currency-mask";
import PercentageMask from "../../../components/Mask/percentage-mask";

function Form({ formLoading }) {
  const { initialValues, values } = useFormikContext();

  return (
    <div className="flex flex-col w-full gap-4 py-4">
      <FormFields
        loading={formLoading}
        fields={[
          {
            type: InputTypes.TEXT,
            name: "name",
            label: "Nome",
          },
          {
            type: InputTypes.TEXT,
            name: "price",
            label: "Preço",
            mask: CurrencyMask,
          },
          {
            type: InputTypes.NUMBER,
            name: "quantity",
            label: "Estoque",
          },
          {
            type: InputTypes.CHECKBOX,
            name: "comission",
            label: "Comissão",
          },
          {
            type: InputTypes.TEXT,
            name: "productFixed",
            label: "Valor por produto",
            mask: CurrencyMask,
            visible: !values.comission,
          },
          {
            type: InputTypes.TEXT,
            name: "productPercent",
            label: "Porcentagem por produto",
            mask: PercentageMask,
            visible: !values.comission,
          },
          {
            type: InputTypes.CHECKBOX,
            name: "active",
            label: "Ativo",
          },
        ]}
      />
      <div className="fixed flex flex-col gap-4 bottom-4 left-4 right-4">
        <Button
          label="Salvar"
          className="w-full p-3 text-white rounded-md bg-primary hover:bg-tertiary"
          type="submit"
          disabled={values === initialValues}
          loading={formLoading}
          icon={<Spinner />}
        />
      </div>
    </div>
  );
}

export default function ModalProductForm({ open, onClose, id }) {
  const toaster = useToaster();
  const { setException } = useException();

  const { data, loading } = useQuery(GET_PRODUCT, {
    variables: {
      id: Number(id) || null,
    },
    skip: !id,
  });

  const [create, { loading: createLoading }] = useMutation(
    CREATE_PRODUCT_MUTATION
  );

  const [update, { loading: updateLoading }] = useMutation(
    UPDATE_PRODUCT_MUTATION
  );

  async function onSubmit(values) {
    try {
      if (id) {
        await update({
          variables: {
            product: {
              id: id,
              name: values.name,
              quantity: values.quantity,
              price: values.price,
              active: values.active,
              comission: values.comission,
              productFixed: values.productFixed,
              productPercent: values.productPercent,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [getOperationName(GET_PRODUCTS)],
        });
        toaster.push(MessageAlert("Atualizado com sucesso.", TYPE_MSG.SUCCESS));
      } else {
        await create({
          variables: {
            product: {
              name: values.name,
              quantity: values.quantity,
              price: values.price,
              active: values.active,
              comission: values.comission,
              productFixed: Number(values.productFixed),
              productPercent: Number(values.productPercent),
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [getOperationName(GET_PRODUCTS)],
        });
        toaster.push(MessageAlert("Cadastrado com sucesso.", TYPE_MSG.SUCCESS));
      }

      onClose();
    } catch (error) {
      setException(error);
    }
  }

  const formLoading = useMemo(
    () => loading || createLoading || updateLoading,
    [loading, createLoading, updateLoading]
  );

  const initialValues = useMemo(() => {
    if (!loading && data?.product) {
      return {
        ...data?.product,
      };
    }
    return {
      name: "",
      price: "",
      quantity: "",
      active: true,
      comission: false,
    };
  }, [data, loading]);

  return (
    <Modal
      title="Produto"
      subTitle="Dados do produto"
      open={open}
      onClose={onClose}
      img="/./ios/180.png"
    >
      <div className="flex h-full overflow-auto mb-[60px]">
        <AForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          formComponent={Form}
          formProps={{ formLoading, id }}
          validationSchema={Yup.object()
            .shape({
              name: Yup.string().required("Campo obrigatório"),
              price: Yup.string().required("Campo obrigatório"),
              quantity: Yup.string().required("Campo obrigatório"),
              productFixed: Yup.number()
                .nullable()
                .test(
                  "comission",
                  "Campo obrigatório",
                  function (value, context) {
                    if (context?.parent?.comission) {
                      if (!context?.parent?.productPercent && !!value) {
                        return true;
                      }

                      if (!!context?.parent?.productPercent && !value) {
                        return true;
                      }

                      if (!!context?.parent?.productPercent && !!value) {
                        return false;
                      }

                      return false;
                    }
                    return true;
                  }
                ),
              productPercent: Yup.number()
                .nullable()
                .test(
                  "comission",
                  "Campo obrigatório",
                  function (value, context) {
                    if (context?.parent?.comission) {
                      if (!context?.parent?.productFixed && !!value) {
                        return true;
                      }

                      if (!!context?.parent?.productFixed && !value) {
                        return true;
                      }

                      if (!!context?.parent?.productFixed && !!value) {
                        return false;
                      }

                      return false;
                    }
                    return true;
                  }
                ),
            })
            .test("geral", "", function (value) {
              if (!!value?.comission) {
                if (!!value?.productFixed && !!value?.productPercent) {
                  setException("Erro", "Escolha uma opção apenas de comissão.");
                }
              }
            })}
        />
      </div>
    </Modal>
  );
}
