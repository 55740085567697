import React, { useEffect, useMemo, useState } from "react";
import { UilMultiply } from "@iconscout/react-unicons";
import { Drawer, useToaster } from "rsuite";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_APPOINTMENT_RECURRENCE_MUTATION,
  CREATE_WAITING_LIST_MUTATION,
  GET_APPOINTMENTS,
  GET_DURATION,
  GET_OPERATIONS,
  GET_USERS,
} from "../../queries";
import moment from "moment";
import { useAuth } from "../../../../hooks/use-auth";
import { useContextValue } from "../../../../context";
import useException from "../../../../hooks/use-exception";
import Animation from "../../../../Animation/Animation";
import Calendar from "../../../../Animation/calendar.json";
import MessageAlert, { TYPE_MSG } from "../../../../components/Message/message";
import CalendarNavigator from "../../../../components/CalendarNavigator";
import AlertModal from "../../../../components/Modal/AlertModal";
import { getOperationName } from "@apollo/client/utilities";
import AForm from "../../../../components/Form";
import FormFields from "../../../../components/Form/form-fields";
import { InputTypes } from "../../../../components/Input";
import { useFormikContext } from "formik";
import useDebounce from "../../../../hooks/use-debounce";
import FinanceUserForm from "../../../finance/views/finance-user-form";

const recurrence = [
  { id: 1, name: "1 Mês", value: 1 },
  { id: 2, name: "3 Mêses", value: 3 },
  { id: 3, name: "6 Mêses", value: 6 },
  { id: 4, name: "12 Mêses", value: 12 },
  { id: 5, name: "24 Mêses", value: 24 },
];

const recurrenceDay = [
  { id: 1, name: "1 Semana (7 dias)", value: 7 },
  { id: 2, name: "2 Semana (14 dias)", value: 14 },
  { id: 3, name: "3 Semana (21 dias)", value: 21 },
  { id: 4, name: "1 Mês (30 dias)", value: 30 },
];

function Form({ formLoading, setValues, setOpenUser }) {
  const { values, setFieldValue } = useFormikContext();
  const [text, setText] = useState();
  const debounced = useDebounce(text);

  const { data, loading } = useQuery(GET_USERS, {
    variables: {
      filter: {
        active: true,
        companyIdentifier: 0,
        employeeIdentifier: 0,
        name: debounced,
        phone: debounced,
      },
    },
  });

  useEffect(() => {
    setValues(values);
  }, [values]);

  const loadingQuerys = useMemo(() => formLoading, [formLoading]);

  return (
    <div className="flex flex-col items-center justify-center w-full h-full gap-4 py-4">
      <div className="flex flex-col justify-center w-full h-full gap-4 items-cente">
        <FormFields
          loading={loadingQuerys}
          fields={[
            {
              type: InputTypes.AUTOCOMPLETE,
              label: "Cliente",
              name: "user",
              width: "w-full",
              options:
                data?.userClients?.map((res) => {
                  return {
                    ...res,
                    name: `${res.name} - ${res?.phone}`,
                  };
                }) || [],
              onButton: true,
              onButtonAction: () => {
                setOpenUser(true);
              },
              onChange: () => {
                setFieldValue("observation", "");
              },
              onChangeInput: (e) => {
                setText(e);
              },
              loading,
              onInputChange: (event, newInputValue, reason) => {
                if (reason === "clear") {
                  setText();
                }
              },
            },
            {
              type: InputTypes.AUTOCOMPLETE,
              label: "A cada",
              name: "interval",
              width: "w-full",
              options: recurrenceDay,
            },
            {
              type: InputTypes.AUTOCOMPLETE,
              label: "Durante",
              name: "time",
              width: "w-full",
              options: recurrence,
            },
          ]}
        />
      </div>
    </div>
  );
}

export default function ModalAppointmentRecurrence({
  open,
  onClose,
  selected,
  employee,
  listServices,
}) {
  const { user, loading } = useAuth();
  const toaster = useToaster();
  const { selDate, selectedDay, selectedHour, setOpenLogin, setPath } =
    useContextValue();
  const { setException } = useException();
  const [animationLoading, setAnimationLoading] = useState();
  const [values, setValues] = useState();
  const [openModal, setOpenModal] = useState();
  const [list, setList] = useState([]);
  const [openUser, setOpenUser] = useState();

  const { data, loading: appointmentLoading } = useQuery(GET_APPOINTMENTS, {
    variables: {
      filter: {
        canceledAt: true,
        employeeId: employee?.id,
        date: moment(selDate),
      },
    },
    skip: !employee?.id || !selDate,
  });

  const services = useMemo(() => {
    const duration = listServices?.map(({ duration }) => duration);
    const price = listServices?.map(({ price }) => price);
    return {
      duration: duration?.reduce((acc, currentValue) => acc + currentValue, 0),
      price: price?.reduce((acc, currentValue) => acc + currentValue, 0),
    };
  }, [listServices]);

  const isEmployee = useMemo(
    () =>
      !loading && user?.isEmployee && user?.employeeIdentifier === employee.id,
    [loading, user, employee]
  );

  const { data: dataOperation, loading: operationLoading } = useQuery(
    GET_OPERATIONS,
    {
      variables: {
        filter: { employeeId: employee?.id },
      },
      skip: !employee?.id,
    }
  );

  const { data: dataService, loading: serviceLoading } = useQuery(
    GET_DURATION,
    {
      variables: {
        filter: { employeeId: employee?.id },
      },
      skip: !employee?.id,
    }
  );

  const [createAppointmentRecurrence, { loading: createLoading }] = useMutation(
    CREATE_APPOINTMENT_RECURRENCE_MUTATION
  );

  const [createWaitingList, { loading: createWaitingListLoading }] =
    useMutation(CREATE_WAITING_LIST_MUTATION);

  async function onWaitingList() {
    try {
      await createWaitingList({
        variables: {
          waitingList: {
            date: moment(selDate, "YYYY-MM-DD HH:mm:ss").format(),
            serviceId: selected.id,
            employeeId: employee?.id,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [getOperationName(GET_APPOINTMENTS)],
      });
      setOpenModal(false);
      onClose();
      toaster.push(
        MessageAlert("Adicionado na lista de espera.", TYPE_MSG.SUCCESS)
      );
    } catch (error) {
      setException(error);
    }
  }

  const queryLoading = useMemo(
    () =>
      loading ||
      operationLoading ||
      serviceLoading ||
      appointmentLoading ||
      createLoading ||
      createWaitingListLoading,
    [
      loading,
      appointmentLoading,
      serviceLoading,
      operationLoading,
      createLoading,
      createWaitingListLoading,
    ]
  );

  async function onSubmit() {
    if (values?.user?.id && values?.interval?.value && values?.time?.value) {
      try {
        const create = await createAppointmentRecurrence({
          variables: {
            appointment: {
              employeeId: employee?.id,
              date: moment(`${selectedHour}`, "YYYY-MM-DD HH:mm:ss").format(),
              interval: values?.interval?.value,
              time: values?.time?.value,
              order: {
                userId: values?.user?.id,
                price: services.price,
                totalService: services.price,
                totalProduct: 0,
                services: listServices.map((res) => ({
                  id: res.id,
                  name: res.name,
                  price: res.price,
                  quantity: 1,
                  duration: res.duration,
                })),
                totalDuration: services.duration,
              },
            },
          },
        });

        setList(create?.data?.createAppointmentRecurrence);
        setAnimationLoading(true);
        setValues();
      } catch (error) {
        setAnimationLoading(false);
        setException(error);
      }
    } else {
      setException("Erro", "Preencha todos os campos.");
    }
  }

  return (
    <Drawer
      backdrop="static"
      open={open}
      onClose={onClose}
      placement="bottom"
      style={{
        display: "flex",
        backgroundColor: "transparent",
        height: "100vh",
      }}
    >
      <div className="flex flex-col bg-white h-auto rounded-tl-[50px] fixed bottom-0 w-full gap-4">
        <div className="flex w-full gap-2">
          <div className="flex ml-10 -mt-10 rounded-md w-28 h-28">
            <img
              src="/./ios/180.png"
              alt=""
              width={100}
              height={100}
              className="object-cover"
            />
          </div>
          <div className="flex flex-col flex-1 p-4">
            <strong className="text-primary">{employee?.name}</strong>
            <span>{employee?.employeeType?.name}</span>
          </div>
        </div>
        <button
          onClick={onClose}
          className="absolute flex items-center justify-center w-10 h-10 bg-white rounded-full shadow-md -top-6 right-5"
        >
          <UilMultiply size={20} weight="fill" className="text-primary" />
        </button>
        {listServices?.map((res, i) => (
          <div
            key={i}
            className="flex items-center justify-between w-full px-4"
          >
            <strong className="w-2/3 text-primary">{res?.name}</strong>
            <strong className="text-primary ">{res?.duration} Minutos</strong>
          </div>
        ))}
        <div className="flex flex-col flex-1 gap-4 px-4 pb-4 overflow-auto">
          {isEmployee && (
            <AForm
              formComponent={Form}
              formProps={{ formLoading: queryLoading, setValues, setOpenUser }}
            />
          )}
          <CalendarNavigator
            operations={dataOperation?.operations}
            appointments={data?.appointments}
            serviceDuration={services?.duration}
            duration={dataService?.serviceDuration?.duration}
            loading={queryLoading}
          />
          {selectedDay && selectedHour && user?.id && (
            <div className="flex w-full">
              <button
                onClick={onSubmit}
                type="submit"
                className="flex items-center justify-center w-full p-4 text-white rounded-md bg-primary"
              >
                Agendar
              </button>
            </div>
          )}
          {!user?.id && (
            <div className="flex w-full">
              <button
                onClick={() => {
                  setOpenLogin(true);
                  setPath();
                }}
                className="flex items-center justify-center w-full p-4 text-white rounded-md bg-primary"
              >
                Entrar
              </button>
            </div>
          )}
          {/* {selectedDay &&
            !listHours?.length &&
            available &&
            user?.id &&
            !isEmployee &&
            !moment(moment(selDate).format("DD-MM-YYYY")).isSameOrAfter(
              moment().format("DD-MM-YYYY")
            ) &&
            !queryLoading && (
              <div className="flex w-full">
                <button
                  onClick={() => setOpenModal(true)}
                  className="flex items-center justify-center w-full p-4 text-white bg-green-600 rounded-md"
                >
                  Lista de Espera
                </button>
              </div>
            )} */}
        </div>
        {animationLoading && (
          <div className="fixed flex items-center justify-center w-full h-full -translate-x-1/2 -translate-y-1/2 bg-black top-1/2 left-1/2 bg-opacity-90">
            <Animation
              width={150}
              height={150}
              animation={Calendar}
              loop={false}
              onAction={(e) => {
                if (e === "complete") {
                  setAnimationLoading(false);
                  onClose(list);
                }
              }}
            />
          </div>
        )}
        {openModal && (
          <AlertModal
            open={openModal}
            cancelAction={() => {
              setOpenModal(false);
            }}
            title="Agendamento"
            message="Deseja entrar na lista de espera?"
            confirLabel="Sim"
            cancelLabel="Não"
            confirmAction={onWaitingList}
          />
        )}
        {openUser && (
          <FinanceUserForm
            open={openUser}
            onClose={() => {
              setOpenUser(false);
            }}
          />
        )}
      </div>
    </Drawer>
  );
}
