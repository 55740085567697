import { useMemo } from "react";
import { useField, useFormikContext } from "formik";
import { styled } from "@mui/styles";
import { Autocomplete, createFilterOptions, TextField } from "@mui/material";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#575757",
    fontSize: 14,
  },
  "& label": {
    color: "#575757",
    fontSize: 14,
  },
  "& input": {
    color: "#575757",
    fontSize: 14,
  },

  "& .MuiInput-underline:after": {
    borderBottomColor: "#575757",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#575757",
    },
    "&:hover fieldset": {
      borderColor: "#575757",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#575757",
    },
    "&:after fieldset": {
      borderColor: "#575757",
    },
  },
});

function AutocompleteMultipleField({
  attribute: {
    options = [],
    itemValue = "value",
    itemLabel = "label",
    name,
    label,
    disabled,
    onChangeInput,
    onChange,
    freeSolo = false,
    multiple = true,
    autoComplete = "off",
    required,
    index,
    limitTags = 1,
    groupBy,
    width,
    variant = "outlined",
    visible = false,
  },
  loading,
}) {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  const { values } = useFormikContext();
  const filter = createFilterOptions();

  const disabledWrapper = () => {
    if (loading) {
      return true;
    }

    return typeof disabled === "function" ? disabled(values, index) : disabled;
  };

  const value = useMemo(() => {
    if (!field.value && multiple) {
      return [];
    }

    return field.value ? field.value : null;
  }, [field, multiple]);

  return (
    <div className={`${visible ? "hidden" : "flex"} ${width}`}>
      {!loading && (
        <div className={`flex ${width}`}>
          <Autocomplete
            {...field}
            disableCloseOnSelect={multiple}
            id={name.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "")}
            value={value}
            freeSolo={freeSolo}
            multiple={multiple}
            limitTags={limitTags}
            fullWidth={true}
            options={options}
            disabled={disabledWrapper()}
            groupBy={groupBy}
            getOptionSelected={(option, val) => {
              return option[itemValue] === val[itemValue];
            }}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            getOptionLabel={(value) => value?.name || ""}
            loading={loading}
            filterOptions={(opt, params) => {
              const filtered = filter(opt, params);

              if (!filtered?.length) {
                if (params.inputValue !== "") {
                  let { inputValue } = params;

                  filtered.push({
                    inputValue,
                    [itemLabel]: "Sem resultado",
                  });
                }
              }
              return filtered;
            }}
            loadingText="Carregando..."
            onChange={(_, e) => {
              if (onChange) {
                onChange(e);
              }
              setFieldValue(name, e);
            }}
            ListboxProps={{ style: { maxHeight: 150 } }}
            renderInput={(params) => (
              <CssTextField
                {...params}
                autoComplete={autoComplete}
                label={label}
                error={!!meta.error}
                helperText={meta.error}
                variant={variant}
                onChange={(e) => {
                  if (onChange) {
                    onChange(e.target.value, values);
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  autoComplete,
                  inputProps: {
                    ...params.inputProps,
                    autoComplete,
                  },
                  endAdornment: <>{params?.InputProps?.endAdornment}</>,
                }}
              />
            )}
          />
        </div>
      )}
    </div>
  );
}

export default AutocompleteMultipleField;
