import { gql } from "@apollo/client";

export const REGISTER_NOTIFICATION_MUTATION = gql`
  mutation registerNotification($notification: NotificationInput) {
    registerNotification(notification: $notification)
  }
`;
export const SEND_NOTIFICATION_MUTATION = gql`
  mutation sendNotification($notification: NotificationInput) {
    sendNotification(notification: $notification)
  }
`;
