import React, { useState } from 'react';
import * as Yup from 'yup';
import AForm from '../../../components/Form';
import Button from '../../../components/Button';
import Spinner from '../../../components/Spinner';
import { InputTypes } from '../../../components/Input';
import APhoneMask from '../../../components/Mask/a-phone-number.mask';
import FormFields from '../../../components/Form/form-fields';
import useException from '../../../hooks/use-exception';
import { useToaster } from 'rsuite';
import { useMutation } from '@apollo/client';
import { VERYFY_PHONE_MUTATION } from '../queries';
import MessageAlert, { TYPE_MSG } from '../../../components/Message/message';

function Form({ loading }) {
  return (
    <div className="flex flex-col items-center justify-center w-full gap-4 py-4">
      <FormFields
        loading={loading}
        fields={[
          {
            type: InputTypes.TEXT,
            label: 'Whastapp',
            name: 'phone',
            width: 'w-full',
            mask: APhoneMask,
          },
          {
            type: InputTypes.TEXT,
            label: 'E-mail',
            name: 'email',
            width: 'w-full',
          },
        ]}
      />
      <div className="flex flex-col w-full gap-4">
        <Button
          label="Verificar"
          className="w-full p-3 text-white rounded-md bg-primary hover:bg-tertiary"
          type="submit"
          icon={<Spinner />}
          loading={loading}
        />
      </div>
    </div>
  );
}

export default function PhoneForm({ onStep, onPhone, onEmail }) {
  const { setException } = useException();
  const toaster = useToaster();

  const [verifyPhoneAndSendToken, { loading: resetLoading }] = useMutation(
    VERYFY_PHONE_MUTATION,
  );

  async function onSubmit(values) {
    try {
      await verifyPhoneAndSendToken({
        variables: {
          phone: values.phone,
          email: values.email,
        },
      });
      toaster.push(
        MessageAlert('Um código foi enviado para seu Email.', TYPE_MSG.SUCCESS),
      );
      onPhone(values?.phone);
      onEmail(values?.email);
      onStep(2);
    } catch (error) {
      setException(error);
    }
  }

  return (
    <AForm
      initialValues={{ phone: '', email: '' }}
      onSubmit={onSubmit}
      formComponent={Form}
      formProps={{ loading: resetLoading }}
      validationSchema={Yup.object({
        phone: Yup.string().required('Campo obrigatório'),
        email: Yup.string().required('Campo obrigatório'),
      })}
    />
  );
}
