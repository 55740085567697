import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { TIMEZONE_KEY, TOKEN_GUEST, TOKEN_KEY } from "./hooks/queries";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(TOKEN_KEY);
  const timezone = localStorage.getItem(TIMEZONE_KEY);

  const url = window.location.href;
  const parts = url.split("/");
  let tenancy = parts[3];

  return {
    headers: {
      ...headers,
      Authorization: token ? `${token}` : TOKEN_GUEST,
      schema: tenancy,
      timezone,
    },
  };
});

const versionLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    return response;
  });
});

const linkError = onError(({ graphQLErrors }) => {
  if (
    graphQLErrors &&
    graphQLErrors[0]?.extensions?.code === "UNAUTHENTICATED"
  ) {
    localStorage.removeItem(TOKEN_KEY);
    console.log("UNAUTHENTICATED", graphQLErrors);
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([
    linkError,
    versionLink.concat(authLink.concat(httpLink)),
  ]),
  cache: new InMemoryCache({}),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
  timeout: 10000,
});

export default client;
