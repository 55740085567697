import { MapPin } from "@phosphor-icons/react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/styles";
import Autocomplete from "@mui/material/Autocomplete";
import React from "react";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    fontSize: 14,
  },
  "& label": {
    fontSize: 14,
  },
  "& input": {
    fontSize: 14,
  },
  "& .MuiInput-underline:after": {
    border: "none",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
    "&:after fieldset": {
      border: "none",
    },
  },
});

export default function AutoComplete({ data = [], label, onChange, disabled }) {
  return (
    <div
      className={`${
        disabled ? "hidden" : "flex"
      } items-center w-full gap-4 px-4 rounded-md bg-quaternary`}
    >
      <span className="text-xl text-orange-600">
        <MapPin />
      </span>
      <span className="flex w-full">
        <Autocomplete
          disablePortal
          options={data}
          fullWidth
          noOptionsText="Sem resultado"
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <CssTextField {...params} placeholder={label} />
          )}
          onChange={onChange}
        />
      </span>
    </div>
  );
}
