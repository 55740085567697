import { Clock } from "@phosphor-icons/react";
import React from "react";
import { Range } from "react-range";

function getTrackBackground({
  values,
  colors,
  min,
  max,
  direction = "to right",
  rtl = false,
}) {
  if (rtl && direction === "to right") {
    direction = "to left";
  } else if (rtl && "to left") {
    direction = "to right";
  }

  const progress = values
    .slice(0)
    .sort((a, b) => a - b)
    .map((value) => ((value - min) / (max - min)) * 100);
  const middle = progress.reduce(
    (acc, point, index) =>
      `${acc}, ${colors[index]} ${point}%, ${colors[index + 1]} ${point}%`,
    ""
  );
  return `linear-gradient(${direction}, ${colors[0]} 0%${middle}, ${
    colors[colors.length - 1]
  } 100%)`;
}

export default function SliderTimer({
  onChange,
  value,
  onSaved,
  min = 0,
  max = 24,
}) {
  function convertToTimeFormat(val) {
    let hours = Math.floor(val);
    let minutes = Math.round((val % 1) * 100);

    minutes = Math.round(minutes / 5) * 5;

    if (minutes >= 60) {
      hours += 1;
      minutes %= 60;
    }

    let minutesFormated = minutes.toString().padStart(2, "0");

    return hours + ":" + minutesFormated;
  }

  const handleChange = (val) => {
    onChange(val);
    onSaved([convertToTimeFormat(val[0]), convertToTimeFormat(val[1])]);
  };

  return (
    <div>
      <Range
        step={0.05}
        min={min}
        max={max}
        values={value}
        onChange={handleChange}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            style={{
              ...props.style,
              background: getTrackBackground({
                values: value,
                colors: ["#ccc", "#5cc6d0", "#ccc"],
                min,
                max,
              }),
            }}
            className="w-full h-2 rounded-full bg-[#ccc]"
          >
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            style={{
              ...props.style,
              borderRadius: "50%",
            }}
            className="w-6 h-6 shadow-md outline-none bg-primary"
          />
        )}
      />
      <div className="flex justify-between py-4 text-slate-600">
        <div className="flex items-center gap-2">
          <span>
            <Clock size={20} />
          </span>
          <span>{convertToTimeFormat(value[0])}</span>
        </div>
        <div className="flex items-center gap-2">
          <span>
            <Clock size={20} />
          </span>
          <span>{convertToTimeFormat(value[1])}</span>
        </div>
      </div>
    </div>
  );
}
