import React, { useState } from "react";
import formatCurrency from "../../../utils/formatCurrency";
import { Calendar, Eye, Lock, Trash } from "@phosphor-icons/react";
import Spinner from "../../../components/Spinner";
import Modal from "../../../components/Modal";
import OrderDetail from "./order-detail";
import { useQuery } from "@apollo/client";
import { GET_ORDERS } from "../queries";
import Animation from "../../../Animation/Animation";
import Hand from "../../../Animation/hand.json";

export default function FinanceInvoicingCard({
  open,
  onClose,
  onDelete,
  filter,
}) {
  const [id, setId] = useState();
  const [openOrderDetail, setOpenOrderDetail] = useState();

  const { data, loading } = useQuery(GET_ORDERS, {
    variables: {
      filter: { ...filter, canceledAt: true },
    },
    skip: !filter,
  });

  return (
    <>
      <Modal
        title="Extrato"
        subTitle="Lista de comandas"
        open={open}
        onClose={onClose}
        img="/./ios/180.png"
      >
        {loading ? (
          <div className="flex items-center justify-center w-full h-full bg-opacity-50">
            <Animation width={150} height={150} animation={Hand} loop={true} />
          </div>
        ) : (
          <div className="flex flex-col w-full h-auto gap-2 ">
            {data?.orders
              ?.filter((res) => res)
              .map((item, i) => (
                <div
                  key={i}
                  className={`flex w-full border-l-8 ${
                    item?.type === "RECEIVE"
                      ? "border-green-600"
                      : item?.type === "CONSUME"
                      ? "border-primary"
                      : item?.type === "BLOCKED"
                      ? "border-yellow-500"
                      : "border-red-600"
                  } rounded-md shadow bg-secondary`}
                >
                  <div className="flex items-center w-full p-4">
                    <strong>#{item?.id}</strong>
                  </div>
                  <div className="flex items-center justify-end w-full p-2 ">
                    <button
                      className="cursor-pointer"
                      onClick={() => {
                        setOpenOrderDetail(true);
                        setId(item.id);
                      }}
                      disabled={loading}
                    >
                      <Eye size={20} />
                    </button>
                  </div>
                  <div className="flex items-center w-full p-4">
                    <strong
                      className={`w-full ${
                        item?.type === "RECEIVE"
                          ? "text-green-600"
                          : item?.type === "CONSUME"
                          ? "text-primary"
                          : item?.type === "BLOCKED"
                          ? "text-yellow-500"
                          : "text-red-600"
                      }`}
                    >
                      {formatCurrency(item?.price)}
                    </strong>
                  </div>
                  <div className="flex items-center p-4">
                    {item?.finance && (
                      <button
                        className="cursor-pointer"
                        onClick={() => onDelete(item)}
                        disabled={loading}
                      >
                        {loading ? <Spinner /> : <Trash size={20} />}
                      </button>
                    )}
                    {item.type === "BLOCKED" && <Lock size={20} />}
                    {!item.finance && item.type !== "BLOCKED" && (
                      <Calendar size={20} />
                    )}
                  </div>
                </div>
              ))}
          </div>
        )}
      </Modal>
      {openOrderDetail && (
        <OrderDetail
          open={openOrderDetail}
          onClose={() => {
            setOpenOrderDetail();
            setId();
          }}
          id={id}
        />
      )}
    </>
  );
}
