import React, { useState } from "react";
import formatCurrency from "../../../utils/formatCurrency";
import { Calendar, Eye } from "@phosphor-icons/react";
import Modal from "../../../components/Modal";
import OrderDetail from "./order-detail";
import { GET_APPOINTMENTS } from "../queries";
import { useQuery } from "@apollo/client";
import moment from "moment";

export default function FinanceAppointmentsCard({ open, onClose, filter }) {
  const [id, setId] = useState();
  const [openOrderDetail, setOpenOrderDetail] = useState();

  const { data, loading } = useQuery(GET_APPOINTMENTS, {
    variables: {
      filter,
    },
    skip: !filter,
  });

  return (
    <>
      <Modal
        title="Agendamentos"
        subTitle="Lista de agendamentos"
        open={open}
        onClose={onClose}
        img="/./ios/180.png"
      >
        <div className="flex flex-col w-full h-auto gap-2 ">
          {data?.appointments
            ?.filter((res) => res?.order?.type !== "BLOCKED")
            ?.map((item) => (
              <div
                key={item.id}
                className={`flex w-full border-l-8 ${
                  moment(item.date).isAfter(moment()) && !item?.canceledAt
                    ? "border-yellow-500"
                    : !item?.canceledAt
                    ? "border-green-600"
                    : "border-red-600"
                } rounded-md shadow bg-secondary`}
              >
                <div className="flex items-center w-full gap-2 p-4">
                  <strong>#{item?.id}</strong>
                  <strong>
                    {moment(item.date).isAfter(moment()) && !item?.canceledAt
                      ? "Pendente"
                      : !item?.canceledAt
                      ? "Finalizado"
                      : "Cancelado"}
                  </strong>
                </div>
                <div className="flex items-center justify-end w-full p-2 ">
                  <button
                    className="cursor-pointer"
                    onClick={() => {
                      setOpenOrderDetail(true);
                      setId(item?.order?.id);
                    }}
                    disabled={loading}
                  >
                    <Eye size={20} />
                  </button>
                </div>
                <div className="flex items-center w-full p-4">
                  <strong
                    className={`w-full ${
                      moment(item.date).isAfter(moment()) && !item?.canceledAt
                        ? "text-yellow-500"
                        : !item?.canceledAt
                        ? "text-green-600"
                        : "text-red-600"
                    }`}
                  >
                    {formatCurrency(item?.order?.price)}
                  </strong>
                </div>
                <div className="flex items-center p-4">
                  <Calendar size={20} />
                </div>
              </div>
            ))}
        </div>
      </Modal>
      {openOrderDetail && (
        <OrderDetail
          open={openOrderDetail}
          onClose={() => {
            setOpenOrderDetail();
            setId();
          }}
          id={id}
        />
      )}
    </>
  );
}
