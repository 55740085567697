export function formatWeek(week) {
  switch (week) {
    case "Domingo":
      return "Dom";
    case "Segunda":
      return "Seg";
    case "Terça":
      return "Ter";
    case "Quarta":
      return "Qua";
    case "Quinta":
      return "Qui";
    case "Sexta":
      return "Sex";
    case "Sábado":
      return "Sab";
    default:
      break;
  }
}

export function formatWeekDay(week) {
  switch (week) {
    case 0:
      return "Dom";
    case 1:
      return "Seg";
    case 2:
      return "Ter";
    case 3:
      return "Qua";
    case 4:
      return "Qui";
    case 5:
      return "Sex";
    case 6:
      return "Sab";
    default:
      break;
  }
}
