import { Formik } from "formik";
import React from "react";

function AForm({
  onSubmit,
  validationSchema,
  initialValues = {},
  enableReinitialize = true,
  validateOnBlur = false,
  validateOnChange = false,
  formComponent: FormComponent,
  formProps = {},
}) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize={enableReinitialize}
      validationSchema={validationSchema}
      validateOnBlur={validateOnBlur}
      validateOnChange={validateOnChange}
    >
      {(props) => (
        <form
          onSubmit={props.handleSubmit}
          autoComplete="off"
          className="flex flex-col items-center w-full h-auto overflow-auto"
        >
          <FormComponent {...formProps} />
        </form>
      )}
    </Formik>
  );
}

export default AForm;
