import React, { useMemo } from "react";
import * as Yup from "yup";
import { useToaster } from "rsuite";
import { useMutation, useQuery } from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";
import FormFields from "../../../components/Form/form-fields";
import { InputTypes } from "../../../components/Input";
import APhoneMask from "../../../components/Mask/a-phone-number.mask";
import Button from "../../../components/Button";
import Spinner from "../../../components/Spinner";
import {
  CREATE_SUPPLIER_MUTATION,
  GET_SUPPLIER,
  GET_SUPPLIERS,
  UPDATE_SUPPLIER_MUTATION,
} from "../queries";
import useException from "../../../hooks/use-exception";
import MessageAlert, { TYPE_MSG } from "../../../components/Message/message";
import Modal from "../../../components/Modal";
import AForm from "../../../components/Form";

function Form({ formLoading }) {
  return (
    <div className="flex flex-col items-center justify-center w-full gap-4 py-4">
      <FormFields
        loading={formLoading}
        fields={[
          {
            type: InputTypes.TEXT,
            label: "Nome",
            name: "name",
            width: "w-full",
          },
          {
            type: InputTypes.TEXT,
            label: "Whastapp",
            name: "phone",
            width: "w-full",
            mask: APhoneMask,
          },
        ]}
      />
      <div className="flex flex-col w-full gap-2">
        <Button
          label="Cadastrar"
          className="w-full p-3 text-white rounded-md bg-primary hover:bg-tertiary"
          type="submit"
          icon={<Spinner />}
          loading={formLoading}
        />
      </div>
    </div>
  );
}

export default function SupplierForm({ id, open, onClose }) {
  const { setException } = useException();
  const toaster = useToaster();

  const { data, loading } = useQuery(GET_SUPPLIER, {
    variables: {
      id: Number(id) || null,
    },
    skip: !id,
  });

  const [create, { loading: createLoading }] = useMutation(
    CREATE_SUPPLIER_MUTATION
  );

  const [update, { loading: updateLoading }] = useMutation(
    UPDATE_SUPPLIER_MUTATION
  );

  async function onSubmit(values) {
    try {
      if (id) {
        await update({
          variables: {
            supplier: {
              id: Number(id),
              name: values?.name,
              phone: values?.phone,
              active: true,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [getOperationName(GET_SUPPLIERS)],
        });
        toaster.push(
          MessageAlert("Fornecedor atualizado com sucesso.", TYPE_MSG.SUCCESS)
        );
      } else {
        await create({
          variables: {
            supplier: {
              name: values?.name,
              phone: values?.phone,
              active: true,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [getOperationName(GET_SUPPLIERS)],
        });
        toaster.push(
          MessageAlert("Fornecedor criado com sucesso.", TYPE_MSG.SUCCESS)
        );
      }
      onClose();
    } catch (error) {
      setException(error);
    }
  }

  const initialValues = useMemo(() => {
    if (!loading && data?.supplier) {
      return {
        name: data.supplier.name,
        phone: data.supplier.phone,
      };
    }
    return {
      name: "",
      phone: "",
    };
  }, [data, loading]);

  const formLoading = useMemo(
    () => loading || createLoading || updateLoading,
    [loading, createLoading, updateLoading]
  );

  return (
    <Modal
      title="Fornecedor"
      subTitle={`${id ? "Atualizar" : "Criar"} fornecedor`}
      open={open}
      onClose={onClose}
      img="/./ios/180.png"
    >
      <AForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        formComponent={Form}
        validationSchema={Yup.object({
          name: Yup.string().required("Campo obrigatório"),
        })}
        formProps={{ formLoading }}
      />
    </Modal>
  );
}
