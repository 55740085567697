import React, { useMemo, useState } from "react";
import { useContextValue } from "../../../context";
import { useMutation, useQuery } from "@apollo/client";
import { DISCART_APPOINTMENT_MUTATION, GET_APPOINTMENTS } from "../queries";
import moment from "moment";
import AlertModal from "../../../components/Modal/AlertModal";
import EmployeeAppointment from "../../Appointment/views/employee-appointment";
import Animation from "../../../Animation/Animation";
import Hand from "../../../Animation/hand.json";
import CalendarNavigator from "../../../components/CalendarNavigator";
import { getOperationName } from "@apollo/client/utilities";
import MessageAlert, { TYPE_MSG } from "../../../components/Message/message";
import { useToaster } from "rsuite";
import useException from "../../../hooks/use-exception";

export default function Appointments() {
  const { selDate } = useContextValue();
  const { setException } = useException();
  const toaster = useToaster();
  const [openModal, setOpenModal] = useState();
  const [selected, setSelected] = useState();

  const [discartAppointment, { loading: discartLoading }] = useMutation(
    DISCART_APPOINTMENT_MUTATION
  );

  async function onDiscart() {
    try {
      await discartAppointment({
        variables: {
          appointment: {
            id: selected.id,
            date: selected.date,
            link: window.location.origin,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [getOperationName(GET_APPOINTMENTS)],
      });
      toaster.push(
        MessageAlert("Agendamento cancelado com sucesso.", TYPE_MSG.SUCCESS)
      );
      setOpenModal(false);
    } catch (error) {
      setOpenModal(false);
      setException(error);
    }
  }

  const { data, loading } = useQuery(GET_APPOINTMENTS, {
    variables: {
      filter: { date: moment(selDate) },
    },
  });

  const formLoading = useMemo(
    () => loading || discartLoading,
    [loading, discartLoading]
  );

  return (
    <>
      <div className="flex flex-col w-full h-screen">
        <div className="flex flex-col h-[calc(100vh-54px)] gap-2 px-4 overflow-hidden">
          <CalendarNavigator
            activeDays
            disabledHours
            appointments={data?.appointments}
            loading={formLoading}
          />
          <div className="flex flex-col h-full overflow-hidden">
            <div className="flex flex-col h-[calc(100vh-314px)] gap-4 overflow-auto rounded-md">
              {formLoading ? (
                <div className="fixed flex items-center justify-center w-full h-full -translate-x-1/2 -translate-y-1/2 bg-opacity-50 top-1/2 left-1/2">
                  <Animation
                    width={150}
                    height={150}
                    animation={Hand}
                    loop={true}
                  />
                </div>
              ) : (
                <div className="flex flex-col gap-2">
                  <EmployeeAppointment
                    data={data}
                    onDiscart={(res) => {
                      setSelected(res);
                      setOpenModal(true);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {openModal && (
        <AlertModal
          open={openModal}
          cancelAction={() => {
            setOpenModal(false);
            setSelected();
          }}
          title="Agendamento"
          message="Deseja cancelar este agendamento?"
          confirLabel="Sim"
          cancelLabel="Não"
          confirmAction={onDiscart}
        />
      )}
    </>
  );
}
