import { gql } from "@apollo/client";

export const UPDATE_PROFILE_MUTATION = gql`
  mutation updateUserClientProfile($user: UserClientInput) {
    updateUserClientProfile(user: $user)
  }
`;

export const GET_STATES = gql`
  query states($filter: StateFilter) {
    states(filter: $filter) {
      id
      name
    }
  }
`;

export const GET_CITIES = gql`
  query cities($filter: CityFilter) {
    cities(filter: $filter) {
      id
      name
    }
  }
`;

export const UPDATE_PERMISSION_MUTATION = gql`
  mutation updatePermission($permission: PermissionInput) {
    updatePermission(permission: $permission) {
      id
    }
  }
`;
