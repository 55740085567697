import { useToaster } from "rsuite";
import MessageAlert, { TYPE_MSG } from "../components/Message/message";

function useException() {
  const toaster = useToaster();

  const setException = (
    error,
    defaultMessage = "Ocorreu um erro ao realizar a operação"
  ) => {
    let message = defaultMessage;

    if (error?.graphQLErrors && error?.graphQLErrors[0]) {
      const graphQLError = error?.graphQLErrors[0];

      if (Array.isArray(graphQLError)) {
        message = graphQLError.map((graphQLErr) => graphQLErr.message);
      } else {
        message = error.graphQLErrors[0].message;
      }
    }

    toaster.push(MessageAlert(message, TYPE_MSG.ERROR));
  };

  const parseException = (error) => {
    if (error?.graphQLErrors && error?.graphQLErrors[0]) {
      const graphQLError = error?.graphQLErrors[0];

      if (Array.isArray(graphQLError)) {
        return graphQLError.map((graphQLErr) => graphQLErr.message).join(" / ");
      }
      return error.graphQLErrors[0].message;
    }
    return "Ocorreu um erro ao realizar a operação!";
  };

  return {
    setException,
    parseException,
  };
}

export default useException;
