import React from "react";
import { useAuth } from "../../hooks/use-auth";

export default function NotPermission() {
  const { user } = useAuth();
  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen">
      <div className="flex flex-col-reverse items-center justify-center gap-16 px-8 lg:px-24 md:px-44 lg:flex-row md:gap-28">
        <div className="relative w-full pb-12 xl:pt-24 xl:w-1/2 lg:pb-0">
          <div className="relative">
            <div className="absolute">
              <div className="">
                <h1 className="my-2 text-2xl font-bold text-gray-800">
                  Sem autorização para acessar a página
                </h1>
                <p className="my-2 text-gray-800">Acesse não autorizado.</p>
              </div>
            </div>
            <div>
              <img src="/404-2.png" alt="" width={500} height={500} />
            </div>
          </div>
        </div>
        <div>
          <img src="/./imgs/group.png" alt="" width={500} height={500} />
        </div>
      </div>
    </div>
  );
}
