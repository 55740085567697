import { useMemo } from "react";
import NumberFormat from "react-number-format";

export default function CurrencyMask({
  inputRef,
  onChange,
  value,
  name,
  ...other
}) {
  const currencyConfig = useMemo(() => {
    return {
      thousandSeparator: ".",
      decimalSeparator: ",",
      prefix: "R$",
      decimalScale: 2,
      fixedDecimalScale: true,
    };
  }, []);

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      value={value}
      inputMode="decimal"
      onValueChange={(values) => {
        onChange({
          target: {
            name,
            value:
              values.floatValue === 0
                ? values.floatValue?.toString()
                : values.floatValue,
          },
        });
      }}
      {...currencyConfig}
    />
  );
}
