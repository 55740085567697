import React, { useMemo } from "react";
import { useMutation, useQuery } from "@apollo/client";
import * as Yup from "yup";
import { getOperationName } from "@apollo/client/utilities";
import { useToaster } from "rsuite";
import { useFormikContext } from "formik";
import moment from "moment";
import {
  CREATE_OR_UPDATE_OPERATION_MUTATION,
  GET_EMPLOYEES,
  GET_OPERATIONS,
} from "../../queries";
import FormFields from "../../../../components/Form/form-fields";
import { InputTypes } from "../../../../components/Input";
import Button from "../../../../components/Button";
import useException from "../../../../hooks/use-exception";
import MessageAlert, { TYPE_MSG } from "../../../../components/Message/message";
import AForm from "../../../../components/Form";
import Modal from "../../../../components/Modal";
import Animation from "../../../../Animation/Animation";
import Hand from "../../../../Animation/hand.json";
import { Copy, Plus } from "@phosphor-icons/react";

const operationDayWeeks = [
  { id: 1, name: "Segunda" },
  { id: 2, name: "Terça" },
  { id: 3, name: "Quarta" },
  { id: 4, name: "Quinta" },
  { id: 5, name: "Sexta" },
  { id: 6, name: "Sábado" },
  { id: 7, name: "Domingo" },
];

function Form({ formLoading, id }) {
  const { initialValues, values } = useFormikContext();

  return (
    <div className="flex flex-col w-full gap-4">
      <FormFields
        loading={formLoading}
        width="grid grid-cols-2 gap-4 w-full border-b py-4 justify-items-end"
        fields={[
          {
            type: InputTypes.ARRAY,
            name: "operation",
            label: "Horários de funcionamento",
            addMore: <Plus size={20} weight="bold" />,
            duplicateLabel: <Copy size={20} weight="bold" />,
            alwaysShowDelete: true,
            width: "w-full",
            children: [
              {
                type: InputTypes.AUTOCOMPLETE,
                name: "dayWeek",
                label: "Dias da semana",
                itemValue: "id",
                itemLabel: "name",
                options: operationDayWeeks?.map((doc) => ({
                  id: doc.id,
                  name: doc.name,
                })),
                width: "w-full",
              },
              {
                type: InputTypes.TIMER,
                name: "startDate",
                value: "startDate",
                label: "Início",
                width: "w-full",
              },
              {
                type: InputTypes.TIMER,
                name: "startBrake",
                value: "startBrake",
                label: "Início Almoço",
                width: "w-full",
              },
              {
                type: InputTypes.TIMER,
                name: "endBrake",
                value: "endBrake",
                label: "Fim Almoço",
                width: "w-full",
              },
              {
                type: InputTypes.TIMER,
                name: "endDate",
                value: "endDate",
                label: "Final",
                width: "w-full",
              },
            ],
          },
        ]}
      />
      <div className="fixed flex flex-col gap-4 bottom-4 left-4 right-4">
        <Button
          label="Salvar"
          className="w-full p-3 text-white rounded-md bg-primary hover:bg-tertiary"
          type="submit"
          disabled={values === initialValues}
        />
      </div>
    </div>
  );
}

export default function ModalTimerForm({ open, onClose, id }) {
  const toaster = useToaster();
  const { setException } = useException();

  const { data, loading } = useQuery(GET_OPERATIONS, {
    variables: {
      filter: { employeeId: id },
    },
    skip: !id,
  });

  const [createOrUpdate, { loading: createOrUpdateLoading }] = useMutation(
    CREATE_OR_UPDATE_OPERATION_MUTATION
  );

  async function onSubmit(values) {
    try {
      await createOrUpdate({
        variables: {
          operation: values?.operation?.map((value) => ({
            dayWeek: value?.dayWeek?.name,
            startDate: value?.startDate,
            startBrake: value?.startBrake,
            endBrake: value?.endBrake,
            endDate: value?.endDate,
          })),
          employeeId: id,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          getOperationName(GET_OPERATIONS),
          getOperationName(GET_EMPLOYEES),
        ],
      });
      toaster.push(MessageAlert("Cadastrado com sucesso.", TYPE_MSG.SUCCESS));
      onClose();
    } catch (error) {
      setException(error);
    }
  }

  const formLoading = useMemo(
    () => loading || createOrUpdateLoading,
    [loading, createOrUpdateLoading]
  );

  const initialValues = useMemo(() => {
    if (!loading && data?.operations) {
      return {
        operation: data.operations
          .sort((a, b) => {
            const findA = operationDayWeeks.find(
              (item) => item.name === a.dayWeek
            );
            const findB = operationDayWeeks.find(
              (item) => item.name === b.dayWeek
            );

            return findA.id - findB.id;
          })
          .map((operation) => ({
            id: operation.id,
            dayWeek: operationDayWeeks.find(
              (item) => item.name === operation.dayWeek
            ),
            startDate: moment(operation.startDate, "HH:mm").format("HH:mm"),
            startBrake: moment(operation.startBrake, "HH:mm").format("HH:mm"),
            endBrake: moment(operation.endBrake, "HH:mm").format("HH:mm"),
            endDate: moment(operation.endDate, "HH:mm").format("HH:mm"),
          })),
      };
    }
    return {
      operation: [],
    };
  }, [loading, data]);

  return (
    <Modal
      title="Horários"
      subTitle="Dados de funcionamento"
      open={open}
      onClose={onClose}
      img="/./ios/180.png"
    >
      {formLoading ? (
        <div className="fixed flex items-center justify-center w-full h-full -translate-x-1/2 -translate-y-1/2 bg-black bg-opacity-50 top-1/2 left-1/2 ">
          <Animation width={150} height={150} animation={Hand} loop={true} />
        </div>
      ) : (
        <div className="flex h-full overflow-auto mb-[60px]">
          <AForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            formComponent={Form}
            formProps={{ formLoading, id }}
            validationSchema={Yup.object({
              operation: Yup.array()
                .of(
                  Yup.object().shape({
                    dayWeek: Yup.object().required("Campo obrigatório"),
                    startDate: Yup.string().required("Campo obrigatório"),
                    startBrake: Yup.string()
                      .required("Campo obrigatório")
                      .test(
                        "Início almoço",
                        "Inicio do almoço tem que ser maior que início",
                        (value, object) => {
                          return value > object.parent.startDate;
                        }
                      ),
                    endBrake: Yup.string()
                      .required("Campo obrigatório")
                      .test(
                        "Fim almoço",
                        "Fim do almoço tem que ser maior ou igual que início do almoço",
                        (value, object) => {
                          return value >= object.parent.startBrake;
                        }
                      ),
                    endDate: Yup.string()
                      .required("Campo obrigatório")
                      .test(
                        "Fim expediente",
                        "Fim do expediente tem que ser maior ou igual que fim do almoço",
                        (value, object) => {
                          return value >= object.parent.endBrake;
                        }
                      ),
                  })
                )
                .required("Campo obrigatório"),
            })}
          />
        </div>
      )}
    </Modal>
  );
}
