import React, { useMemo, useState } from "react";
import { UilMultiply, UilPen, UilCalendarAlt } from "@iconscout/react-unicons";
import { useQuery } from "@apollo/client";
import { GET_EMPLOYEE, GET_SERVICES } from "../queries";
import HomeCardService from "./home-card-service";
import ModalServiceForm from "./modal/modal-service-form";
import ModalAppointment from "./modal/modal-appointment";
import moment from "moment";
import Animation from "../../../Animation/Animation";
import Hand from "../../../Animation/hand.json";
import ADrawer from "../../../components/Drawer";
import { useAuth } from "../../../hooks/use-auth";
import { useContextValue } from "../../../context";
import { Plus } from "@phosphor-icons/react";
import ModalAppointmentRecurrence from "./modal/modal-appointment-recurrence";
import AlertModal from "../../../components/Modal/AlertModal";

export default function HomeCardDetail({
  open,
  onClose,
  id,
  onEdit,
  manager,
  onOperation,
}) {
  const { user, loading } = useAuth();
  const { setSelectedDay, setSelectedMonth, setSelectedYear } =
    useContextValue();
  const [openAppointment, setOpenAppointment] = useState();
  const [openModalService, setOpenModalService] = useState();
  const [listServices, setListServices] = useState([]);
  const [selected, setSelected] = useState();
  const [checked, setChecked] = useState();
  const [openAppointmentRecurrence, setOpenAppointmentRecurrence] = useState();
  const [list, setList] = useState([]);
  const [alertRecurrence, setAlertRecurrence] = useState();

  const isMaster = useMemo(
    () => !loading && (user?.master || user?.manager),
    [user, loading]
  );

  const isEmployee = useMemo(
    () => !loading && user?.isEmployee && user?.employeeIdentifier === id,
    [loading, user, id]
  );

  const { data, loading: employeeLoading } = useQuery(GET_EMPLOYEE, {
    variables: {
      id: Number(id) || null,
    },
    skip: !id,
  });

  const { data: dataServices, loading: servicesLoading } = useQuery(
    GET_SERVICES,
    {
      variables: {
        filter: {
          active: isMaster ? null : true,
          employeeId: Number(id),
        },
      },
      skip: !id,
    }
  );

  const queryLoading = useMemo(
    () => employeeLoading || servicesLoading,
    [employeeLoading, servicesLoading]
  );

  function onSelectedServices(item) {
    const findServices = listServices?.some((res) => res.id === item.id);
    if (!findServices) {
      setListServices((prev) => {
        return [...prev, item];
      });
    } else {
      const filter = listServices?.filter((obj) => obj.id !== item.id);
      setListServices(filter);
    }
  }

  return (
    <>
      <ADrawer open={open} onClose={onClose}>
        <div className="flex flex-col bg-white h-full rounded-tl-[50px] relative">
          <div className="flex w-full gap-2">
            <div className="flex ml-10 -mt-10 rounded-md w-28 h-28">
              <img
                src="/./ios/180.png"
                alt=""
                width={100}
                height={100}
                className="object-cover"
              />
            </div>
            <div className="flex flex-col flex-1 p-5">
              <strong className="text-primary">{data?.employee?.name}</strong>
              <span>{data?.employee?.employeeType?.name}</span>
            </div>
          </div>
          <div className="absolute flex items-center justify-end gap-4 -top-6 right-5">
            {(isMaster || isEmployee) && (
              <>
                <button
                  onClick={onOperation}
                  className="flex items-center justify-center w-10 h-10 bg-white rounded-full shadow-md"
                >
                  <UilCalendarAlt size={20} />
                </button>
                <button
                  onClick={onEdit}
                  className="flex items-center justify-center w-10 h-10 bg-white rounded-full shadow-md"
                >
                  <UilPen size={20} />
                </button>
              </>
            )}
            {isMaster && (
              <button
                onClick={() => {
                  setOpenModalService(true);
                }}
                className="flex items-center justify-center w-10 h-10 bg-white rounded-full shadow-md"
              >
                <Plus size={20} weight="bold" />
              </button>
            )}
            <button
              onClick={onClose}
              className="flex items-center justify-center w-10 h-10 bg-white rounded-full shadow-md"
            >
              <UilMultiply size={20} weight="fill" />
            </button>
          </div>
          <div className="flex items-center justify-between gap-8 p-4">
            <div className="w-full">
              <strong className="text-primary">Lista de serviços</strong>
            </div>
            {user?.manager && (
              <div className="flex items-center justify-end w-full gap-4">
                <strong className="text-primary">Recorrência</strong>
                <input
                  className="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-slate-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                  type="checkbox"
                  role="switch"
                  onChange={(e) => setChecked(e.target.checked)}
                />
              </div>
            )}
          </div>
          <div className="flex flex-col px-4 pb-4 gap-2 overflow-auto h-[calc(100vh-256px)]">
            {queryLoading ? (
              <div className="fixed flex items-center justify-center w-full h-full -translate-x-1/2 -translate-y-1/2 bg-black bg-opacity-50 top-1/2 left-1/2">
                <Animation
                  width={150}
                  height={150}
                  animation={Hand}
                  loop={true}
                />
              </div>
            ) : (
              dataServices?.services?.map((item) => (
                <HomeCardService
                  id={item.id}
                  key={item.id}
                  label={item.name}
                  duration={item.duration}
                  price={item.price}
                  onAction={() => {
                    onSelectedServices(item);
                  }}
                  onEdit={() => {
                    setSelected(item);
                    setOpenModalService(true);
                  }}
                  active={item.active}
                  master={isMaster}
                  employee={isEmployee}
                  checkedIds={listServices?.map(({ id }) => id)}
                />
              ))
            )}
          </div>
          {!!listServices.length && (
            <button
              onClick={() => {
                if (checked) {
                  setOpenAppointmentRecurrence(true);
                } else {
                  setOpenAppointment(true);
                }
                setSelectedYear(moment().year());
                setSelectedMonth(moment().month());
                setSelectedDay(moment().date());
              }}
              className="p-2 m-4 text-white rounded-md bg-primary"
            >
              Agendar
            </button>
          )}
        </div>
      </ADrawer>
      {openAppointment && (
        <ModalAppointment
          employee={data?.employee}
          open={openAppointment}
          listServices={listServices}
          onClose={() => {
            setOpenAppointment(false);
            setOpenModalService(false);
            onClose();
          }}
        />
      )}
      {openModalService && (
        <ModalServiceForm
          id={selected?.id}
          employeeId={data?.employee?.id}
          onClose={() => {
            setOpenModalService();
            setSelected();
          }}
          open={openModalService}
        />
      )}
      {openAppointmentRecurrence && (
        <ModalAppointmentRecurrence
          employee={data?.employee}
          open={openAppointmentRecurrence}
          listServices={listServices}
          onClose={(list) => {
            setList(list);
            setOpenAppointmentRecurrence(false);
            if (!!list?.length) {
              setAlertRecurrence(true);
            }
          }}
        />
      )}
      {alertRecurrence && (
        <AlertModal
          open={alertRecurrence}
          cancelAction={() => {
            setAlertRecurrence(false);
            setList([]);
          }}
          title="Agendamento"
          message="Lista de datas"
          options={list}
          confirLabel="Ok"
          confirmAction={() => {
            setAlertRecurrence(false);
            setList([]);
          }}
        />
      )}
    </>
  );
}
