import { useField, useFormikContext } from "formik";
import React, { useMemo, useState } from "react";
import { Loader, Uploader, useToaster } from "rsuite";
import AvatarIcon from "@rsuite/icons/legacy/Avatar";
import MessageAlert, { TYPE_MSG } from "../Message/message";

function UploadField({ attribute: { label, name, type, onChange } }) {
  const { setFieldValue } = useFormikContext();
  const toaster = useToaster();
  const [field] = useField(name);
  const [uploading, setUploading] = useState(false);
  const [fileInfo, setFileInfo] = useState(null);

  function previewFile(file, callback) {
    const reader = new FileReader();
    reader.onloadend = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(file);
  }

  const value = useMemo(() => {
    return field.value ? field.value : null;
  }, [field]);

  return (
    <Uploader
      fileListVisible={false}
      listType="picture"
      method="get"
      onUpload={(file) => {
        setUploading(true);
        previewFile(file.blobFile, (value) => {
          setFieldValue(name, value);
          setFileInfo(value);
        });
      }}
      onSuccess={(response, file) => {
        setUploading(false);
        if (file) {
          toaster.push(
            MessageAlert("Imagem enviada com sucesso.", TYPE_MSG.SUCCESS)
          );
        }
      }}
      onError={(value) => {
        setFileInfo(null);
        setUploading(false);
        toaster.push(MessageAlert("Ocorreu um erro.", TYPE_MSG.ERROR));
      }}
    >
      <button style={{ width: "100px", height: "100px" }} type="button">
        {uploading && <Loader backdrop center />}
        {fileInfo || value ? (
          <img src={fileInfo || value} width={100} height={100} alt="" />
        ) : (
          <AvatarIcon className="flex" />
        )}
      </button>
    </Uploader>
  );
}

export default UploadField;
