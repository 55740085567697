import React, { useMemo, useState } from "react";
import * as Yup from "yup";
import { useFormikContext } from "formik";
import FormFields from "../../../../components/Form/form-fields";
import { InputTypes } from "../../../../components/Input";
import Button from "../../../../components/Button";
import Modal from "../../../../components/Modal";
import AForm from "../../../../components/Form";
import { useAuth } from "../../../../hooks/use-auth";
import SliderTimer from "../../../../components/Slider/Timer";
import useException from "../../../../hooks/use-exception";
import {
  CREATE_APPOINTMENT_LOCK_MUTATION,
  GET_APPOINTMENTS,
} from "../../queries";
import { useMutation } from "@apollo/client";
import moment from "moment";
import Spinner from "../../../../components/Spinner";
import MessageAlert, { TYPE_MSG } from "../../../../components/Message/message";
import { useToaster } from "rsuite";
import { getOperationName } from "@apollo/client/utilities";
import { GET_PEOPLE_APPOINTMENTS } from "../../../Appointment/queries";

function Form({ formLoading }) {
  const { initialValues, values, setFieldValue } = useFormikContext();
  const [value, setValue] = useState([10, 15]);

  return (
    <div className="flex flex-col items-center justify-center w-full gap-4 p-4 overflow-hidden">
      <FormFields
        loading={formLoading}
        fields={[
          {
            type: InputTypes.TEXT,
            name: "name",
            label: "Nome",
          },
          {
            type: InputTypes.DATE,
            name: "startDate",
            label: "Dia início",
            width: "w-full",
          },
          {
            type: InputTypes.DATE,
            name: "endDate",
            label: "Dia fim",
            width: "w-full",
          },
        ]}
      />
      <div className="w-full py-4">
        <SliderTimer
          min={0.05}
          max={23.55}
          value={value}
          onChange={setValue}
          onSaved={(e) => {
            setFieldValue("blockStart", e[0]);
            setFieldValue("blockEnd", e[1]);
          }}
        />
      </div>
      <div className="flex flex-col w-full gap-4">
        <Button
          label="Salvar"
          className={`${
            values === initialValues && "hidden"
          } bg-primary w-full p-3 rounded-md text-white hover:bg-tertiary`}
          type="submit"
          disabled={values === initialValues}
          icon={<Spinner />}
          loading={formLoading}
        />
      </div>
    </div>
  );
}

export default function ModalLocked({ open, onClose, employeeId }) {
  const { user, loading } = useAuth();
  const { setException } = useException();
  const toaster = useToaster();

  const isEmployee = useMemo(
    () =>
      !loading && user?.isEmployee && user?.employeeIdentifier === employeeId,
    [loading, user, employeeId]
  );

  const [createAppointmentLock, { loading: createLoading }] = useMutation(
    CREATE_APPOINTMENT_LOCK_MUTATION
  );

  async function onSubmit(values) {
    const blockStart = moment(
      `${moment(values.date).format("DD-MM-YYYY")} ${values.blockStart}`,
      "DD-MM-YYYY HH:mm"
    ).format("DD-MM-YYYY HH:mm");
    const blockEnd = moment(
      `${moment(values.date).format("DD-MM-YYYY")} ${values.blockEnd}`,
      "DD-MM-YYYY HH:mm"
    ).format("DD-MM-YYYY HH:mm");

    const durationRange = moment(blockEnd, "DD-MM-YYYY HH:mm").diff(
      moment(blockStart, "DD-MM-YYYY HH:mm"),
      "minutes"
    );

    try {
      await createAppointmentLock({
        variables: {
          appointment: {
            employeeId,
            startDate: moment(values.startDate, "YYYY-MM-DD HH:mm:ss"),
            endDate: moment(values.endDate, "YYYY-MM-DD HH:mm:ss"),
            order: {
              services: [
                {
                  name: values.name,
                  price: 0,
                  quantity: 1,
                  duration: durationRange,
                },
              ],
              price: 0,
              totalProduct: 0,
              totalService: 0,
              totalDuration: durationRange,
            },
            blockStart: values.blockStart,
            blockEnd: values.blockEnd,
            isEmployee,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          getOperationName(GET_APPOINTMENTS),
          getOperationName(GET_PEOPLE_APPOINTMENTS),
        ],
      });
      toaster.push(
        MessageAlert("Horários trancado com sucesso.", TYPE_MSG.SUCCESS)
      );
      onClose();
    } catch (error) {
      setException(error);
    }
  }

  const initialValues = useMemo(() => {
    return {
      name: "",
      startDate: moment(),
      endDate: moment(),
      blockStart: "6:00",
      blockEnd: "20:00",
    };
  }, []);

  const formLoading = useMemo(
    () => loading || createLoading,
    [loading, createLoading]
  );

  return (
    <Modal
      title="Trancar"
      subTitle="Duração do tempo trancado"
      open={open}
      onClose={onClose}
      img="/./ios/180.png"
    >
      <AForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        formComponent={Form}
        validationSchema={Yup.object({
          name: Yup.string().required("Campo obrigatório"),
          startDate: Yup.date().required("Campo obrigatório"),
          endDate: Yup.date().required("Campo obrigatório"),
        })}
        formProps={{ formLoading }}
      />
    </Modal>
  );
}
