import { useMemo, useState } from "react";
import { GET_PEOPLE_APPOINTMENTS } from "./queries";
import { MagnifyingGlass } from "@phosphor-icons/react";

import { useQuery } from "@apollo/client";
import { useAuth } from "../../hooks/use-auth";
import Animation from "../../Animation/Animation";
import Hand from "../../Animation/hand.json";
import unaccent from "../../utils/unaccent";
import Card from "../../components/Card";

export default function History() {
  const { user, loading } = useAuth();
  const [id, setId] = useState();
  const [searchText, setSearchText] = useState();

  const isMasterOrManager = useMemo(
    () => (!loading && user?.master) || user?.manager,
    [user, loading]
  );

  const { data, loading: loadingAppointments } = useQuery(
    GET_PEOPLE_APPOINTMENTS,
    {
      variables: {
        filter: {},
      },
    }
  );

  const appointments = useMemo(() => {
    return data?.peopleAppointments?.filter((item) =>
      searchText
        ? unaccent(item?.company?.name?.toLowerCase())?.includes(
            unaccent(searchText?.toLowerCase())
          )
        : item
    );
  }, [data, searchText]);

  const formLoading = useMemo(
    () => loading || loadingAppointments,
    [loading, loadingAppointments]
  );

  return (
    <>
      <div className="flex flex-col w-full h-full gap-4 p-4 overflow-hidden">
        <div className="flex items-center w-full h-[45px] gap-2 p-3 text-white rounded-md bg-primary">
          <input
            className="w-full text-white bg-transparent outline-none placeholder:text-white"
            placeholder="Buscar pelo nome"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <MagnifyingGlass size={20} />
        </div>
        <div className="flex flex-col h-[calc(100vh-145px)] gap-2 pr-2 overflow-auto scroll-m-4">
          {formLoading ? (
            <div className="fixed flex items-center justify-center w-full h-full -translate-x-1/2 -translate-y-1/2 bg-black bg-opacity-50 top-1/2 left-1/2 ">
              <Animation
                width={150}
                height={150}
                animation={Hand}
                loop={true}
              />
            </div>
          ) : (
            appointments
              ?.sort((a, b) => {
                const dateA = new Date(a.date).getTime();
                const dateB = new Date(b.date).getTime();

                if (dateA < dateB) return 1;
                if (dateA > dateB) return -1;

                const canceledAtA = a.canceledAt === null ? 0 : 1;
                const canceledAtB = b.canceledAt === null ? 0 : 1;

                return canceledAtA - canceledAtB;
              })
              ?.map((res, i) => (
                <div key={i}>
                  <Card
                    data={res}
                    onAction={() => {
                      setId(res.id);
                    }}
                    manager={isMasterOrManager}
                  />
                </div>
              ))
          )}
        </div>
      </div>
    </>
  );
}
