import React from "react";

export default function Button({ onChange }) {
  return (
    <button
      onClick={onChange}
      className="flex items-center justify-center w-full p-4 text-white rounded-md bg-primary whitespace-nowrap"
    >
      <span>Buscar Agora</span>
    </button>
  );
}
