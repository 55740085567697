import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./components/Header";
import Home from "./views/home";
import Feature from "./views/feature";
import Advantage from "./views/advantage";
import Plan from "./views/plan";

export default function Site() {
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState();

  const targetRef = {
    home: useRef(null),
    feature: useRef(null),
    advantage: useRef(null),
    plan: useRef(null),
  };

  const scrollToComponent = (path) => {
    targetRef[path].current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="flex justify-center w-full h-screen overflow-x-hidden overflow-y-auto bg-secondary">
      <div className="fixed top-0 z-50 flex items-center justify-center w-full h-24 py-4 pt-10 sm:px-6 md:px-6 lg:px-6 bg-secondary">
        <Header
          onClick={() => scrollToComponent("home")}
          links={[
            {
              label: "Funcionalidades",
              action: () => scrollToComponent("feature"),
            },
            {
              label: "Vantagens",
              action: () => scrollToComponent("advantage"),
            },
            {
              label: "Planos",
              action: () => scrollToComponent("plan"),
            },
            {
              label: "Empresas",
              action: () => navigate("/company"),
            },
          ]}
          menuOpen={openMenu}
          onMenuOpen={() => setOpenMenu(!openMenu)}
        />
      </div>
      <div className="flex flex-col items-center w-full h-full py-10 max-w-7xl">
        <div
          ref={targetRef.home}
          className="flex w-full min-h-screen sm:px-6 md:px-6 lg:px-6"
        >
          <Home />
        </div>
        <div
          ref={targetRef.feature}
          className="flex w-screen h-full min-h-screen bg-gray-900 sm:px-6 md:px-6 lg:px-6"
        >
          <Feature />
        </div>
        <div
          ref={targetRef.advantage}
          className="flex w-screen h-fit sm:px-6 md:px-6 lg:px-6  pt-[96px]"
        >
          <Advantage />
        </div>
        <div ref={targetRef.plan} className="flex w-screen h-fit pt-[96px]">
          <Plan />
        </div>
      </div>
      <button
        onClick={() => {
          window.open(`https://wa.me/message/JS7BDQR6CO5JB1`);
        }}
        className="fixed p-3 bg-green-800 rounded-full bottom-8 right-8 animate-bounce"
      >
        <img src="./imgs/whatsapp.svg" width={25} alt="" />
      </button>
    </div>
  );
}
