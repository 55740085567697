import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import {
  DISCART_APPOINTMENT_MUTATION,
  GET_APPOINTMENT,
  GET_APPOINTMENTS,
  GET_OPERATIONS,
  GET_PEOPLE_APPOINTMENTS,
  GET_WAITING_LISTS,
} from "./queries";
import { useMutation, useQuery } from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";
import { useToaster } from "rsuite";
import { useAuth } from "../../hooks/use-auth";
import { useContextValue } from "../../context";
import useException from "../../hooks/use-exception";
import MessageAlert, { TYPE_MSG } from "../../components/Message/message";
import Animation from "../../Animation/Animation";
import Hand from "../../Animation/hand.json";
import CalendarNavigator from "../../components/CalendarNavigator";
import AlertModal from "../../components/Modal/AlertModal";
import { Lock } from "@phosphor-icons/react";
import ClientAppointment from "./views/client-appointment";
import EmployeeAppointment from "./views/employee-appointment";

import ModalLocked from "../Home/views/modal/modal-locked-appointment";
import { useParams } from "react-router-dom";
import ADateText from "../../components/a-date-text";
import { usePermission } from "../../hooks/use-permission";

export default function Appointment() {
  const { user, loading } = useAuth();
  const { id } = useParams();
  const { selDate } = useContextValue();
  const [openModal, setOpenModal] = useState();
  const [setOpenModalNotification] = useState();
  const [selected, setSelected] = useState();
  const [buttonSideSelected, setButtonSideSelected] = useState("CLIENT");
  const { setException } = useException();
  const toaster = useToaster();
  const [locked, setLocked] = useState();
  const refreshSchedule = usePermission(1);

  const isEmployee = useMemo(
    () => !loading && user?.isEmployee,
    [loading, user]
  );

  const { data: dataAppointment, loading: appointmentCanceledLoading } =
    useQuery(GET_APPOINTMENT, {
      variables: {
        id: Number(id),
      },
      skip: !id,
    });

  const { data: dataPeopleAppointments, loading: peopleAppointmentsLoading } =
    useQuery(GET_PEOPLE_APPOINTMENTS, {
      variables: {
        filter: { date: moment(selDate) },
      },
      skip: buttonSideSelected !== "CLIENT",
    });

  const { data, loading: appointmentLoading } = useQuery(GET_APPOINTMENTS, {
    variables: {
      filter: {
        date: moment(selDate),
        employeeId: user?.employeeIdentifier,
      },
    },
    skip: buttonSideSelected === "CLIENT",
    pollInterval: refreshSchedule && 300000, // 5 minuto
  });

  const { data: dataOperation, loading: operationLoading } = useQuery(
    GET_OPERATIONS,
    {
      variables: {
        filter: { employeeId: user?.employeeIdentifier },
      },
      skip: !user?.employeeIdentifier || buttonSideSelected === "CLIENT",
    }
  );

  const [discartAppointment, { loading: discartLoading }] = useMutation(
    DISCART_APPOINTMENT_MUTATION
  );

  async function onDiscart() {
    try {
      await discartAppointment({
        variables: {
          appointment: {
            id: selected.id,
            date: selected.date,
            link: window.location.origin,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          getOperationName(GET_APPOINTMENTS),
          getOperationName(GET_WAITING_LISTS),
          getOperationName(GET_PEOPLE_APPOINTMENTS),
        ],
      });
      toaster.push(
        MessageAlert("Agendamento cancelado com sucesso.", TYPE_MSG.SUCCESS)
      );
      setOpenModal(false);
    } catch (error) {
      setOpenModal(false);
      setException(error);
    }
  }

  useEffect(() => {
    if (
      !appointmentCanceledLoading &&
      dataAppointment &&
      dataAppointment?.appointment?.userClient?.id === user?.id
    ) {
      if (!!dataAppointment?.appointment?.canceledAt) {
        toaster.push(
          MessageAlert("Agendamento já foi cancelado.", TYPE_MSG.SUCCESS)
        );
      } else {
        setSelected(dataAppointment?.appointment);
        setOpenModal(true);
      }
    }
  }, [dataAppointment, appointmentCanceledLoading, user, toaster]);

  const queryLoading = useMemo(
    () =>
      discartLoading ||
      loading ||
      appointmentLoading ||
      operationLoading ||
      peopleAppointmentsLoading ||
      appointmentCanceledLoading,
    [
      discartLoading,
      loading,
      appointmentLoading,
      operationLoading,
      peopleAppointmentsLoading,
      appointmentCanceledLoading,
    ]
  );

  const buttonSide = ({ options }) => {
    return options.map((res, i) => (
      <>
        {res.value === "LOCKED" && isEmployee && (
          <button
            key={i}
            onClick={res.onAction}
            className="flex p-2 border-2 rounded-full border-primary hover:bg-primary hover:text-white text-primary"
          >
            <Lock size={25} />
          </button>
        )}

        {res.value !== "LOCKED" && (
          <button
            key={i}
            className={`flex items-center justify-center w-full p-2 rounded-md border-2 ${
              buttonSideSelected === res.value
                ? "bg-primary text-white"
                : "bg-white  text-primary"
            }`}
            onClick={res.onAction}
          >
            {res.label}
          </button>
        )}
      </>
    ));
  };

  return (
    <>
      <div className="flex flex-col w-full h-screen">
        <div className="flex flex-col h-[calc(100vh-54px)] gap-2 p-4 overflow-hidden">
          <CalendarNavigator
            activeDays
            disabledHours
            operations={dataOperation?.operations}
            appointments={data?.appointments}
            service={selected}
            loading={queryLoading}
          />

          {isEmployee && user?.isSchedule ? (
            <strong className="flex justify-between gap-4 p-4 text-center text-white bg-white rounded-md">
              {buttonSide({
                options: [
                  {
                    label: "Pessoal",
                    onAction: () => {
                      setButtonSideSelected("CLIENT");
                    },
                    value: "CLIENT",
                  },
                  {
                    label: "Bloquear",
                    onAction: () => {
                      setLocked(true);
                    },
                    value: "LOCKED",
                  },
                  {
                    label: "Profissional",
                    onAction: () => {
                      setButtonSideSelected("EMPLOYEE");
                    },
                    value: "EMPLOYEE",
                  },
                ],
              })}
            </strong>
          ) : (
            <strong className="flex justify-center gap-4 p-4 text-center bg-white rounded-md text-primary">
              <ADateText date={selDate} />
            </strong>
          )}
          <div className="flex flex-col h-full overflow-hidden">
            <div className="flex flex-col h-[calc(100%-0px)] gap-4 overflow-auto rounded-md">
              {queryLoading ? (
                <div className="fixed flex items-center justify-center w-full h-full -translate-x-1/2 -translate-y-1/2 bg-opacity-50 top-1/2 left-1/2">
                  <Animation
                    width={150}
                    height={150}
                    animation={Hand}
                    loop={true}
                  />
                </div>
              ) : (
                <div className="flex flex-col gap-2">
                  {buttonSideSelected === "CLIENT" ? (
                    <ClientAppointment
                      client
                      data={dataPeopleAppointments}
                      onDiscart={(res) => {
                        setSelected(res);
                        setOpenModal(true);
                      }}
                      onNotification={(res) => {
                        setSelected(res);
                        setOpenModalNotification(true);
                      }}
                    />
                  ) : (
                    <EmployeeAppointment
                      data={data}
                      onDiscart={(res) => {
                        setSelected(res);
                        setOpenModal(true);
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {openModal && (
        <AlertModal
          open={openModal}
          cancelAction={() => {
            setOpenModal(false);
            setSelected();
          }}
          title="Agendamento"
          message="Deseja cancelar este agendamento?"
          confirLabel="Sim"
          cancelLabel="Não"
          confirmAction={onDiscart}
        />
      )}

      {locked && (
        <ModalLocked
          open={locked}
          onClose={() => setLocked(false)}
          employeeId={user?.employeeIdentifier}
        />
      )}
    </>
  );
}
