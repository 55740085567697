import { ArrowLeft, ArrowRight } from "@phosphor-icons/react";
import React, { useEffect, useMemo, useRef } from "react";
import { days, months } from "./constants";
import moment from "moment";
import { useContextValue } from "../../context";
import { formatWeek } from "../../utils/format-week";
import createSchedule from "../../utils/calendar-hour";
import { usePermission } from "../../hooks/use-permission";

export default function CalendarNavigator({
  activeDays,
  disabledHours,
  operations,
  loading,
  appointments,
  serviceDuration,
  duration,
}) {
  const {
    handleLeft,
    handleRight,
    selectedMonth,
    selectedYear,
    selectedDay,
    listDays,
    setListDays,
    listHours,
    setListHours,
    setSelectedDay,
    selectedHour,
    setSelectedHour,
    selDate,
  } = useContextValue();
  const advanceScheduling = usePermission(2);

  const scrollRef = useRef(null);

  useEffect(() => {
    if (listDays) {
      const index = listDays?.findIndex((date) => date.number === selectedDay);

      if (index !== -1) {
        const element = scrollRef.current.children[index];
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [listDays, selectedDay]);

  const availableDays = useMemo(() => {
    if (operations && !loading) {
      return operations?.map((item) => ({
        week: formatWeek(item.dayWeek),
        startDate: item.startDate,
        startBrake: item.startBrake,
        endBrake: item.endBrake,
        endDate: item.endDate,
      }));
    }
  }, [operations, loading]);

  const available = useMemo(() => {
    let d = new Date(selectedYear, selectedMonth, selectedDay);
    return availableDays?.find((e) => e.week === days[d.getDay()]);
  }, [availableDays, selectedYear, selectedMonth, selectedDay]);

  useEffect(() => {
    let daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();
    const newListDays = [];

    for (let i = 1; i <= daysInMonth; i++) {
      let d = new Date(selectedYear, selectedMonth, i);

      let filter = availableDays?.filter((e) => e.week === days[d.getDay()]);

      newListDays.push({
        status: activeDays ? true : filter?.length > 0 ? true : false,
        weekDay: days[d.getDay()],
        number: i,
      });
    }

    setListDays(newListDays);
    setListHours([]);
    setSelectedHour();
  }, [selectedMonth, selectedYear, availableDays, activeDays]);

  useEffect(() => {
    if (selectedDay > 0 && available && !loading && duration) {
      const now = moment().format("YYYY-MM-DD HH:mm");

      const startTime = moment(
        `${selDate} ${available?.startDate}`,
        "YYYY-MM-DD HH:mm"
      );
      const endTime = moment(
        `${selDate} ${available?.endDate}`,
        "YYYY-MM-DD HH:mm"
      );

      const hours = createSchedule({
        startTime,
        endTime,
        startBreak: available?.startBrake,
        endBreak: available?.endBrake,
        duration,
        date: selDate,
      });

      const scheduleAppoitments = appointments?.map((item) => ({
        start: moment(item?.date).format("YYYY-MM-DD HH:mm"),
        end: moment(item?.date)
          .add(item?.order?.totalDuration, "minutes")
          .format("YYYY-MM-DD HH:mm"),
      }));

      const unavailableHours = [];
      const availableHours = [];

      if (scheduleAppoitments) {
        for (const hour of hours) {
          const newService = moment(hour)
            .add(serviceDuration, "minutes")
            .format("YYYY-MM-DD HH:mm");

          // Elimina os horarios que fica depois do inicio do almoco ate antes do final do almoco
          if (
            moment(newService).format("HH:mm") > available?.startBrake &&
            moment(hour).format("HH:mm") < available?.startBrake
          ) {
            unavailableHours.push(hour);
          }

          // ELimina horarios que passa do final do expediente
          if (newService > endTime.format("YYYY-MM-DD HH:mm")) {
            unavailableHours.push(hour);
          }

          for (const appointment of scheduleAppoitments) {
            // Elimina horarios que o novo servico entre em um horario ja agendado
            if (moment(appointment.start).isBetween(hour, newService)) {
              unavailableHours.push(hour);
            }

            if (
              hour >= appointment.start &&
              hour < appointment.end &&
              moment(appointment.start).format("YYYY-MM-DD") === selDate
            ) {
              unavailableHours.push(hour);
            }
          }

          const find = unavailableHours.some(
            (unavailableHour) => unavailableHour === hour
          );

          if (!find) {
            availableHours.push(hour);
          }
        }
      }

      const filterHours = availableHours.filter((hour) =>
        moment(hour).isSameOrAfter(now)
      );

      const hoursFilter = filterHours.filter((hour) => {
        const diferencaMinutos = moment(hour, "YYYY-MM-DD HH:mm").diff(
          moment(),
          "minutes"
        );

        return Math.abs(diferencaMinutos) >= Number(advanceScheduling);
      });

      setListHours(hoursFilter);
    }
  }, [
    selectedDay,
    selectedMonth,
    selectedYear,
    selectedHour,
    serviceDuration,
    availableDays,
    selDate,
    available,
    duration,
    appointments,
    loading,
    advanceScheduling,
  ]);

  return (
    <div className="flex flex-col items-center justify-center gap-4 p-4 text-white rounded-md bg-primary">
      <div className="flex justify-between w-full gap-4">
        <button onClick={handleLeft}>
          <ArrowLeft weight="bold" size={18} />
        </button>
        <strong>{`${months[selectedMonth]} ${selectedYear}`}</strong>
        <button onClick={handleRight}>
          <ArrowRight weight="bold" size={18} />
        </button>
      </div>
      <div className="flex flex-col w-full gap-4">
        <div className="flex w-full gap-4 pb-4 overflow-x-auto" ref={scrollRef}>
          {listDays?.map((day, i) => (
            <button
              className={`flex flex-col items-center px-2 rounded-md ${
                !day.status && "opacity-50"
              } ${
                day.number === selectedDay && "bg-white opacity-100 text-black"
              }`}
              key={i}
              onClick={() => {
                if (day.status) {
                  setSelectedDay(day.number);
                  setSelectedHour();
                }
              }}
            >
              <span>{day.weekDay}</span>
              <span>{day.number}</span>
            </button>
          ))}
        </div>

        {listHours?.length > 0 && !disabledHours && !loading && (
          <div className="flex flex-col items-center justify-center gap-4 text-white rounded-md bg-primary">
            <div className="flex items-center justify-center w-full gap-4">
              <strong>Horários</strong>
            </div>
            <div className="flex w-full gap-4 overflow-x-auto">
              {listHours?.map((hour, i) => (
                <button
                  onClick={() => setSelectedHour(hour)}
                  key={i}
                  className={`flex items-center px-2 rounded-md ${
                    hour === selectedHour && "bg-white text-black"
                  }`}
                >
                  <span>{moment(hour).format("HH:mm")}</span>
                </button>
              ))}
            </div>
          </div>
        )}
        {!listHours?.length && selectedDay && !disabledHours && !loading && (
          <div className="flex flex-col items-center justify-center text-white rounded-md bg-primary">
            <div className="flex justify-center w-full gap-4 overflow-x-auto">
              <span>Sem horários disponíveis</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
