import React from "react";
import ReactDOM from "react-dom/client";
import { CustomProvider } from "rsuite";
import ptBR from "rsuite/locales/pt_BR";
import "rsuite/dist/rsuite.min.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import ContextProvider from "./context";
import { ApolloProvider } from "@apollo/client";
import client from "./apolloClient";
import { ProvideAuth } from "./hooks/use-auth";
import { ProvideCompany } from "./hooks/use-company";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/pt-br";
import { ThemeProvider, createTheme } from "@mui/material";

const root = ReactDOM.createRoot(document.getElementById("root"));

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <CustomProvider locale={ptBR}>
            <ApolloProvider client={client}>
              <ProvideAuth>
                <ProvideCompany>
                  <ContextProvider>
                    <App />
                  </ContextProvider>
                </ProvideCompany>
              </ProvideAuth>
            </ApolloProvider>
          </CustomProvider>
        </LocalizationProvider>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
