import { TextField } from "@mui/material";
import { styled } from "@mui/styles";
import { useField, useFormikContext } from "formik";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#575757",
    fontSize: 14,
  },
  "& label": {
    color: "#575757",
    fontSize: 14,
  },
  "& input": {
    color: "#575757",
    fontSize: 14,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#575757",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#575757",
    },
    "&:hover fieldset": {
      borderColor: "#575757",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#575757",
    },
    "&:after fieldset": {
      borderColor: "#575757",
    },
  },
});

function InputField({
  attribute: {
    label,
    name,
    type,
    onChange,
    disabled,
    width = "w-full",
    variant = "outlined",
    mask: Mask,
    visible = false,
  },
}) {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  return (
    <div className={`${visible ? "hidden" : "flex"} ${width}`}>
      <CssTextField
        {...field}
        error={!!meta.error}
        helperText={meta.error}
        type={type}
        label={label}
        name={name}
        variant={variant}
        fullWidth
        disabled={disabled}
        InputProps={{
          inputComponent: Mask,
        }}
        InputLabelProps={{
          shrink: !!field.value,
        }}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
          if (onChange) {
            onChange(e.target.value);
          }
        }}
      />
    </div>
  );
}

export default InputField;
