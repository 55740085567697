import { ArrowLeft } from "@phosphor-icons/react";
import React from "react";
import { Drawer } from "rsuite";

function ADrawer({
  open,
  onClose,
  title,
  children,
  size = "full",
  height = "100%",
  width = "100%",
}) {
  return (
    <Drawer
      style={{ height, width }}
      backdrop="static"
      open={open}
      onClose={onClose}
      placement="bottom"
      size={size}
    >
      <div className={`bg-primary flex flex-col h-full`}>
        <div className="flex items-start h-32 p-4 text-white bg-primary">
          <strong>{title}</strong>
          <button onClick={onClose}>
            <ArrowLeft size={20} />
          </button>
        </div>
        <div className="flex flex-col h-[calc(100%-112px)] bg-primary">
          {children}
        </div>
      </div>
    </Drawer>
  );
}

export default ADrawer;
