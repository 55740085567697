import React, { useMemo, useState } from "react";
import { useAuth } from "../../hooks/use-auth";
import ProductList from "./views/product-list";
import PointList from "./views/point-list";
import SupplierList from "./views/supplier-list";

export default function List() {
  const { user, loading } = useAuth();

  const isMasterOrManager = useMemo(
    () => (!loading && user?.master) || user?.manager,
    [user, loading]
  );

  const [buttonSideSelected, setButtonSideSelected] = useState(
    isMasterOrManager ? "PRODUCT" : "POINTS"
  );

  const buttonSide = ({ options }) => {
    return options.map((res, i) => (
      <button
        key={i}
        className={`flex items-center justify-center w-full p-2 rounded-md border-2 ${
          buttonSideSelected === res.value
            ? "bg-primary text-white"
            : "bg-white  text-primary"
        }`}
        onClick={res.onAction}
      >
        {res.label}
      </button>
    ));
  };

  return (
    <>
      {user?.manager && (
        <div className="flex flex-col w-full h-full gap-4 p-4 overflow-hidden">
          <strong className="flex justify-between gap-4 p-4 text-center text-white bg-white rounded-md">
            {buttonSide({
              options: [
                {
                  label: "Produto",
                  onAction: () => {
                    setButtonSideSelected("PRODUCT");
                  },
                  value: "PRODUCT",
                },
                {
                  label: "Fornecedor",
                  onAction: () => {
                    setButtonSideSelected("SUPPLIER");
                  },
                  value: "SUPPLIER",
                },
                {
                  label: "Pontos",
                  onAction: () => {
                    setButtonSideSelected("POINTS");
                  },
                  value: "POINTS",
                },
              ],
            })}
          </strong>
        </div>
      )}
      {buttonSideSelected === "PRODUCT" && (
        <ProductList buttonSideSelected={buttonSideSelected} />
      )}
      {buttonSideSelected === "SUPPLIER" && (
        <SupplierList buttonSideSelected={buttonSideSelected} />
      )}
      {buttonSideSelected === "POINTS" && (
        <PointList buttonSideSelected={buttonSideSelected} />
      )}
    </>
  );
}
