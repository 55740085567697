import React, { useMemo } from "react";
import * as Yup from "yup";
import Modal from "../../../components/Modal";
import AForm from "../../../components/Form";
import MessageAlert, { TYPE_MSG } from "../../../components/Message/message";
import useException from "../../../hooks/use-exception";
import { useToaster } from "rsuite";
import { InputTypes } from "../../../components/Input";
import FormFields from "../../../components/Form/form-fields";
import Button from "../../../components/Button";
import { useMutation } from "@apollo/client";
import { CREATE_USER_MUTATION, GET_USERS } from "../queries";
import Spinner from "../../../components/Spinner";
import APhoneMask from "../../../components/Mask/a-phone-number.mask";
import { getOperationName } from "@apollo/client/utilities";

function Form({ formLoading }) {
  return (
    <div className="flex flex-col items-center justify-center w-full gap-4 py-4">
      <FormFields
        loading={formLoading}
        fields={[
          {
            type: InputTypes.TEXT,
            label: "Nome",
            name: "name",
            width: "w-full",
          },
          {
            type: InputTypes.TEXT,
            label: "Whastapp",
            name: "phone",
            width: "w-full",
            mask: APhoneMask,
          },
        ]}
      />
      <div className="flex flex-col w-full gap-2">
        <Button
          label="Cadastrar"
          className="w-full p-3 text-white rounded-md bg-primary hover:bg-tertiary"
          type="submit"
          icon={<Spinner />}
          loading={formLoading}
        />
      </div>
    </div>
  );
}

export default function FinanceUserForm({ open, onClose }) {
  const { setException } = useException();
  const toaster = useToaster();

  const [create, { loading }] = useMutation(CREATE_USER_MUTATION);

  const formLoading = useMemo(() => loading, [loading]);

  async function onSubmit(values) {
    try {
      await create({
        variables: {
          user: {
            name: values?.name,
            phone: values?.phone,
            password: "123",
            active: true,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [getOperationName(GET_USERS)],
      });
      toaster.push(
        MessageAlert("Cadastro criado com sucesso.", TYPE_MSG.SUCCESS)
      );
      onClose();
    } catch (error) {
      setException(error);
    }
  }

  return (
    <Modal
      title="Cliente"
      subTitle="Criar conta do cliente"
      open={open}
      onClose={onClose}
      img="/./ios/180.png"
    >
      <AForm
        onSubmit={onSubmit}
        initialValues={{ currentPassword: "", password: "" }}
        formComponent={Form}
        validationSchema={Yup.object({
          name: Yup.string().required("Campo obrigatório"),
          phone: Yup.string().required("Campo obrigatório"),
        })}
        formProps={{ formLoading }}
      />
    </Modal>
  );
}
