import React from "react";
import { UilTagAlt } from "@iconscout/react-unicons";
import Modal from "../../../components/Modal";
import formatCurrency from "../../../utils/formatCurrency";
import { GET_ORDER } from "../queries";
import { useQuery } from "@apollo/client";
import Animation from "../../../Animation/Animation";
import Hand from "../../../Animation/hand.json";
import ADateText from "../../../components/a-date-text";

export default function OrderDetail({ open, onClose, id }) {
  const { data, loading } = useQuery(GET_ORDER, {
    variables: {
      id,
    },
    skip: !id,
  });

  return (
    <Modal
      title="Comanda"
      subTitle="Detalhamento da comanda"
      open={open}
      onClose={onClose}
      img="/./ios/180.png"
    >
      {loading ? (
        <div className="flex items-center justify-center w-full h-full bg-opacity-50">
          <Animation width={150} height={150} animation={Hand} loop={true} />
        </div>
      ) : (
        <div className="flex flex-col w-full h-full gap-4 overflow-hidden">
          <div className="flex flex-col w-full gap-2">
            <div className="flex justify-between w-full p-4 text-white rounded-md bg-primary">
              <div className="space-x-2">
                <strong>Comanda</strong>
                <strong>#{data?.order?.id}</strong>
              </div>
              {data?.order?.date && (
                <strong>
                  <ADateText date={data?.order?.date} />
                </strong>
              )}
              <strong>
                {data?.order?.type === "BLOCKED"
                  ? "Trancado"
                  : data?.order?.type === "RECEIVE"
                  ? "Receita"
                  : data?.order?.type === "CONSUME"
                  ? "Consumo"
                  : "Despesa"}
              </strong>
            </div>
            <div className="flex justify-between w-full gap-4 p-2 rounded-md bg-secondary">
              <div className="flex flex-col items-center justify-center w-full p-2 text-white rounded-md bg-primary">
                <strong>Profissional</strong>
                <strong>{data?.order?.employee?.name}</strong>
              </div>
              {data?.order?.userClient && (
                <div className="flex flex-col items-center justify-center w-full p-2 text-white rounded-md bg-primary">
                  <strong>Cliente</strong>
                  <strong>{data?.order?.userClient?.name}</strong>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col h-full overflow-auto">
            <div className="flex flex-col w-full h-auto gap-2 ">
              {data?.order?.orderService?.map((res, i) => (
                <div
                  key={i}
                  className="flex w-full rounded-md shadow bg-secondary"
                >
                  <div className="flex items-center w-full gap-4 p-4">
                    <strong>{res?.quantity}x</strong>
                    <strong>{res?.name}</strong>
                    {res.plan && (
                      <strong className="text-green-500">
                        <UilTagAlt />
                      </strong>
                    )}
                  </div>
                  <div className="flex items-center w-full p-4">
                    <strong className="w-full text-end">
                      {formatCurrency(res?.price)}
                    </strong>
                  </div>
                </div>
              ))}
              {data?.order?.orderProduct?.map((res, i) => (
                <div
                  key={i}
                  className="flex w-full rounded-md shadow bg-secondary"
                >
                  <div className="flex items-center w-full gap-4 p-4">
                    <strong>{res?.quantity}x</strong>
                    <strong>{res?.name}</strong>
                  </div>
                  <div className="flex items-center w-full p-4">
                    <strong className="w-full text-end">
                      {formatCurrency(res?.price)}
                    </strong>
                  </div>
                </div>
              ))}
              {data?.order?.supplier && (
                <div className="flex w-full rounded-md shadow bg-secondary">
                  <div className="flex items-center w-full gap-4 p-4">
                    <strong>1x</strong>
                    <strong>{data?.order?.supplier?.name}</strong>
                  </div>
                  <div className="flex items-center w-full p-4">
                    <strong className="w-full text-end">
                      {formatCurrency(data?.order?.price)}
                    </strong>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col w-full p-4 text-white rounded-md bg-primary">
            {data?.order?.discount > 0 && (
              <>
                <div className="flex justify-between w-full">
                  <strong>Subtotal</strong>
                  <strong>
                    {formatCurrency(
                      data?.order?.price + data?.order?.discount || 0
                    )}
                  </strong>
                </div>
                <div className="flex justify-between w-full">
                  <strong>Desconto</strong>
                  <strong>{formatCurrency(data?.order?.discount || 0)}</strong>
                </div>
              </>
            )}
            <div className="flex justify-between w-full">
              <strong>Total</strong>
              <strong>
                {formatCurrency(data?.order?.price || data?.order?.price)}
              </strong>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}
