import { makeStyles, useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useField, useFormikContext } from "formik";
import React from "react";
import OTPInput from "react-otp-input";

const useStyles = makeStyles(() => ({
  input: {
    outline: "none !important",
    border: "1px solid",
    borderRadius: "5px",
    width: (props) => (props.isMobile ? "30px !important" : "50px !important"),
    height: (props) => (props.isMobile ? "30px !important" : "50px !important"),
    borderColor: (props) =>
      props?.meta ? "1px solid rgb(220 38 38)" : "1px solid #575757",
  },
}));

export default function CodeField({
  attribute: { id, name, label, disabled, required, width, length, visible },
  loading,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const [field, meta] = useField(name);
  const { values, setFieldValue } = useFormikContext();
  const classes = useStyles({ meta: !!meta.error, isMobile });

  console.log(isMobile);

  return (
    <div
      className={`${
        visible ? "hidden" : "flex"
      } ${width} flex-col items-center justify-center gap-4`}
    >
      <OTPInput
        errorMessage={"Invalid OTP"}
        containerStyle={{
          width: "fit-content",
          gap: 10,
        }}
        value={field?.value}
        onChange={(e) => {
          setFieldValue(name, e);
        }}
        numInputs={length}
        renderInput={(props) => {
          return (
            <input
              {...props}
              id={name}
              name={name}
              disabled={disabled}
              required={required}
              className={classes.input}
            />
          );
        }}
      />
      {!!meta?.error && <span className="text-red-600">{meta.error}</span>}
    </div>
  );
}
