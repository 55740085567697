import React from "react";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();
  const company = localStorage.getItem("company");

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen">
      <div className="flex flex-col-reverse items-center justify-center gap-16 px-8 lg:px-24 md:px-44 lg:flex-row md:gap-28">
        <div className="relative w-full pb-12 xl:pt-24 xl:w-1/2 lg:pb-0">
          <div className="relative">
            <div className="absolute">
              <div className="">
                <h1 className="my-2 text-2xl font-bold text-gray-800">
                  Página não foi encontrada
                </h1>
                <p className="my-2 text-gray-800">
                  Desculpe por isso! Por favor, visite nossa pagina para chegar
                  onde você precisa ir.
                </p>
                {company && (
                  <button
                    onClick={() => navigate(`/${company}`)}
                    className="w-full px-8 py-4 my-2 text-center text-white border rounded md bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50"
                  >
                    Início
                  </button>
                )}

                <button
                  onClick={() => navigate("/")}
                  className="w-full px-8 py-4 my-2 text-center text-white border rounded md bg-primary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50"
                >
                  Empresas
                </button>
              </div>
            </div>
            <div>
              <img src="/404-2.png" alt="" width={500} height={500} />
            </div>
          </div>
        </div>
        <div>
          <img src="/./imgs/group.png" alt="" width={500} height={500} />
        </div>
      </div>
    </div>
  );
}
