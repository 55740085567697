import { ArchiveBox, Phone } from "@phosphor-icons/react";
import React, { useMemo } from "react";
import { UilPen, UilBill, UilEye } from "@iconscout/react-unicons";
import { useAuth } from "../../hooks/use-auth";
import formatCurrency from "../../utils/formatCurrency";

function getStatus(data) {
  return data?.active || data?.totalValue > 0
    ? "border-green-600"
    : "border-red-600";
}

export default function CardProduct({ onEdit, data, type, onShowPoint }) {
  const { user, loading } = useAuth();

  const isEmployee = useMemo(
    () => !loading && user?.isEmployee && user?.employeeIdentifier === data?.id,
    [loading, user, data]
  );

  return (
    <div
      className={`flex flex-col border-l-4 ${getStatus(
        data
      )} bg-white w-full rounded-md p-4 gap-4`}
    >
      {type !== "PLAN" && (
        <div className="flex items-center w-full gap-2 pb-4 border-b">
          {data?.quantity && (
            <div className="flex items-center w-full gap-2">
              <span className="text-slate-400">
                <ArchiveBox size={20} />
              </span>
              <span className="text-slate-400">{data?.quantity}</span>
            </div>
          )}

          {data?.phone && (
            <div className="flex items-center w-full gap-2">
              <span className="text-slate-400">
                <Phone size={20} />
              </span>
              <span className="text-slate-400">{data?.phone}</span>
            </div>
          )}

          {(user?.manager || isEmployee) && type !== "PLAN" && (
            <button
              className="flex items-center justify-end w-full text-slate-400"
              onClick={onEdit}
            >
              <UilPen size={20} />
            </button>
          )}
        </div>
      )}
      <div className="flex items-center justify-between w-full gap-4">
        <div className="w-14">
          <img
            src="/./ios/180.png"
            alt=""
            width={50}
            className="object-contain"
          />
        </div>
        <div className="flex justify-between w-full">
          <div className="flex flex-col w-full">
            <strong className="text-primary">
              {data?.name || data?.userClient?.name}
            </strong>
            {data?.totalValue >= 0 && (
              <div className="flex items-center w-full gap-2">
                <span className="text-slate-400">
                  <UilBill size={20} />
                </span>
                <span className="text-slate-400">{data?.totalValue}</span>
              </div>
            )}
            <div className="flex items-center gap-2">
              {data?.price && (
                <span className="text-slate-400">
                  {formatCurrency(data?.price)}
                </span>
              )}
              {data?.service && (
                <span className="text-slate-400">{data?.service?.name}</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
