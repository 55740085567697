import React, { useEffect, useMemo, useState } from "react";
import { UilMultiply } from "@iconscout/react-unicons";
import { Drawer, useToaster } from "rsuite";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_APPOINTMENT_MUTATION,
  CREATE_WAITING_LIST_MUTATION,
  GET_APPOINTMENTS,
  GET_DURATION,
  GET_OPERATIONS,
  GET_USER_CLIENTS,
} from "../../queries";
import moment from "moment";
import { useAuth } from "../../../../hooks/use-auth";
import { useContextValue } from "../../../../context";
import useException from "../../../../hooks/use-exception";
import Animation from "../../../../Animation/Animation";
import Calendar from "../../../../Animation/calendar.json";
import MessageAlert, { TYPE_MSG } from "../../../../components/Message/message";
import CalendarNavigator from "../../../../components/CalendarNavigator";
import AlertModal from "../../../../components/Modal/AlertModal";
import { getOperationName } from "@apollo/client/utilities";
import AForm from "../../../../components/Form";
import FormFields from "../../../../components/Form/form-fields";
import { InputTypes } from "../../../../components/Input";
import { useFormikContext } from "formik";
import FinanceUserForm from "../../../finance/views/finance-user-form";
import useDebounce from "../../../../hooks/use-debounce";

function Form({ formLoading, setOpenUser, setValues }) {
  const { user } = useAuth();
  const { values, setFieldValue } = useFormikContext();
  const [text, setText] = useState();
  const debounced = useDebounce(text);

  const { data, loading: userLoading } = useQuery(GET_USER_CLIENTS, {
    variables: {
      filter: {
        active: true,
        companyIdentifier: 0,
        employeeIdentifier: 0,
        name: debounced,
        phone: debounced,
      },
    },
  });

  useEffect(() => {
    if (user?.id !== values?.user?.id) {
      setValues({
        user: values?.user,
      });
    } else {
      setValues(values);
    }
  }, [values]);

  const loadingQuerys = useMemo(() => formLoading, [formLoading]);

  return (
    <div className="flex flex-col w-full gap-4 py-4">
      <FormFields
        loading={loadingQuerys}
        fields={[
          {
            type: InputTypes.AUTOCOMPLETE,
            label: "Cliente",
            name: "user",
            width: "w-full",
            options:
              data?.userClients?.map((res) => {
                return {
                  ...res,
                  name: `${res.name} - ${res?.phone}`,
                };
              }) || [],
            onButton: true,
            onButtonAction: () => {
              setOpenUser(true);
            },
            onChange: () => {
              setFieldValue("observation", "");
            },
            onChangeInput: (e) => {
              setText(e);
            },
            loading: userLoading,
            onInputChange: (event, newInputValue, reason) => {
              if (reason === "clear") {
                setText();
              }
            },
          },
          {
            type: InputTypes.TEXT,
            label: "Observação",
            name: "observation",
            width: "w-full",
            visible: values?.user?.id !== user?.id,
          },
        ]}
      />
    </div>
  );
}

export default function ModalAppointment({
  open,
  onClose,
  selected,
  employee,
  listServices,
}) {
  const { user, loading } = useAuth();
  const toaster = useToaster();
  const {
    selDate,
    selectedDay,
    selectedHour,
    selectedMonth,
    selectedYear,
    setOpenLogin,
    setPath,
    setSaveAppointment,
    animationLoading,
    setAnimationLoading,
  } = useContextValue();
  const { setException } = useException();
  const [values, setValues] = useState();
  const [openModal, setOpenModal] = useState();
  const [openUser, setOpenUser] = useState();

  const { data, loading: appointmentLoading } = useQuery(GET_APPOINTMENTS, {
    variables: {
      filter: {
        canceledAt: true,
        employeeId: employee?.id,
        date: moment(selDate),
      },
    },
    skip: !employee?.id || !selDate,
  });

  const services = useMemo(() => {
    const duration = listServices?.map(({ duration }) => duration);
    const price = listServices?.map(({ price }) => price);
    return {
      duration: duration?.reduce((acc, currentValue) => acc + currentValue, 0),
      price: price?.reduce((acc, currentValue) => acc + currentValue, 0),
    };
  }, [listServices]);

  const isEmployee = useMemo(
    () =>
      !loading && user?.isEmployee && user?.employeeIdentifier === employee.id,
    [loading, user, employee]
  );

  const { data: dataOperation, loading: operationLoading } = useQuery(
    GET_OPERATIONS,
    {
      variables: {
        filter: { employeeId: employee?.id },
      },
      skip: !employee?.id,
    }
  );

  const { data: dataService, loading: serviceLoading } = useQuery(
    GET_DURATION,
    {
      variables: {
        filter: { employeeId: employee?.id },
      },
      skip: !employee?.id,
    }
  );

  const [createAppointment, { loading: createLoading }] = useMutation(
    CREATE_APPOINTMENT_MUTATION
  );

  const [createWaitingList, { loading: createWaitingListLoading }] =
    useMutation(CREATE_WAITING_LIST_MUTATION);

  async function onWaitingList() {
    try {
      await createWaitingList({
        variables: {
          waitingList: {
            date: selDate,
            serviceId: selected.id,
            employeeId: employee?.id,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [getOperationName(GET_APPOINTMENTS)],
      });
      setOpenModal(false);
      onClose();
      toaster.push(
        MessageAlert("Adicionado na lista de espera.", TYPE_MSG.SUCCESS)
      );
    } catch (error) {
      setException(error);
    }
  }

  const queryLoading = useMemo(
    () =>
      loading ||
      operationLoading ||
      serviceLoading ||
      appointmentLoading ||
      createLoading ||
      createWaitingListLoading,
    [
      loading,
      appointmentLoading,
      serviceLoading,
      operationLoading,
      createLoading,
      createWaitingListLoading,
    ]
  );

  async function onSubmit() {
    try {
      await createAppointment({
        variables: {
          appointment: {
            employeeId: employee?.id,
            userId: values?.user?.id,
            date: moment(`${selectedHour}`, "YYYY-MM-DD HH:mm:ss"),
            userNotificationTimer: user?.notificationTimer,
            order: {
              observation: values?.observation,
              price: services.price,
              totalService: services.price,
              totalProduct: 0,
              services: listServices.map((res) => ({
                id: res.id,
                name: res.name,
                price: res.price,
                quantity: 1,
                duration: res.duration,
              })),
              totalDuration: services.duration,
            },
            locked: selected?.locked,
            isEmployee,
          },
        },
      });
      setAnimationLoading(true);
      setValues();
    } catch (error) {
      setAnimationLoading(false);
      setException(error);
    }
  }

  return (
    <Drawer
      backdrop="static"
      open={open}
      onClose={onClose}
      placement="bottom"
      style={{
        display: "flex",
        backgroundColor: "transparent",
        height: "100vh",
      }}
    >
      <div className="flex flex-col bg-white h-auto rounded-tl-[50px] fixed bottom-0 w-full gap-4">
        <div className="flex w-full gap-2">
          <div className="flex ml-10 -mt-10 rounded-md w-28 h-28">
            <img
              src="/./ios/180.png"
              alt=""
              width={100}
              height={100}
              className="object-cover"
            />
          </div>
          <div className="flex flex-col flex-1 p-4">
            <strong className="text-primary">{employee?.name}</strong>
            <span>{employee?.employeeType?.name}</span>
          </div>
        </div>
        <button
          onClick={onClose}
          className="absolute flex items-center justify-center w-10 h-10 bg-white rounded-full shadow-md -top-6 right-5"
        >
          <UilMultiply size={20} weight="fill" className="text-primary" />
        </button>
        {listServices?.map((res, i) => (
          <div
            key={i}
            className="flex items-center justify-between w-full px-4"
          >
            <strong className="w-2/3 text-primary">{res?.name}</strong>
            <strong className="text-primary ">{res?.duration} Minutos</strong>
          </div>
        ))}

        <div className="flex flex-col flex-1 gap-4 px-4 pb-4 overflow-auto">
          {isEmployee && (
            <div className="flex justify-between w-full">
              <AForm
                formComponent={Form}
                formProps={{
                  formLoading: queryLoading,
                  setOpenUser,
                  setValues,
                }}
              />
            </div>
          )}
          <CalendarNavigator
            operations={dataOperation?.operations}
            appointments={data?.appointments}
            serviceDuration={services?.duration}
            duration={dataService?.serviceDuration?.duration}
            loading={queryLoading}
          />
          {user?.id && (
            <div className="flex w-full">
              <button
                disabled={!selectedDay || !selectedHour}
                onClick={(value) => {
                  if (isEmployee && !values?.user?.name && !selected?.locked) {
                    setException("Erro", "Selecione o cliente");
                  } else {
                    onSubmit(value);
                  }
                }}
                className="flex items-center justify-center w-full p-4 text-white rounded-md bg-primary disabled:opacity-30"
              >
                Agendar
              </button>
            </div>
          )}
          {!user?.id && (
            <div className="flex w-full">
              <button
                disabled={!selectedDay || !selectedHour}
                onClick={() => {
                  setOpenLogin(true);
                  setPath();
                  setSaveAppointment({
                    selectedDay,
                    selectedHour,
                    selectedMonth,
                    selectedYear,
                    isEmployee,
                    employee,
                    services,
                    values,
                    listServices,
                    selected,
                  });
                }}
                className="flex items-center justify-center w-full p-4 text-white rounded-md bg-primary disabled:opacity-30"
              >
                Entrar
              </button>
            </div>
          )}
        </div>
        {animationLoading && (
          <div className="fixed flex items-center justify-center w-full h-full -translate-x-1/2 -translate-y-1/2 bg-black top-1/2 left-1/2 bg-opacity-90">
            <Animation
              width={150}
              height={150}
              animation={Calendar}
              loop={false}
              onAction={(e) => {
                if (e === "complete") {
                  setAnimationLoading(false);
                  toaster.push(
                    MessageAlert("Agendado com sucesso.", TYPE_MSG.SUCCESS)
                  );
                  setValues();
                  onClose();
                }
              }}
            />
          </div>
        )}
        {openModal && (
          <AlertModal
            open={openModal}
            cancelAction={() => {
              setOpenModal(false);
            }}
            title="Agendamento"
            message="Deseja entrar na lista de espera?"
            confirLabel="Sim"
            cancelLabel="Não"
            confirmAction={onWaitingList}
          />
        )}
      </div>
      {openUser && (
        <FinanceUserForm
          open={openUser}
          onClose={() => {
            setOpenUser(false);
          }}
        />
      )}
    </Drawer>
  );
}
