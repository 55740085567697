import React from "react";
import moment from "moment";

export default function ADateText({
  date,
  format = "DD-MM-YYYY",
  className,
  add = 0,
  addType = "minute",
}) {
  return (
    <span className={className}>
      {moment(date).add(add, addType).format(format)}
    </span>
  );
}
