import React, { useMemo } from "react";
import moment from "moment";
import AppointmentTimelineEmpty from "./appointment-timeline-empty";
import CardAppointment from "./card-appointment";

function compareDates(a, b) {
  const dateA = moment(a.date);
  const dateB = moment(b.date);

  const isAfterA = moment().isAfter(dateA);

  const isAfterB = moment().isAfter(dateB);

  if (isAfterA && !isAfterB) {
    return 1;
  } else if (!isAfterA && isAfterB) {
    return -1;
  } else {
    return 0;
  }
}

export default function ClientAppointment({ onDiscart, data }) {
  const appointments = useMemo(() => {
    return data?.peopleAppointments
      ?.filter((res) => {
        return !res.canceledAt;
      })
      ?.map((item) => ({
        ...item,
        active: moment(item.date).isSameOrAfter(
          moment().format("YYYY-MM-DD HH:mm")
        ),
      }));
  }, [data?.peopleAppointments]);

  return (
    <>
      {!appointments?.length ? (
        <div className="flex items-center justify-center">
          <AppointmentTimelineEmpty name="Nenhum agendamento encontrado até o momento." />
        </div>
      ) : (
        appointments?.sort(compareDates)?.map((res) => (
          <div key={res.id}>
            <CardAppointment
              client
              data={res}
              onDiscart={() => onDiscart(res)}
            />
          </div>
        ))
      )}
    </>
  );
}
