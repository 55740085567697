import { gql } from '@apollo/client';

export const AUTHENTICATE_MUTATION = gql`
  mutation authenticateClient($username: String, $password: String) {
    authenticateClient(username: $username, password: $password) {
      token
      user {
        id
        name
        email
        phone
        username
      }
    }
  }
`;

export const VERYFY_PHONE_MUTATION = gql`
  mutation verifyPhoneAndSendToken($phone: String, $email: String) {
    verifyPhoneAndSendToken(phone: $phone, email: $email)
  }
`;

export const VERYFY_PHONE_AND_TOKEN_MUTATION = gql`
  mutation verifyPhoneAndToken($phone: String, $email: String, $token: String) {
    verifyPhoneAndToken(phone: $phone, token: $token, email: $email)
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation resetPassword($phone: String, $password: String) {
    resetPassword(phone: $phone, password: $password)
  }
`;
