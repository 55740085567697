import moment from "moment";

export default function createSchedule({
  startTime,
  endTime,
  startBreak,
  endBreak,
  duration,
  date,
}) {
  const horariosManha = [];

  for (
    let hora = startTime.clone();
    hora.isBefore(endTime);
    hora.add(duration, "minutes")
  ) {
    if (hora.isBefore(moment(`${date} ${startBreak}`, "YYYY-MM-DD HH:mm"))) {
      horariosManha.push(hora.format("YYYY-MM-DD HH:mm"));
    }
  }

  const horariosTarde = [];

  for (
    let hora = moment(`${date} ${endBreak}`, "YYYY-MM-DD HH:mm");
    hora.isBefore(endTime);
    hora.add(duration, "minutes")
  ) {
    horariosTarde.push(hora.format("YYYY-MM-DD HH:mm"));
  }

  const horarios = horariosManha.concat(horariosTarde);

  return horarios;
}
