import React from "react";
import formatCurrency from "../../../utils/formatCurrency";

const plans = [
  {
    label: "Plano 1",
    list: [
      "2 Profissionais",
      "Comissões",
      "Lembretes de agendamento via WhatsApp",
      "Agendamentos Ilimitados",
      "Clientes Ilimitados",
      "Suporte via WhatsApp",
      "Sem Período de Fidelidade",
      "Ativação imediata",
    ],
    price: 49.9,
  },
  {
    label: "Plano 2",
    list: [
      "Até 5 Profissionais",
      "Comissões",
      "Lembretes de agendamento via WhatsApp",
      "Agendamentos Ilimitados",
      "Clientes Ilimitados",
      "Suporte via WhatsApp",
      "Sem Período de Fidelidade",
      "Ativação imediata",
    ],
    price: 79.9,
  },
  {
    label: "Plano 3",
    list: [
      "Até 10 Profissionais",
      "Comissões",
      "Lembretes de agendamento via WhatsApp",
      "Agendamentos Ilimitados",
      "Clientes Ilimitados",
      "Suporte via WhatsApp",
      "Sem Período de Fidelidade",
      "Ativação imediata",
    ],
    price: 129.9,
  },
];

export default function Plan() {
  return (
    <div className="flex flex-col items-center justify-center w-full gap-10 py-10 h-fit bg-primary">
      <div className="flex items-center justify-center w-full">
        <strong className="text-4xl text-white">Planos</strong>
      </div>
      <div className="grid h-full grid-cols-3 gap-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 max-w-7xl">
        {plans?.map((res) => (
          <div className="flex flex-col items-center justify-center gap-3 p-10 bg-white rounded-lg">
            <strong className="pb-2 text-4xl border-b-2 border-primary">
              {res?.label}
            </strong>
            {res?.list?.map((doc) => (
              <span>{doc}</span>
            ))}
            <strong className="text-4xl">{formatCurrency(res?.price)}</strong>
            <button
              onClick={() =>
                window.open(
                  "https://api.whatsapp.com/send?phone=5547984832451&text=Quero%20testar%20o%20aplicativo%20de%20agendamento.",
                  "_blank"
                )
              }
              className="flex justify-center px-4 py-2 mt-4 text-white rounded-md h-fit w-fit bg-primary whitespace-nowrap"
            >
              <strong>Teste grátis por 30 dias</strong>
            </button>
          </div>
        ))}
      </div>
      <strong className="text-lg text-white sm:text-center md:text-center lg:text-center ">
        Não vamos solicitar dados de pagamento para testar
      </strong>
    </div>
  );
}
