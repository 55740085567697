import React from "react";
import NumberFormat from "react-number-format";

function APhoneMask(props) {
  const { inputRef, onChange, value, ...other } = props;

  const setMaskPhone = (phone) => {
    phone = phone.replace(/\D/g, "");
    phone = phone.replace(/^(\d{2})(\d)/g, "($1) $2");
    phone = phone.replace(/(\d)(\d{4})$/, "$1-$2");
    return phone;
  };

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      value={value}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format={(val) => {
        return val && setMaskPhone(val);
      }}
      placeholder="(00) 00000-0000"
      mask="_"
      maxLength={15}
    />
  );
}

export default APhoneMask;
