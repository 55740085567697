import React from 'react';
import * as Yup from 'yup';
import AForm from '../../../components/Form';
import Button from '../../../components/Button';
import Spinner from '../../../components/Spinner';
import { InputTypes } from '../../../components/Input';
import FormFields from '../../../components/Form/form-fields';
import useException from '../../../hooks/use-exception';
import { useToaster } from 'rsuite';
import { useMutation } from '@apollo/client';
import { VERYFY_PHONE_AND_TOKEN_MUTATION } from '../queries';
import MessageAlert, { TYPE_MSG } from '../../../components/Message/message';

function Form({ loading }) {
  return (
    <div className="flex flex-col items-center justify-center w-full gap-4 py-4">
      <FormFields
        loading={loading}
        fields={[
          {
            type: InputTypes.CODE,
            label: 'Código',
            name: 'code',
            width: 'w-full',
            length: 6,
          },
        ]}
      />
      <div className="flex flex-col w-full gap-4">
        <Button
          label="Verificar"
          className="w-full p-3 text-white rounded-md bg-primary hover:bg-tertiary"
          type="submit"
          icon={<Spinner />}
          loading={loading}
        />
      </div>
    </div>
  );
}

export default function CodeForm({ onStep, phone, email }) {
  const { setException } = useException();
  const toaster = useToaster();

  const [verifyPhoneAndToken, { loading }] = useMutation(
    VERYFY_PHONE_AND_TOKEN_MUTATION,
  );

  async function onSubmit(values) {
    try {
      await verifyPhoneAndToken({
        variables: {
          token: values.code,
          phone,
          email,
        },
      });
      toaster.push(
        MessageAlert('Código verificado com sucesso.', TYPE_MSG.SUCCESS),
      );
      onStep(3);
    } catch (error) {
      setException(error);
    }
  }

  return (
    <AForm
      initialValues={{ code: '' }}
      onSubmit={onSubmit}
      formComponent={Form}
      formProps={{ loading }}
      validationSchema={Yup.object({
        code: Yup.string().required('Campo obrigatório'),
      })}
    />
  );
}
