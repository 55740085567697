import { gql } from "@apollo/client";

export const GET_APPOINTMENTS = gql`
  query appointments($filter: AppointmentFilter) {
    appointments(filter: $filter) {
      id
      date
      canceledAt
      order {
        id
        price
        type
      }
    }
  }
`;

export const GET_USERS = gql`
  query userClients($filter: UserClientFilter) {
    userClients(filter: $filter) {
      id
      name
      phone
      createdAt
      updatedAt
    }
  }
`;

export const GET_ORDERS_TOTALS = gql`
  query orderTotals($filter: OrderFilter) {
    orderTotals(filter: $filter) {
      id
      receive
      expense
      balance
      grossRevenue
      totalProducts
      totalServices
      totalClients
      consume
      listServices {
        serviceName
        totalQuantity
        totalFixedCommission
        totalPercentCommission
      }
      listProducts {
        productName
        totalQuantity
        totalFixedCommission
        totalPercentCommission
      }
      sumServices
      sumProducts
      totalAppointments {
        canceled
        finished
        pending
        totals
      }
      totalComissions
    }
  }
`;

export const GET_EMPLOYEE = gql`
  query employee($id: Int) {
    employee(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const GET_EMPLOYEES = gql`
  query employees($filter: EmployeeFilter) {
    employees(filter: $filter) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

export const GET_SERVICES = gql`
  query services($filter: ServiceFilter) {
    services(filter: $filter) {
      id
      name
      price
      duration
      createdAt
      updatedAt
    }
  }
`;

export const GET_PRODUCTS = gql`
  query products($filter: ProductFilter) {
    products(filter: $filter) {
      id
      name
      price
      quantity
      active
      comission
      createdAt
      updatedAt
    }
  }
`;

export const GET_ORDER = gql`
  query order($id: Int) {
    order(id: $id) {
      id
      totalService
      totalProduct
      totalDuration
      price
      type
      date
      createdAt
      updatedAt
      discount
      employee {
        id
        name
      }
      supplier {
        id
        name
        phone
      }
      userClient {
        id
        name
      }
      orderService {
        id
        name
        duration
        quantity
        price
        serviceFixed
        servicePercent
        serviceId
        plan
        service {
          id
          name
        }
      }
      orderProduct {
        id
        name
        quantity
        price
        comission
        productFixed
        productPercent
      }
    }
  }
`;

export const GET_ORDERS = gql`
  query orders($filter: OrderFilter) {
    orders(filter: $filter) {
      id
      price
      type
    }
  }
`;

export const GET_SUPPLIERS = gql`
  query suppliers($filter: SupplierFilter) {
    suppliers(filter: $filter) {
      id
      name
      phone
      active
    }
  }
`;

export const CREATE_ORDER = gql`
  mutation createOrder($order: OrderInput) {
    createOrder(order: $order) {
      id
    }
  }
`;

export const CREATE_USER_MUTATION = gql`
  mutation createUserClient($user: UserClientInput) {
    createUserClient(user: $user)
  }
`;

export const CREATE_SUPPLIER_MUTATION = gql`
  mutation createSupplier($supplier: SupplierInput) {
    createSupplier(supplier: $supplier) {
      id
    }
  }
`;

export const DELETE_ORDER = gql`
  mutation discartOrder($id: Int) {
    discartOrder(id: $id)
  }
`;
