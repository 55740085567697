import React from "react";

import Input from "../Input";
import ArrayField from "../Input/array.field";

function Field({ field, loading, width }) {
  if (field.type === "ARRAY") {
    return (
      <ArrayField
        field={field}
        loading={loading}
        width={width}
        visible={field.visible}
      />
    );
  }
  return <Input field={field} loading={loading} />;
}

function FormFields({ fields = [], loading, width }) {
  return fields.map((field, index) => (
    <Field field={field} key={index} loading={loading} width={width} />
  ));
}

export default FormFields;
