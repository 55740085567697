import { useField } from "formik";
import React, { useMemo } from "react";

export default function CheckboxField({
  attribute: { label, name, type, onChange, visible },
}) {
  const [field] = useField(name);

  const value = useMemo(() => {
    return field.value;
  }, [field]);

  return (
    <div className={`${visible ? "hidden" : "flex"} items-center`}>
      <input
        {...field}
        name={name}
        type={type}
        defaultChecked={value}
        value={value}
        checked={value}
        onClick={(e) => {
          if (onChange) {
            onChange(e.target.checked);
          }
          field.onChange(e);
        }}
        className="w-5 h-5 font-bold accent-primary"
      />

      <label className="ml-2 font-bold text-gray-600 text-md">{label}</label>
    </div>
  );
}
