import React from "react";
import { CarouselCustomNavigation } from "../components/Carousel";

const leftFeatures = [
  {
    label: "Agendamento rápido",
    description:
      "Seu cliente não precisa preencher formulário de cadastro longo e chato.",
  },
  {
    label: "Recorrência",
    description: "Faça agendamentos recorrentes.",
  },

  {
    label: "Lembretes",
    description: "O aplicativo envia lembretes automáticos por whatsapp.",
  },
  {
    label: "Agendamentos ilimitados",
    description: "Não se preocupe com a quantidade de agendamentos.",
  },
  {
    label: "Lista de espera",
    description:
      "Se o dia estiver cheio o cliente pode aguardar em uma lista de espera.",
  },
];

const rightFeatures = [
  {
    label: "Multi agenda",
    description:
      "Cadastre todos os profissionais no aplicativo para melhor organização.",
  },
  {
    label: "Financeiro",
    description: "Tenha controle de todas entradas e saidas.",
  },
  {
    label: "Comissão",
    description: "Sistema de comissionamento para seus funcionarios.",
  },

  {
    label: "Estoque",
    description: "Tenha controle do seus estoque de forma automática.",
  },
  {
    label: "Sua agenda na palma da mão",
    description:
      "Acompanhe quem está agendando e quem está cancelando em tempo real.",
  },
];

export default function Feature() {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full p-10 text-white">
      <div className="flex flex-col items-center justify-end w-full">
        <strong className="text-3xl sm:text-center md:text-center lg:text-center">
          Tenha mais praticidade com a Ageendae
        </strong>
      </div>
      <div className="flex items-center h-full gap-4 max-w-7xl">
        <div className="flex flex-col w-1/2 gap-2 text-white sm:hidden md:hidden lg:hidden">
          {leftFeatures?.map((res, i) => (
            <div
              key={i}
              className={`flex flex-col items-end justify-end text-end rounded-md p-4 ${
                i % 2 !== 0 ? "bg-primary text-black " : "bg-white text-black"
              }`}
            >
              <strong className="text-lg">{res?.label}</strong>
              <span className="w-80">{res?.description}</span>
            </div>
          ))}
        </div>
        <div className="flex items-center justify-center w-[500px] ">
          <CarouselCustomNavigation />
          {/* <img src="./mobile.png" alt="" className="object-cover h-[500px] " /> */}
        </div>
        <div className="flex flex-col w-1/2 gap-2 text-white sm:hidden md:hidden lg:hidden">
          {rightFeatures?.map((res, i) => (
            <div
              key={i}
              className={`flex flex-col items-start justify-start text-start rounded-md p-4 ${
                i % 2 !== 0 ? "bg-primary text-black " : "bg-white text-black"
              }`}
            >
              <strong className="text-lg">{res?.label}</strong>
              <span className="w-80">{res?.description}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col items-center justify-center gap-6">
        <strong className="text-2xl sm:text-center sm:w-3/4 md:text-center lg:text-center md:w-3/4 lg:w-3/4">
          Inicie um teste grátis sem compromisso!
        </strong>
        <button
          onClick={() =>
            window.open(
              "https://api.whatsapp.com/send?phone=5547984832451&text=Quero%20testar%20o%20aplicativo%20de%20agendamento.",
              "_blank"
            )
          }
          className="flex justify-center px-4 py-2 text-white rounded-md h-fit w-fit bg-primary whitespace-nowrap"
        >
          <strong className="text-lg">Teste grátis por 30 dias</strong>
        </button>
      </div>
    </div>
  );
}
