import React, { useState } from 'react';
import Modal from '../../../components/Modal';
import PhoneForm from './phone-form';
import CodeForm from './code-form';
import ResetPasswordForm from './reset-password-form';

export default function ChangePassword({
  open,
  onClose,
  title,
  subTitle,
  step,
  setStep,
}) {
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
      subTitle={subTitle}
      img="/./ios/192.png"
    >
      <div className="flex flex-col items-center justify-center h-full p-4">
        {step === 1 && (
          <PhoneForm
            onStep={(e) => setStep(e)}
            onPhone={(e) => setPhone(e)}
            onEmail={(e) => setEmail(e)}
          />
        )}
        {step === 2 && <CodeForm onStep={(e) => setStep(e)} phone={phone} />}
        {step === 3 && (
          <ResetPasswordForm
            phone={phone}
            email={email}
            onClose={() => {
              setStep(1);
              onClose();
            }}
          />
        )}
      </div>
    </Modal>
  );
}
