import React, { useMemo } from "react";
import { useMutation, useQuery } from "@apollo/client";
import * as Yup from "yup";
import { useToaster } from "rsuite";
import { getOperationName } from "@apollo/client/utilities";
import {
  CREATE_SERVICE_MUTATION,
  GET_EMPLOYEE,
  GET_SERVICE,
  GET_SERVICES,
  UPDATE_SERVICE_MUTATION,
} from "../../queries";
import { useFormikContext } from "formik";
import FormFields from "../../../../components/Form/form-fields";
import { InputTypes } from "../../../../components/Input";
import NumberIntegerMask from "../../../../components/Mask/number-integer";
import CurrencyMask from "../../../../components/Mask/currency-mask";
import Button from "../../../../components/Button";
import useException from "../../../../hooks/use-exception";
import AForm from "../../../../components/Form";
import MessageAlert, { TYPE_MSG } from "../../../../components/Message/message";
import Modal from "../../../../components/Modal";
import PercentageMask from "../../../../components/Mask/percentage-mask";

function Form({ formLoading }) {
  const { initialValues, values } = useFormikContext();

  return (
    <div className="flex flex-col w-full gap-4 pt-4">
      <FormFields
        loading={formLoading}
        width="flex flex-col w-full gap-4 items-end border-b py-4"
        fields={[
          {
            type: InputTypes.TEXT,
            name: "name",
            label: "Nome do serviço",
            width: "w-full",
          },
          {
            type: InputTypes.TEXT,
            name: "duration",
            label: "Duração do serviço em minutos",
            width: "w-full",
            mask: NumberIntegerMask,
          },
          {
            type: InputTypes.TEXT,
            name: "price",
            label: "Preço",
            width: "w-full",
            mask: CurrencyMask,
          },
          {
            type: InputTypes.CHECKBOX,
            name: "comission",
            label: "Comissão",
          },
          {
            type: InputTypes.TEXT,
            name: "serviceFixed",
            label: "Valor por serviço",
            mask: CurrencyMask,
            visible: !values.comission,
          },
          {
            type: InputTypes.TEXT,
            name: "servicePercent",
            label: "Porcentagem por serviço",
            mask: PercentageMask,
            visible: !values.comission,
          },
          {
            type: InputTypes.CHECKBOX,
            name: "active",
            label: "Ativo",
          },
        ]}
      />
      <div className="fixed flex flex-col gap-4 bottom-4 left-4 right-4">
        <Button
          label="Salvar"
          className="w-full p-3 text-white rounded-md bg-primary hover:bg-tertiary"
          type="submit"
          disabled={values === initialValues}
        />
      </div>
    </div>
  );
}

export default function ModalServiceForm({ open, onClose, employeeId, id }) {
  const toaster = useToaster();

  const { setException } = useException();

  const { data, loading } = useQuery(GET_SERVICE, {
    variables: {
      id,
    },
    skip: !id,
  });

  const [create, { loading: createLoading }] = useMutation(
    CREATE_SERVICE_MUTATION
  );

  const [update, { loading: updateLoading }] = useMutation(
    UPDATE_SERVICE_MUTATION
  );

  async function onSubmit(values) {
    try {
      if (id) {
        await update({
          variables: {
            service: {
              id,
              name: values?.name,
              price: values?.price,
              duration: Number(values?.duration),
              serviceFixed: Number(values?.serviceFixed),
              servicePercent: Number(values?.servicePercent),
              comission: values?.comission,
              active: values?.active,
            },
            employeeId,
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            getOperationName(GET_EMPLOYEE),
            getOperationName(GET_SERVICES),
          ],
        });
        toaster.push(MessageAlert("Atualizado com sucesso.", TYPE_MSG.SUCCESS));
      } else {
        await create({
          variables: {
            service: {
              name: values?.name,
              price: values?.price,
              duration: Number(values?.duration),
              serviceFixed: Number(values?.serviceFixed),
              servicePercent: Number(values?.servicePercent),
              comission: values?.comission,
              active: values?.active,
            },
            employeeId,
          },
          awaitRefetchQueries: true,
          refetchQueries: [
            getOperationName(GET_EMPLOYEE),
            getOperationName(GET_SERVICES),
          ],
        });

        toaster.push(MessageAlert("Cadastrado com sucesso.", TYPE_MSG.SUCCESS));
      }

      onClose();
    } catch (error) {
      setException(error);
    }
  }

  const formLoading = useMemo(
    () => loading || createLoading || updateLoading,
    [loading, createLoading, updateLoading]
  );

  const initialValues = useMemo(() => {
    if (!loading && data?.service) {
      return {
        ...data?.service,
      };
    }
    return {
      service: [
        {
          name: "",
          duration: "",
          price: "",
          comission: false,
          active: true,
        },
      ],
    };
  }, [loading, data]);

  return (
    <Modal
      title="Serviços"
      subTitle="Dados do serviços"
      open={open}
      onClose={onClose}
      img="/./ios/180.png"
    >
      <div className="flex h-full overflow-auto mb-[60px]">
        <AForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          formComponent={Form}
          formProps={{ formLoading }}
          validationSchema={Yup.object()
            .shape({
              name: Yup.string().required("Campo obrigatório"),
              duration: Yup.string().required("Campo obrigatório"),
              price: Yup.string().required("Campo obrigatório"),
              serviceFixed: Yup.number()
                .nullable()
                .test(
                  "comission",
                  "Prencha uma das opções de comissão",
                  function (value, context) {
                    if (context?.parent?.comission) {
                      if (!context?.parent?.servicePercent && !!value) {
                        return true;
                      }

                      if (!!context?.parent?.servicePercent && !value) {
                        return true;
                      }

                      if (!!context?.parent?.servicePercent && !!value) {
                        return false;
                      }

                      return false;
                    }
                    return true;
                  }
                ),
              servicePercent: Yup.number()
                .nullable()
                .test(
                  "comission",
                  "Prencha uma das opções de comissão",
                  function (value, context) {
                    if (context?.parent?.comission) {
                      if (!context?.parent?.serviceFixed && !!value) {
                        return true;
                      }

                      if (!!context?.parent?.serviceFixed && !value) {
                        return true;
                      }

                      if (!!context?.parent?.serviceFixed && !!value) {
                        return false;
                      }

                      return false;
                    }
                    return true;
                  }
                ),
            })
            .test("geral", "", function (value) {
              if (!!value?.comission) {
                if (!!value?.serviceFixed && !!value?.servicePercent) {
                  setException("Erro", "Escolha uma opção apenas de comissão.");
                }
              }
            })}
        />
      </div>
    </Modal>
  );
}
