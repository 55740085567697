import React from "react";

export default function Button({
  label,
  onAction,
  type,
  className,
  icon,
  disabled,
  loading,
}) {
  return (
    <button
      className={className}
      type={type}
      onClick={onAction}
      disabled={disabled}
    >
      {loading ? <span>{icon}</span> : <span> {label}</span>}
    </button>
  );
}
