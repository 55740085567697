"use client";

import React from "react";

export default function AppointmentTimelineEmpty({ name }) {
  return (
    <ol className="border-b last:border-none">
      <li>
        <div className="items-center block gap-4 p-4 sm:flex hover:bg-gray-100">
          <div className="flex justify-between w-full">
            <div className="text-gray-600">
              <strong className="font-medium">{name}</strong>
            </div>
          </div>
        </div>
      </li>
    </ol>
  );
}
