import { UilPen } from "@iconscout/react-unicons";
import {
  CheckCircle,
  Clock,
  ClockCounterClockwise,
  Coins,
} from "@phosphor-icons/react";
import formatCurrency from "../../../utils/formatCurrency";
import { usePermission } from "../../../hooks/use-permission";

export default function HomeCardService({
  label,
  onAction,
  duration,
  master,
  onEdit,
  active,
  checkedIds,
  id,
  price,
}) {
  const showPriceService = usePermission(7);

  return (
    <div
      className={`flex w-full border-2 rounded-md  ${
        checkedIds?.includes(id) && "border-primary"
      }`}
    >
      <div
        onClick={onAction}
        className={`flex items-center justify-between p-4 rounded-l-md bg-secondary cursor-pointer w-full `}
      >
        <div className="flex flex-col justify-center w-full">
          <strong className="text-primary">{label}</strong>
          <div className="flex items-center gap-2 text-slate-400">
            <Clock size={20} weight="bold" />
            <span>{duration} Minutos</span>
          </div>
          {showPriceService && (
            <div className="flex items-center gap-2 text-slate-400">
              <Coins size={20} weight="bold" />
              <span>{formatCurrency(price)}</span>
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center justify-end gap-4 p-4 rounded-r-md bg-secondary">
        {checkedIds?.includes(id) && (
          <div className="flex items-center justify-center rounded-full shadow-md">
            <CheckCircle size={20} className="text-primary" />
          </div>
        )}

        {master && (
          <button
            className={`flex items-center justify-center w-10 h-10 ${
              active ? "bg-white" : "bg-red-600 text-white"
            } rounded-full shadow-md`}
            onClick={onEdit}
          >
            <UilPen size={20} />
          </button>
        )}
      </div>
    </div>
  );
}
