import { useQuery } from "@apollo/client";
import React, { useMemo, useState } from "react";
import { GET_EMPLOYEES } from "../queries";
import useException from "../../../hooks/use-exception";
import { useAuth } from "../../../hooks/use-auth";
import { useParams } from "react-router-dom";
import unaccent from "../../../utils/unaccent";
import { MagnifyingGlass, Plus, ShareNetwork } from "@phosphor-icons/react";
import Animation from "../../../Animation/Animation";
import Hand from "../../../Animation/hand.json";
import CardEmployee from "./card-employee";
import HomeCardDetail from "./home-card-detail";
import ModalEmployeeForm from "./modal/modal-employee-form";
import ModalTimerForm from "./modal/modal-timer-form";

export default function Employees() {
  const { user, loading } = useAuth();
  const { company } = useParams();
  const { setException } = useException();
  const [openDetail, setOpenDetail] = useState();
  const [id, setId] = useState();
  const [searchText, setSearchText] = useState();
  const [openModalForm, setOpenModalForm] = useState();
  const [openModalTimer, setOpenModalTimer] = useState();

  if (company) {
    localStorage.setItem("company", company);
  }

  const isMasterOrManager = useMemo(
    () => (!loading && user?.master) || user?.manager,
    [user, loading]
  );

  const { data, loading: employeeLoading } = useQuery(GET_EMPLOYEES, {
    variables: {
      filter: {
        active: true,
        activeAppointment: user?.isEmployee || isMasterOrManager ? false : true,
        manager: isMasterOrManager,
      },
    },
  });

  const employees = useMemo(() => {
    return data?.employees?.filter((item) =>
      searchText
        ? unaccent(item?.name?.toLowerCase())?.includes(
            unaccent(searchText?.toLowerCase())
          )
        : item
    );
  }, [data, searchText]);

  function isWebShareSupported() {
    return "share" in navigator;
  }

  async function shareContent(url) {
    try {
      await navigator.share({
        title: "Ageendae",
        text: "Faça o agendamento através do link",
        url,
      });
    } catch (error) {
      setException(error);
    }
  }

  const handleShareClick = () => {
    const url = window.location.href;
    if (isWebShareSupported()) {
      shareContent(url);
    } else {
      window.open(
        `https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`,
        "_blank"
      );
    }
  };

  const formLoading = useMemo(
    () => loading || employeeLoading,
    [loading, employeeLoading]
  );

  return (
    <>
      <div className="flex flex-col w-full h-full gap-4 px-4 overflow-hidden">
        <div className="flex items-center justify-between w-full">
          <div className="flex flex-col w-full text-slate-400">
            <strong className="text-xl">Encontre o seu</strong>
            <strong className="text-xl">profissional favorito</strong>
          </div>
          <div
            onClick={handleShareClick}
            className="flex items-center h-full p-3 rounded-md cursor-pointer"
          >
            <ShareNetwork size={20} weight="bold" />
          </div>
        </div>

        <div className="flex items-center w-full h-full gap-2 rounded-md">
          <div className="flex items-center w-full gap-2 p-3 text-white rounded-md bg-primary">
            <input
              className="w-full text-white bg-transparent outline-none placeholder:text-white"
              placeholder="Buscar pelo nome"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <MagnifyingGlass size={20} />
          </div>
          {isMasterOrManager && (
            <button
              onClick={() => setOpenModalForm(true)}
              className="flex items-center h-[45px] p-3 text-white rounded-md bg-primary"
            >
              <Plus size={20} />
            </button>
          )}
        </div>
        <div
          className={`flex flex-col ${
            user?.manager
              ? "max-h-[calc(100vh-319px)]"
              : "max-h-[calc(100vh-221px)]"
          } overflow-auto gap-2 scroll-m-4 pr-2`}
        >
          {formLoading ? (
            <div className="fixed flex items-center justify-center w-full h-full -translate-x-1/2 -translate-y-1/2 bg-black bg-opacity-50 top-1/2 left-1/2 ">
              <Animation
                width={150}
                height={150}
                animation={Hand}
                loop={true}
              />
            </div>
          ) : (
            employees
              ?.sort((a, b) => {
                if (a.operation?.length < b.operation?.length) {
                  return 1;
                }

                if (a.operation?.length > b.operation?.length) {
                  return -1;
                }

                return 0;
              })
              ?.map((res, i) => (
                <div key={i}>
                  <CardEmployee
                    data={res}
                    onAction={() => {
                      setOpenDetail(true);
                      setId(res.id);
                    }}
                    manager={isMasterOrManager}
                  />
                </div>
              ))
          )}
        </div>
      </div>
      {openDetail && (
        <HomeCardDetail
          id={id}
          open={openDetail}
          onClose={() => {
            setOpenDetail(false);
            setId();
          }}
          onEdit={() => {
            setOpenModalForm(true);
          }}
          onOperation={() => {
            setOpenModalTimer(true);
          }}
        />
      )}
      {openModalForm && (
        <ModalEmployeeForm
          id={id}
          open={openModalForm}
          onClose={() => {
            setOpenModalForm(false);
          }}
        />
      )}
      {openModalTimer && (
        <ModalTimerForm
          id={id}
          open={openModalTimer}
          onClose={() => {
            setOpenModalTimer(false);
          }}
        />
      )}
    </>
  );
}
