import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "../modules/Home";
import LayoutPrivate from "../layout";
import Appointment from "../modules/Appointment";
import Profile from "../modules/Profile";
import NotFound from "../modules/NotFound";
import Site from "../modules/Site";
import Finance from "../modules/finance";
import { useQuery } from "@apollo/client";
import { GET_COMPANY } from "./queries";
import Animation from "../Animation/Animation";
import Hand from "../Animation/hand.json";
import { useAuth } from "../hooks/use-auth";
import NotPermission from "../modules/NotPermission";
import Company from "../modules/Company";
import History from "../modules/History";
import List from "../modules/List";

export default function Routers() {
  const { user } = useAuth();
  const url = window.location.href;
  const formatUrl = url.split("?")[0];
  const parts = formatUrl.split("/");

  if (url.includes("?")) {
    window.location.replace(formatUrl);
  }

  let tenancy = parts[3];
  let company = localStorage.getItem("company");

  const { data, loading } = useQuery(GET_COMPANY, {
    variables: {
      schema: company || tenancy,
    },
    skip: !tenancy?.includes("_") && !company,
  });

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center w-screen h-screen">
        <Animation animation={Hand} loop={true} width={150} />
        <strong>Carregando...</strong>
      </div>
    );
  }

  function isCompany() {
    if (!loading && data?.company) {
      return true;
    }
    return false;
  }

  if (!loading) {
    return (
      <Routes>
        {isCompany() && (
          <Route
            path="/"
            element={<Navigate to={`/${data?.company?.schema}`} />}
          />
        )}
        {!isCompany() && <Route path="/" element={<Site />} />}
        <Route path="/not-found" element={<NotFound />} />
        <Route path="/company" element={<Company />} />
        <Route path={`/`} element={<LayoutPrivate />}>
          <Route path={`/:company`} exact element={<Home />} />
          <Route
            path={`/:company/finance`}
            exact
            element={user?.isEmployee ? <Finance /> : <NotPermission />}
          />
          <Route
            path={`/:company/appointment`}
            exact
            element={user?.id ? <Appointment /> : <NotPermission />}
          />
          <Route
            path={`/:company/appointment/:id`}
            exact
            element={user?.id ? <Appointment /> : <NotPermission />}
          />
          <Route
            path={`/:company/list`}
            exact
            element={user?.id ? <List /> : <NotPermission />}
          />
          <Route
            path={`/:company/history`}
            exact
            element={user?.id ? <History /> : <NotPermission />}
          />
          <Route
            path={`/:company/profile`}
            exact
            element={user?.id ? <Profile /> : <NotPermission />}
          />
        </Route>
      </Routes>
    );
  }
}
