import React, { useMemo } from "react";
import { Outlet } from "react-router-dom";
import TabBottom from "../components/TabBottom";
import { useContextValue } from "../context";
import Login from "../modules/Login";
import Register from "../modules/Register";

export default function LayoutPrivate() {
  const { openLogin, setOpenLogin, path, openRegister, setOpenRegister } =
    useContextValue();

  return (
    <main className="flex flex-col min-h-screen bg-secondary">
      <Outlet />
      <TabBottom />
      {openLogin && (
        <Login
          open={openLogin}
          onClose={() => setOpenLogin()}
          title="Ageendae"
          subTitle="Entrar"
          path={path}
          onRegister={() => {
            setOpenLogin();
            setOpenRegister(true);
          }}
        />
      )}
      {openRegister && (
        <Register
          open={openRegister}
          onClose={() => setOpenRegister(false)}
          onLogin={() => {
            setOpenRegister(false);
            setOpenLogin(true);
          }}
          title="Ageendae"
          subTitle="Cadastre-se"
        />
      )}
    </main>
  );
}
