import React from "react";
import { FieldArray, useField, useFormikContext } from "formik";

import Input from "./index";
import { PlusCircle, Trash } from "@phosphor-icons/react";
import { Box } from "@mui/material";

export default function ArrayField({ field, loading, width }) {
  const { values } = useFormikContext();
  const [, meta] = useField(field.name);

  const value = values[field.name]?.length ? values[field.name] : [];

  const renderDeleteButton = (arrayHelpers, valueIndex) => {
    if (
      field.alwaysShowDelete ||
      (values[field.name]?.length > 1 &&
        valueIndex > 0 &&
        field.alwaysShowDelete)
    ) {
      return (
        <button
          type="button"
          onClick={() => arrayHelpers.remove(valueIndex)}
          className="hover:text-red-600"
        >
          <Trash size={25} />
        </button>
      );
    }
    return null;
  };

  const isAddVisibile = (addField) => {
    if (
      addField?.allowAdd === undefined ||
      addField?.allowAdd === null ||
      addField?.allowAdd === 0
    ) {
      addField.allowAdd = true;
    }
    return typeof addField?.allowAdd === "function"
      ? addField.allowAdd(values)
      : addField?.allowAdd;
  };

  return (
    <div className={`${field?.visible ? "hidden" : "flex flex-col gap-2"}`}>
      <FieldArray
        name={field.name}
        render={(arrayHelpers) => (
          <>
            {value.map((_, valueIndex) => (
              <React.Fragment key={valueIndex}>
                <div key={valueIndex}>
                  <div className={width}>
                    {field.children.map((child, childIndex) => (
                      <div key={childIndex} className="flex flex-wrap w-full">
                        <Input
                          field={{
                            ...child,
                            index: valueIndex,
                            name: `${field.name}[${valueIndex}].${child.name}`,
                          }}
                          loading={loading}
                        />
                      </div>
                    ))}
                    {renderDeleteButton(arrayHelpers, valueIndex)}
                  </div>
                </div>
              </React.Fragment>
            ))}
            <Box
              display="flex"
              width="100%"
              alignItems="flex-end"
              flexDirection="column"
            >
              {isAddVisibile(field) && (
                <>
                  {!loading && (
                    <div className="flex gap-1">
                      {value.length > 0 && (
                        <button
                          type="button"
                          className="p-2 text-base font-bold text-white duration-300 rounded-lg bg-primary hover:bg-slate-500 animate"
                          onClick={() => {
                            const i = values[field.name]?.length;
                            const newValue = {
                              ...values[field.name][i - 1],
                            };
                            delete newValue.id;
                            arrayHelpers.push(newValue);
                          }}
                          startIcon={<PlusCircle />}
                        >
                          {field.duplicateLabel || "Duplicar"}
                        </button>
                      )}
                      <button
                        type="button"
                        className="p-2 text-base font-bold text-white duration-300 rounded-lg bg-primary hover:bg-slate-500 animate"
                        onClick={() => {
                          arrayHelpers.push({});
                        }}
                        startIcon={<PlusCircle />}
                      >
                        {field.addMore || "Adicionar"}
                      </button>
                    </div>
                  )}
                </>
              )}
            </Box>
          </>
        )}
      />
    </div>
  );
}
