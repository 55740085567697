import { useQuery } from "@apollo/client";
import { GET_COMPANY_QUERY } from "./queries";
import { useMemo } from "react";

export const advanceScheduling = [
  { id: 1, name: "10 min", value: "10" },
  { id: 2, name: "20 min", value: "20" },
  { id: 3, name: "30 min", value: "30" },
  { id: 4, name: "1 hora", value: "60" },
  { id: 5, name: "2 horas", value: "120" },
  { id: 6, name: "3 horas", value: "180" },
  { id: 7, name: "4 horas", value: "240" },
  { id: 8, name: "5 horas", value: "300" },
  { id: 9, name: "6 horas", value: "360" },
  { id: 10, name: "12 horas", value: "720" },
  { id: 11, name: "24 horas", value: "1440" },
  { id: 12, name: "48 horas", value: "2880" },
];

export function usePermission(feature) {
  const url = window.location.href;
  const parts = url.split("/");
  let tenancy = parts[3];

  const { data, loading } = useQuery(GET_COMPANY_QUERY, {
    variables: {
      schema: tenancy,
    },
  });

  const featureFind = useMemo(() => {
    return data?.company?.permissions?.find((res) => res?.id === feature);
  }, [data?.company?.permissions, feature, loading]);

  if (featureFind?.type === "CHECKBOX") {
    return featureFind.value === "true" ? true : false;
  }

  if (featureFind?.type === "AUTOCOMPLETE") {
    return featureFind.value;
  }

  return false;
}
