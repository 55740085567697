import { TextField } from "@mui/material";
import { styled } from "@mui/styles";
import { useField } from "formik";
import moment from "moment";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#C7DFE9",
    fontSize: 12,
  },
  "& label": {
    color: "#64748B",
    fontSize: 12,
  },
  "& input": {
    color: "#64748B",
    fontSize: 12,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#C7DFE9",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#64748B",
    },
    "&:hover fieldset": {
      borderColor: "#C7DFE9",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#C7DFE9",
    },
    "&:after fieldset": {
      borderColor: "#C7DFE9",
    },
  },
});

function TimerField({
  attribute: { label, name, type, onChange, disabled, width },
}) {
  const [field, meta] = useField(name);

  return (
    <div className={`flex ${width}`}>
      <CssTextField
        {...field}
        error={!!meta.error}
        helperText={meta.error}
        type="time"
        label={label}
        name={name}
        variant="outlined"
        fullWidth
        disabled={disabled}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}
      />
    </div>
  );
}

export default TimerField;
