import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAuth } from "../hooks/use-auth";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { REGISTER_NOTIFICATION_MUTATION } from "./queries";

export const StateContext = createContext();

export default function ContextProvider({ children }) {
  const { user } = useAuth();
  const location = useLocation();
  const [path, setPath] = useState();
  const [openLogin, setOpenLogin] = useState();
  const [subscribe, setSubscribe] = useState();
  const [openRegister, setOpenRegister] = useState();
  const [selectedYear, setSelectedYear] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(0);
  const [selectedDay, setSelectedDay] = useState(0);
  const [listDays, setListDays] = useState([]);
  const [listHours, setListHours] = useState([]);
  const [selectedHour, setSelectedHour] = useState();
  const [saveAppointment, setSaveAppointment] = useState();
  const [animationLoading, setAnimationLoading] = useState();

  const [registerNotification, { loading: registerLoading }] = useMutation(
    REGISTER_NOTIFICATION_MUTATION
  );

  useEffect(() => {
    setSelectedYear(moment().year());
    setSelectedMonth(moment().month());
    setSelectedDay(moment().date());
  }, [location, user]);

  function handleLeft() {
    let mountDate = new Date(selectedYear, selectedMonth, 1);
    mountDate.setMonth(mountDate.getMonth() - 1);

    setSelectedYear(mountDate.getFullYear());
    setSelectedMonth(mountDate.getMonth());
    setSelectedDay(1);
    setSelectedHour();
    setListHours([]);
  }

  function handleRight() {
    let mountDate = new Date(selectedYear, selectedMonth, 1);
    mountDate.setMonth(mountDate.getMonth() + 1);

    setSelectedYear(mountDate.getFullYear());
    setSelectedMonth(mountDate.getMonth());
    setSelectedDay(1);
    setSelectedHour();
    setListHours([]);
  }

  const selDate = useMemo(() => {
    if (selectedDay > 0) {
      let d = new Date(selectedYear, selectedMonth, selectedDay);
      let year = d.getFullYear();
      let month = d.getMonth() + 1;
      let day = d.getDate();

      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;

      let selDate = year + "-" + month + "-" + day;

      return selDate;
    }
  }, [selectedDay, selectedYear, selectedMonth]);

  return (
    <StateContext.Provider
      value={{
        path,
        setPath,
        handleLeft,
        handleRight,
        setSelectedYear,
        selectedYear,
        setSelectedMonth,
        selectedMonth,
        setSelectedDay,
        selectedDay,
        setSelectedHour,
        selectedHour,
        setListDays,
        listDays,
        setListHours,
        listHours,
        selDate,
        openLogin,
        setOpenLogin,
        openRegister,
        setOpenRegister,
        registerNotification,
        registerLoading,
        subscribe,
        setSubscribe,
        saveAppointment,
        setSaveAppointment,
        animationLoading,
        setAnimationLoading,
      }}
    >
      {children}
    </StateContext.Provider>
  );
}

export const useContextValue = () => useContext(StateContext);
