import React from "react";
import Modal from "../../../components/Modal";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { Calendar, Coins } from "@phosphor-icons/react";
import { GET_POINTS } from "../queries";

export default function ModalPointList({ open, onClose, selected }) {
  const { data, loading } = useQuery(GET_POINTS, {
    variables: {
      filter: { userId: selected?.userId, companyId: selected?.companyId },
    },
    skip: !selected,
  });

  return (
    <Modal
      title="Pontos"
      subTitle={data?.points[0]?.userClient?.name}
      open={open}
      onClose={onClose}
      img="/./ios/180.png"
    >
      <>
        {data?.points?.map((item, i) => (
          <div
            key={i}
            className={`flex w-full border-l-8 ${
              item?.value > 0 &&
              moment(item?.expirationDate).endOf("day").isAfter(moment())
                ? "border-green-600"
                : "border-red-700"
            } rounded-md shadow bg-secondary`}
          >
            <div className="flex items-center w-full p-4">
              <strong>{item?.service?.name}</strong>
            </div>
            <div className="flex items-center gap-4 p-4 min-w-fit">
              <strong>
                <Coins size={25} />
              </strong>
              <strong className="w-full">{item?.value}</strong>
            </div>
            <div className="flex items-center gap-4 p-4 min-w-fit">
              <strong>
                <Calendar size={25} />
              </strong>
              <strong className="w-full">
                {moment(item.expirationDate).format("DD-MM-YYYY")}
              </strong>
            </div>
          </div>
        ))}
      </>
    </Modal>
  );
}
