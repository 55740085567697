import React, { useState } from "react";
import * as Yup from "yup";
import FormFields from "../../components/Form/form-fields";
import { InputTypes } from "../../components/Input";
import Button from "../../components/Button";
import { SignOut } from "@phosphor-icons/react";
import { useAuth } from "../../hooks/use-auth";
import { useNavigate } from "react-router-dom";
import AForm from "../../components/Form";
import Modal from "../../components/Modal";
import APhoneMask from "../../components/Mask/a-phone-number.mask";
import Spinner from "../../components/Spinner";
import { useContextValue } from "../../context";
import { CREATE_APPOINTMENT_MUTATION } from "../Home/queries";
import { useMutation } from "@apollo/client";
import moment from "moment";
import useException from "../../hooks/use-exception";
import ChangePassword from "./views";

function Form({ loading, onRegister, setOpenReset }) {
  return (
    <div className="flex flex-col items-center justify-center w-full gap-4 py-4">
      <FormFields
        loading={loading}
        fields={[
          {
            type: InputTypes.TEXT,
            label: "Whastapp",
            name: "phone",
            width: "w-full",
            mask: APhoneMask,
          },
          {
            type: InputTypes.PASSWORD,
            label: "Senha",
            name: "password",
            width: "w-full",
          },
        ]}
      />
      <div className="flex items-center justify-end w-full py-4">
        <button type="button" className="" onClick={() => setOpenReset(true)}>
          Esqueci minha senha?
        </button>
      </div>
      <div className="flex flex-col w-full gap-4">
        <Button
          label="Entrar"
          className="w-full p-3 text-white rounded-md bg-primary hover:bg-tertiary"
          type="submit"
          icon={<Spinner />}
          loading={loading}
        />
        <Button
          label="Criar conta"
          className="flex items-center justify-center w-full gap-4 p-3 bg-gray-200 rounded-md"
          onAction={onRegister}
          icon={<SignOut size={20} />}
          type="button"
        />
      </div>
    </div>
  );
}

export default function Login({
  open,
  onClose,
  title,
  subTitle,
  onRegister,
  path,
}) {
  const { user, signin, loading, loadUser } = useAuth();
  const { setException } = useException();
  const navigate = useNavigate();
  const [openReset, setOpenReset] = useState();
  const [step, setStep] = useState(1);

  const { saveAppointment, setAnimationLoading, setSaveAppointment } =
    useContextValue();

  const [createAppointment, { loading: createLoading }] = useMutation(
    CREATE_APPOINTMENT_MUTATION
  );

  async function createAppointmentBeforeLogin() {
    try {
      await createAppointment({
        variables: {
          appointment: {
            employeeId: saveAppointment?.employee?.id,
            date: moment(
              `${saveAppointment?.selectedHour}`,
              "YYYY-MM-DD HH:mm:ss"
            ),
            userNotificationTimer: user?.notificationTimer,
            order: {
              price: saveAppointment?.services.price,
              totalService: saveAppointment?.services.price,
              totalProduct: 0,
              services: saveAppointment?.listServices.map((res) => ({
                id: res.id,
                name: res.name,
                price: res.price,
                quantity: 1,
                duration: res.duration,
              })),
              totalDuration: saveAppointment?.services.duration,
            },
            locked: saveAppointment?.selected?.locked,
            isEmployee: saveAppointment?.isEmployee,
          },
        },
      });
      setAnimationLoading(true);
      setSaveAppointment();
    } catch (error) {
      setAnimationLoading(false);
      setException(error);
    }
  }

  async function onSubmit(values) {
    const schema = localStorage.getItem("schema");

    const authenticated = await signin({
      phone: values.phone,
      password: values.password,
      schema,
    });

    if (authenticated) {
      onClose();
      loadUser();
      if (saveAppointment) {
        await createAppointmentBeforeLogin(saveAppointment);
      }

      if (path) {
        navigate(path);
      }
    }
  }

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        title={title}
        subTitle={subTitle}
        img="/./ios/192.png"
      >
        <div className="flex flex-col items-center justify-center h-full p-4">
          <AForm
            initialValues={{ phone: "", password: "" }}
            onSubmit={onSubmit}
            formComponent={Form}
            formProps={{ loading, onRegister, setOpenReset }}
            validationSchema={Yup.object({
              phone: Yup.string().required("Campo obrigatório"),
              password: Yup.string().required("Campo obrigatório"),
            })}
          />
        </div>
      </Modal>
      {openReset && (
        <ChangePassword
          title="Recuperar senha"
          subTitle={
            step === 1
              ? "Digite seu telefone de cadastro para resetar a senha."
              : "Digite o código que recebeu pelo Whatsapp."
          }
          open={openReset}
          onClose={() => setOpenReset(false)}
          step={step}
          setStep={setStep}
        />
      )}
    </>
  );
}
