import { gql } from "@apollo/client";

export const GET_COMPANY = gql`
  query company($schema: String) {
    company(schema: $schema) {
      id
      schema
    }
  }
`;
