import { TextField } from "@mui/material";
import { styled } from "@mui/styles";
import { MagnifyingGlass } from "@phosphor-icons/react";
import React from "react";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    fontSize: 14,
  },
  "& label": {
    fontSize: 14,
  },
  "& input": {
    fontSize: 14,
  },
  "& .MuiInput-underline:after": {
    border: "none",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
    "&:after fieldset": {
      border: "none",
    },
  },
});

export default function Search({ label, onChange }) {
  return (
    <div className="flex items-center w-full gap-4 px-4 rounded-md bg-quaternary">
      <span className="text-xl text-orange-600">
        <MagnifyingGlass />
      </span>
      <span className="flex w-full">
        <CssTextField placeholder={label} onChange={onChange} />
      </span>
    </div>
  );
}
