import { CheckCircle, XCircle } from "@phosphor-icons/react";
import React from "react";
import { Message } from "rsuite";

export const TYPE_MSG = {
  ERROR: "error",
  INFO: "info",
  WARNING: "warning",
  SUCCESS: "success",
};

export default function MessageAlert(text, type) {
  return (
    <Message type={type}>
      <div className="flex gap-2">
        {type === "error" ? (
          <XCircle weight="fill" color="red" size={20} />
        ) : (
          <CheckCircle weight="fill" color="green" size={20} />
        )}

        <b className="text-gray-600">{text}</b>
      </div>
    </Message>
  );
}
