import { gql } from "@apollo/client";

export const GET_APPOINTMENT = gql`
  query appointment($id: Int) {
    appointment(id: $id) {
      id
      date
      canceledAt
      createdAt
      updatedAt
      notification

      employee {
        id
        name
      }
      userClient {
        id
        name
        phone
        birthdate
      }
      order {
        price
        type
        orderService {
          id
          name
          duration
          quantity
          price
          plan
          service {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_APPOINTMENTS = gql`
  query appointments($filter: AppointmentFilter) {
    appointments(filter: $filter) {
      id
      date
      canceledAt
      createdAt
      updatedAt
      recurrence
      notification
      employee {
        id
        name
      }
      userClient {
        id
        name
        phone
        birthdate
      }
      company {
        id
        name
      }
      order {
        price
        type
        observation
        orderService {
          id
          name
          duration
          quantity
          price
          plan
          service {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_APPOINTMENT_RECURRENCE = gql`
  query appointmentRecurrence($filter: AppointmentFilter) {
    appointmentRecurrence(filter: $filter) {
      id
      date
      canceledAt
      createdAt
      updatedAt
      recurrence
      notification
      employee {
        id
        name
      }
      userClient {
        id
        name
        phone
      }
      order {
        price
        type
        orderService {
          id
          name
          duration
          quantity
          price
          plan
          service {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_PEOPLE_APPOINTMENTS = gql`
  query peopleAppointments($filter: AppointmentFilter) {
    peopleAppointments(filter: $filter) {
      id
      date
      canceledAt
      createdAt
      updatedAt
      company {
        id
        name
      }
      employee {
        id
        name
      }
      userClient {
        id
        name
        phone
      }
      order {
        price
        type
        orderService {
          id
          name
          duration
          quantity
          price
          plan
          service {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_OPERATIONS = gql`
  query operations($filter: OperationFilter) {
    operations(filter: $filter) {
      id
      dayWeek
      startDate
      startBrake
      endBrake
      endDate
      createdAt
      updatedAt
      employee {
        id
        name
      }
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query notifications($filter: NotificationFilter) {
    notifications(filter: $filter) {
      id
      active
      appointment {
        id
        canceledAt
        date
        order {
          price
          type
          orderService {
            id
            name
            duration
            quantity
            price
          }
        }
      }
      userClient {
        id
        name
        phone
      }
    }
  }
`;

export const GET_WAITING_LISTS = gql`
  query waitingLists($filter: WaitingListFilter) {
    waitingLists(filter: $filter) {
      id
      date
      sendNotification
      userClient {
        id
        name
        phone
      }
      service {
        id
        name
        duration
        price
      }
    }
  }
`;

export const DISCART_APPOINTMENT_MUTATION = gql`
  mutation discartAppointment($appointment: AppointmentInput) {
    discartAppointment(appointment: $appointment) {
      id
    }
  }
`;

export const SEND_NOTIFICATION_WAITING_LIST_MUTATION = gql`
  mutation sendNotificationWaitingList($notification: NotificationInput) {
    sendNotificationWaitingList(notification: $notification)
  }
`;
