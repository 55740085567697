import React, { useContext, createContext, useState, useEffect } from "react";

import { useAuth } from "./use-auth";

const companyContext = createContext();

function useProvideCompany() {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState();

  useEffect(() => {
    if (user?.company) {
      setCompany(user?.company);
    } else {
      setCompany();
    }
  }, [user]);

  useEffect(() => {
    if (company) {
      setLoading(false);
    }
  }, [company]);

  return {
    company,
    setCompany,
    loading,
  };
}

export function ProvideCompany({ children }) {
  const company = useProvideCompany();

  return (
    <companyContext.Provider value={company}>
      {children}
    </companyContext.Provider>
  );
}

export const useCompany = () => {
  return useContext(companyContext);
};
