import { ArrowLeft, ArrowRight } from "@phosphor-icons/react";
import React from "react";

function ButtonIcon({ id, onClick, name, color }) {
  return (
    <div
      id={id}
      data-testid={id}
      className={`flex content-center items-center justify-center rounded-md p-1 m-[2px] max-w-[48px] ${color} hover:bg-blackOpacity cursor-pointer`}
      onClick={onClick}
    >
      {name === "left" ? <ArrowLeft size={16} /> : <ArrowRight size={16} />}
    </div>
  );
}

export default ButtonIcon;
