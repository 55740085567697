import React from "react";

export default function LinkButton({ path, label, action }) {
  return (
    <button
      onClick={action}
      className="justify-between h-full pt-2 border-b-4 border-transparent hover:border-primary w-fit hover:text-primary"
    >
      <strong className="sm:text-lg md:text-lg lg:text-lg ">{label}</strong>
    </button>
  );
}
