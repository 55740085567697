import { ArrowDown, ArrowUp } from "@phosphor-icons/react";
import React, { useEffect, useMemo, useState } from "react";
import AForm from "../../../components/Form";
import FormFields from "../../../components/Form/form-fields";
import { useFormikContext } from "formik";

function Form({ fields, onChange }) {
  const { values } = useFormikContext();

  useEffect(() => {
    // onChange(values);
  }, [values]);

  return (
    <div className="flex flex-col w-full h-full gap-4 pt-2">
      <FormFields fields={fields} />
    </div>
  );
}

export default function CardPermission({
  title,
  description,
  fields,
  name,
  value,
  onChange,
}) {
  const [open, setOpen] = useState();

  const initialValues = useMemo(() => {
    return { [name]: value };
  }, [name, value]);

  return (
    <div className="flex flex-col w-full px-4 pb-4 border-b last:border-none">
      <div className="flex items-center justify-between w-full px-4 ">
        <div className="flex flex-col w-3/4">
          <strong>{title}</strong>
          <span className="text-sm">{description}</span>
        </div>
        <button type="button" onClick={() => setOpen(!open)}>
          {open ? <ArrowUp size={20} /> : <ArrowDown size={20} />}
        </button>
      </div>
      {open && (
        <div className="flex w-full p-4">
          <AForm
            initialValues={initialValues}
            formComponent={Form}
            formProps={{ fields, onChange }}
          />
        </div>
      )}
    </div>
  );
}
