import { gql } from "@apollo/client";

export const TOKEN_KEY = "token";
export const SCHEMA_KEY = "schema";
export const COMPANY_KEY = "company";
export const TIMEZONE_KEY = "timezone";
export const TOKEN_GUEST =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MCwibmFtZSI6IlZpc2l0YW50ZSIsImlhdCI6MTUxNjIzOTAyMn0.tDICb_j-07P7SxNc0oVu-eiHTXJvOcRRA2SA29SdJO0";

export const USER_QUERY = gql`
  query currentUserClient {
    currentUserClient {
      id
      name
      phone
      master
      employeeIdentifier
      companyIdentifier
      active
      updatedAt
      isEmployee
      isSchedule
      manager
      birthdate
      notificationTimer
      company {
        id
        name
        schema
        timezone
        permissions {
          id
          name
          value
          type
          label
          title
          description
          createdAt
          updatedAt
        }
      }
      address {
        state {
          id
          name
          uf
        }
        city {
          id
          name
        }
      }
    }
  }
`;

export const AUTHENTICATE_MUTATION = gql`
  mutation authenticateClient(
    $phone: String
    $password: String
    $schema: String
  ) {
    authenticateClient(phone: $phone, password: $password, schema: $schema) {
      token
      user {
        id
        name
        phone
        active
        schema
        master
        isEmployee
        employeeIdentifier
        companyIdentifier
      }
    }
  }
`;

export const GET_COMPANY_QUERY = gql`
  query company($id: Int, $schema: String) {
    company(id: $id, schema: $schema) {
      id
      name
      schema
      timezone
      permissions {
        id
        name
        value
        type
        label
        title
        description
        createdAt
        updatedAt
      }
    }
  }
`;
