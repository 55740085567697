import {
  Autocomplete,
  TextField,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { styled } from "@mui/styles";
import { useField, useFormikContext } from "formik";
import React, { useMemo, useState } from "react";
import Button from "../Button";
import { Plus } from "@phosphor-icons/react";
import Spinner from "../Spinner";
import { usePermission } from "../../hooks/use-permission";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#575757",
    fontSize: 14,
  },
  "& label": {
    color: "#575757",
    fontSize: 14,
  },
  "& input": {
    color: "#575757",
    fontSize: 14,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#575757",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#575757",
    },
    "&:hover fieldset": {
      borderColor: "#575757",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#575757",
    },
    "&:after fieldset": {
      borderColor: "#575757",
    },
  },
});

function AutoCompleteField({
  attribute: {
    label,
    name,
    type,
    options = [],
    itemValue = "id",
    itemLabel = "name",
    autoComplete = "off",
    onChange,
    disabled,
    freeSolo = true,
    width,
    variant = "outlined",
    visible = false,
    index,
    onButton,
    onButtonAction,
    onChangeInput,
    loading: userLoading,
    noOptionsLabel = "Sem registros",
    getOptionLabel,
    onInputChange,
  },
  loading,
}) {
  const [field, meta] = useField(name);
  const { values, setFieldValue, initialValues } = useFormikContext();
  const [textFieldValue, setTextFieldValue] = useState();
  const showNumberWpp = usePermission(4);
  const filter = createFilterOptions();

  const value = useMemo(() => {
    return field.value ? field.value : null;
  }, [field]);

  const noOptionsText = useMemo(() => {
    if (userLoading) {
      return <Typography>Carregando...</Typography>;
    }

    if (!textFieldValue && onChangeInput && !userLoading) {
      return <Typography>Digite para buscar</Typography>;
    }

    return <Typography>{noOptionsLabel}</Typography>;
  }, [userLoading, textFieldValue, onChangeInput, noOptionsLabel]);

  const filterOptionsCore = (opt, params) => {
    const filtered = filter(opt, params);

    return filtered.map((res) => {
      const split = res?.name?.split(" - ");
      if (showNumberWpp || !split?.length) {
        return res;
      } else {
        return { ...res, name: split[0] };
      }
    });
  };

  return (
    <div className={`${visible ? "hidden" : "flex"} ${width} gap-2`}>
      <Autocomplete
        {...field}
        loading={loading || userLoading}
        disablePortal
        fullWidth
        disabled={
          typeof disabled === "string"
            ? initialValues?.complement?.some(
                (doc) => doc?.option?.id === value?.id
              )
            : disabled
        }
        readOnly={disabled}
        freeSolo={freeSolo}
        type={type}
        value={value}
        id={name?.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "")}
        options={options}
        getOptionLabel={(option) => {
          if (getOptionLabel) {
            return getOptionLabel(option);
          }

          return option[itemLabel] || "";
        }}
        onChange={(_, e, i) => {
          setFieldValue(name, e);
          if (onChange) {
            onChange(e, index);
          }
          setTextFieldValue(e?.target?.value || null);
        }}
        onChangeCapture={(e) => {
          if (onChangeInput) {
            onChangeInput(e.target.value);
          }
        }}
        onInputChange={onInputChange}
        loadingText="Carregando..."
        filterOptions={(opt, params) => filterOptionsCore(opt, params)}
        noOptionsText={noOptionsText}
        ListboxProps={{ style: { maxHeight: 150 } }}
        renderInput={(params) => (
          <CssTextField
            {...params}
            autoComplete={autoComplete}
            label={label}
            error={!!meta.error}
            helperText={meta.error}
            variant={variant}
            disabled={disabled}
            onChange={(e) => {
              if (onChange) {
                onChange(e.target.value, values);
              }
            }}
            InputProps={{
              ...params.InputProps,
              autoComplete,
              inputProps: {
                ...params.inputProps,
                autoComplete,
              },
              endAdornment: (
                <>
                  {userLoading && <Spinner />}
                  {params?.InputProps?.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      {onButton && (
        <Button
          label={<Plus size={20} />}
          className="flex items-center justify-center text-white rounded-md cursor-pointer w-14 h-[53px] bg-primary hover:bg-tertiary"
          type="button"
          onAction={onButtonAction}
          icon={<Spinner />}
          loading={loading}
        />
      )}
    </div>
  );
}

export default AutoCompleteField;
