import React, { useMemo, useState } from "react";
import * as Yup from "yup";
import FormFields from "../../../components/Form/form-fields";
import { InputTypes } from "../../../components/Input";
import Button from "../../../components/Button/index";
import Modal from "../../../components/Modal";
import AForm from "../../../components/Form";
import { useFormikContext } from "formik";
import CurrencyMask from "../../../components/Mask/currency-mask";
import moment from "moment";
import { useMutation, useQuery } from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";
import {
  CREATE_ORDER,
  GET_EMPLOYEES,
  GET_ORDERS_TOTALS,
  GET_PRODUCTS,
  GET_SERVICES,
  GET_SUPPLIERS,
  GET_USERS,
} from "../queries";
import MessageAlert, { TYPE_MSG } from "../../../components/Message/message";
import { useToaster } from "rsuite";
import useException from "../../../hooks/use-exception";
import Spinner from "../../../components/Spinner";
import { useAuth } from "../../../hooks/use-auth";
import FinanceUserForm from "./finance-user-form";
import FinanceSupplierForm from "./finance-supplier-form";
import { Copy, Plus } from "@phosphor-icons/react";
import useDebounce from "../../../hooks/use-debounce";

const options = [
  { name: "Receita", value: "RECEIVE" },
  { name: "Despesa", value: "EXPENSE" },
  { name: "Consumo", value: "CONSUME" },
  { name: "Plano", value: "PLAN" },
];

function Form({ loading, setOpenUser, setOpenSupplier, user }) {
  const { setFieldValue, values } = useFormikContext();
  const [text, setText] = useState();
  const debounced = useDebounce(text);

  const { data, loading: userLoading } = useQuery(GET_USERS, {
    variables: {
      filter: { active: true, name: debounced, phone: debounced },
    },
    skip: values?.orderType?.value === "EXPENSE",
  });

  const { data: dataEmployee, loading: employeeLoading } = useQuery(
    GET_EMPLOYEES,
    {
      variables: {
        filter: { active: true },
      },
    }
  );

  const { data: dataService, loading: serviceLoading } = useQuery(
    GET_SERVICES,
    {
      variables: {
        filter: { employeeId: values?.employee?.id },
      },
      skip: !values?.employee?.id,
    }
  );

  const { data: dataProduct, loading: productLoading } = useQuery(
    GET_PRODUCTS,
    {
      variables: {
        filter: { active: true },
      },
    }
  );

  const { data: dataSupplier, loading: supplierLoading } = useQuery(
    GET_SUPPLIERS,
    {
      variables: {
        filter: { active: true },
      },
      skip: values?.orderType?.value !== "EXPENSE",
    }
  );

  const formLoading = useMemo(
    () =>
      loading ||
      employeeLoading ||
      serviceLoading ||
      productLoading ||
      supplierLoading,
    [loading, employeeLoading, serviceLoading, productLoading, supplierLoading]
  );

  return (
    <div className="flex flex-col items-center justify-center w-full gap-4 pt-2">
      <div className="flex w-full">
        <FormFields
          loading={formLoading}
          fields={[
            {
              type: InputTypes.TOGGLE,
              itemValue: "id",
              itemLabel: "name",
              name: "type",
              width: "w-full",
              onChange: () => {
                const services = values.services.map((res) => ({
                  price: res?.service?.price,
                  quantity: res.quantity,
                }));

                const products = values.products.map((res) => ({
                  price: res?.product?.price,
                  quantity: res.quantity,
                }));

                const totalServices = services.reduce((acc, currentValue) => {
                  const calc =
                    currentValue.price && currentValue.quantity
                      ? currentValue.price * currentValue.quantity
                      : 0;
                  return acc + calc;
                }, 0);

                const totalProducts = products.reduce((acc, currentValue) => {
                  const calc =
                    currentValue.price && currentValue.quantity
                      ? currentValue.price * currentValue.quantity
                      : 0;
                  return acc + calc;
                }, 0);

                setFieldValue("totalProduct", totalProducts);
                setFieldValue("totalService", totalServices);
                setFieldValue("price", totalServices + totalProducts);
              },
              options: [
                { id: 1, label: "Comanda", value: "ORDER" },
                { id: 2, label: "Serviços", value: "SERVICES" },
                { id: 3, label: "Produtos", value: "PRODUCTS" },
              ]?.filter((res) => {
                if (values?.orderType?.value === "EXPENSE") {
                  return res.id === 1;
                }
                if (values?.orderType?.value === "PLAN") {
                  return res.id !== 3;
                }
                return res;
              }),
            },
          ]}
        />
      </div>
      <div className="flex flex-col w-full h-full min-h-[326px] gap-4 pt-2 overflow-auto">
        <FormFields
          loading={formLoading}
          width="grid grid-cols-[3fr,1.5fr,0.5fr] gap-4 w-full border-b pb-4 justify-items-end"
          fields={[
            {
              type: InputTypes.DATE,
              label: "Data",
              name: "date",
              width: "w-full",
              visible: values.type !== "ORDER",
            },
            {
              type: InputTypes.AUTOCOMPLETE,
              label: "Comanda",
              name: "orderType",
              width: "w-full",
              options: options?.filter((res) =>
                user?.manager
                  ? res
                  : res?.value !== "EXPENSE" && res.value !== "PLAN"
              ),
              visible: values.type !== "ORDER",
              onChange: () => {
                setFieldValue("supplier", {});
                setFieldValue("user", {});
                setFieldValue("services", []);
                setFieldValue("products", []);
                setFieldValue("price", "");
                setFieldValue("discount", "");
              },
            },
            {
              type: InputTypes.AUTOCOMPLETE,
              label: "Fornecedor",
              name: "supplier",
              width: "w-full",
              options: dataSupplier?.suppliers || [],
              visible:
                values?.orderType?.value !== "EXPENSE" ||
                values.type !== "ORDER",
              onButton: true,
              onButtonAction: () => {
                setOpenSupplier(true);
                setFieldValue("orderType", {});
                setFieldValue("employee", {});
              },
            },
            {
              type: InputTypes.AUTOCOMPLETE,
              label: "Cliente",
              name: "user",
              width: "w-full",
              options:
                data?.userClients?.map((res) => {
                  return {
                    ...res,
                    name: `${res.name} - ${res?.phone}`,
                  };
                }) || [],
              visible:
                values?.orderType?.value === "EXPENSE" ||
                values.type !== "ORDER",
              onButton: true,
              onButtonAction: () => {
                setOpenUser(true);
              },
              onChangeInput: (e) => {
                setText(e);
              },
              loading: userLoading,
              onInputChange: (event, newInputValue, reason) => {
                if (reason === "clear") {
                  setText();
                }
              },
            },
            {
              type: InputTypes.AUTOCOMPLETE,
              label: "Profissional",
              name: "employee",
              width: "w-full",
              options: dataEmployee?.employees || [],
              visible: values.type !== "ORDER",
              onChange: () => {
                setFieldValue("services", []);
                setFieldValue("products", []);
              },
            },
            {
              type: InputTypes.TEXT,
              label: "Valor",
              name: "price",
              width: "w-full",
              mask: CurrencyMask,
              disabled: values.orderType?.value !== "EXPENSE",
              visible: values.type !== "ORDER",
            },
            {
              type: InputTypes.TEXT,
              label: "Desconto",
              name: "discount",
              width: "w-full",
              mask: CurrencyMask,
              visible:
                values.type !== "ORDER" || values.orderType?.value !== "PLAN",
            },
            {
              type: InputTypes.ARRAY,
              name: "services",
              label: "Serviços",
              addMore: <Plus size={20} weight="bold" />,
              duplicateLabel: <Copy size={20} weight="bold" />,
              alwaysShowDelete: true,
              width: "w-full",
              visible: values.type !== "SERVICES",
              children: [
                {
                  type: InputTypes.AUTOCOMPLETE,
                  label: "Serviços",
                  name: "service",
                  width: "w-full",
                  options: dataService?.services || [],
                  onChange: (e, index) => {
                    setFieldValue(`services[${index}].quantity`, 1);
                  },
                },
                {
                  type: InputTypes.NUMBER,
                  name: "quantity",
                  label: "Quantidade",
                  width: "w-full",
                },
              ],
            },
            {
              type: InputTypes.ARRAY,
              name: "products",
              label: "Produtos",
              addMore: <Plus size={20} weight="bold" />,
              duplicateLabel: <Copy size={20} weight="bold" />,
              alwaysShowDelete: true,
              width: "w-full",
              visible: values.type !== "PRODUCTS",
              children: [
                {
                  type: InputTypes.AUTOCOMPLETE,
                  label: "Produto",
                  name: "product",
                  width: "w-full",
                  options: dataProduct?.products || [],
                  onChange: (e, index) => {
                    setFieldValue(`products[${index}].quantity`, 1);
                  },
                },
                {
                  type: InputTypes.NUMBER,
                  name: "quantity",
                  label: "Quantidade",
                  width: "w-full",
                },
              ],
            },
          ]}
        />
      </div>
      <div className="flex flex-col w-full gap-4">
        {values.type === "ORDER" && (
          <Button
            label="Salvar"
            className="w-full p-3 text-white rounded-md bg-primary hover:bg-tertiary"
            type="submit"
            icon={<Spinner />}
            loading={formLoading}
            disabled={values.type !== "ORDER" || formLoading}
          />
        )}
      </div>
    </div>
  );
}

export default function FinanceForm({ open, onClose, title, subTitle }) {
  const toaster = useToaster();
  const { user } = useAuth();
  const { setException } = useException();
  const [openUser, setOpenUser] = useState();
  const [openSupplier, setOpenSupplier] = useState();

  const [createOrder, { loading: createLoading }] = useMutation(CREATE_ORDER);

  function getToralDuration(listServices) {
    return listServices?.reduce(
      (acc, currentValue) =>
        acc + currentValue?.service?.duration * currentValue.quantity,
      0
    );
  }

  async function onSubmit(values) {
    const products = values?.products?.filter(
      (res) => !!res.product && !!res.quantity
    );

    const services = values?.services?.filter(
      (res) => !!res.service && !!res.quantity
    );

    try {
      await createOrder({
        variables: {
          order: {
            date: moment(values.date).format("YYYY-MM-DD HH:mm:ss"),
            type: values?.orderType?.value,
            price: values?.price || 0,
            discount: values?.discount || 0,
            services: services?.map((res) => ({
              id: res?.service?.id,
              name: res?.service?.name,
              price: res?.service?.price,
              quantity: res.quantity,
              duration: res?.service?.duration,
            })),
            products: products?.map((res) => ({
              id: res?.product?.id,
              name: res?.product?.name,
              price: res?.product?.price,
              quantity: res.quantity,
            })),
            employeeId: values?.employee?.id,
            totalProduct: values?.totalProduct,
            totalService: values.totalService,
            totalDuration: getToralDuration(values?.services),
            userId: values?.user?.id,
            companyId: user?.companyIdentifier,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [getOperationName(GET_ORDERS_TOTALS)],
      });
      toaster.push(
        MessageAlert("Comanda criado com sucesso.", TYPE_MSG.SUCCESS)
      );
      onClose();
    } catch (error) {
      setException(error);
    }
  }

  const loading = useMemo(() => createLoading, [createLoading]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
      subTitle={subTitle}
      img="/./ios/192.png"
    >
      <div className="flex flex-col items-center h-full gap-4 p-4">
        <AForm
          initialValues={{
            date: moment(),
            services: [{}],
            products: [{}],
            type: "ORDER",
          }}
          onSubmit={onSubmit}
          formComponent={Form}
          formProps={{ loading, setOpenUser, setOpenSupplier, user }}
          validationSchema={Yup.object().shape({
            orderType: Yup.object().required("Campo obrigatório"),
            user: Yup.object().test(
              "user",
              "Campo obrigatório",
              function (val) {
                if (this?.parent?.orderType?.value === "RECEIVE" && val?.id) {
                  return true;
                }

                if (this?.parent?.orderType?.value !== "RECEIVE") {
                  return true;
                }

                return false;
              }
            ),
            supplier: Yup.object().test(
              "supplier",
              "Campo obrigatório",
              function (val) {
                if (this?.parent?.orderType?.value === "EXPENSE" && val) {
                  return true;
                }

                if (this?.parent?.orderType?.value !== "EXPENSE") {
                  return true;
                }

                return false;
              }
            ),
            employee: Yup.object().required("Campo obrigatório"),
            price: Yup.number().test(
              "price",
              "Campo obrigatório",
              function (val) {
                if (this?.parent?.orderType?.value !== "EXPENSE" && val > 0) {
                  return true;
                }

                if (this?.parent?.orderType?.value === "EXPENSE" && val > 0) {
                  return true;
                }

                setException("Erro", "Preencha todos campos em vermelho!");
                return false;
              }
            ),
            services: Yup.array().of(
              Yup.object().shape({
                service: Yup.object().test(
                  "service",
                  "Campo obrigatório",
                  function () {
                    const some = this?.from?.some(
                      (res) => res?.value?.price > 0
                    );

                    return some;
                  }
                ),
                quantity: Yup.number().test(
                  "service",
                  "Campo obrigatório",
                  function () {
                    const some = this?.from?.some(
                      (res) => res?.value?.price > 0
                    );

                    return some;
                  }
                ),
              })
            ),
            products: Yup.array().of(
              Yup.object().shape({
                product: Yup.object().test(
                  "product",
                  "Campo obrigatório",
                  function () {
                    const some = this?.from?.some(
                      (res) => res?.value?.price > 0
                    );

                    return some;
                  }
                ),
                quantity: Yup.number().test(
                  "product",
                  "Campo obrigatório",
                  function () {
                    const some = this?.from?.some(
                      (res) => res?.value?.price > 0
                    );

                    return some;
                  }
                ),
              })
            ),
          })}
        />
      </div>
      {openUser && (
        <FinanceUserForm
          open={openUser}
          onClose={() => {
            setOpenUser(false);
          }}
        />
      )}
      {openSupplier && (
        <FinanceSupplierForm
          open={openSupplier}
          onClose={() => {
            setOpenSupplier(false);
          }}
        />
      )}
    </Modal>
  );
}
