import { gql } from "@apollo/client";

export const GET_PRODUCT = gql`
  query product($id: Int) {
    product(id: $id) {
      id
      name
      price
      quantity
      active
      comission
      createdAt
      updatedAt
      productFixed
      productPercent
    }
  }
`;

export const GET_SUPPLIER = gql`
  query supplier($id: Int) {
    supplier(id: $id) {
      id
      name
      active
      phone
      createdAt
      updatedAt
    }
  }
`;

export const GET_SUPPLIERS = gql`
  query suppliers($filter: SupplierFilter) {
    suppliers(filter: $filter) {
      id
      name
      active
      phone
      createdAt
      updatedAt
    }
  }
`;

export const GET_PRODUCTS = gql`
  query products($filter: ProductFilter) {
    products(filter: $filter) {
      id
      name
      price
      quantity
      active
      comission
      createdAt
      updatedAt
      productFixed
      productPercent
    }
  }
`;

export const GET_POINTS = gql`
  query points($filter: PointFilter) {
    points(filter: $filter) {
      id
      value
      expirationDate
      service {
        id
        name
        duration
        price
      }
      userClient {
        id
        name
      }
      company {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_POINTS_GROUP = gql`
  query pointsGroup($filter: PointFilter) {
    pointsGroup(filter: $filter) {
      userId
      name
      totalValue
      companyId
      companyName
    }
  }
`;

export const CREATE_SUPPLIER_MUTATION = gql`
  mutation createSupplier($supplier: SupplierInput) {
    createSupplier(supplier: $supplier) {
      id
    }
  }
`;

export const UPDATE_SUPPLIER_MUTATION = gql`
  mutation updateSupplier($supplier: SupplierInput) {
    updateSupplier(supplier: $supplier) {
      id
    }
  }
`;

export const CREATE_PRODUCT_MUTATION = gql`
  mutation createProduct($product: ProductInput) {
    createProduct(product: $product) {
      id
    }
  }
`;

export const UPDATE_PRODUCT_MUTATION = gql`
  mutation updateProduct($product: ProductInput) {
    updateProduct(product: $product) {
      id
    }
  }
`;
