import React from "react";
import {
  Alarm,
  Calendar,
  DeviceMobileCamera,
  Notepad,
  PresentationChart,
  Watch,
} from "@phosphor-icons/react";

const advantages = [
  {
    icon: <Alarm size={60} />,
    label: "Reduza atrasos",
    description:
      "As notificações via whatsApp reduzirão os atrasos em até 90%.",
  },
  {
    icon: <DeviceMobileCamera size={60} />,
    label: "Sempre Disponível",
    description:
      "Não importa o dia ou horário, seus clientes poderão agendar horário através do seu site.",
  },
  {
    icon: <Watch size={60} />,
    label: "Ganhe Tempo",
    description:
      "Chega de ficar respondendo WhatsApp! Com o Ageendae seus clientes agendam horários sozinhos.",
  },
  {
    icon: <PresentationChart size={60} />,
    label: "Relatórios",
    description:
      "Saiba as estatísticas do seu negócio, como qual o serviço mais agendado e produtos mais vendidos.",
  },
  {
    icon: <Calendar size={60} />,
    label: "Multi Agenda",
    description:
      "Cada profissional conta com uma agenda independente e o cliente poderá escolher na hora de agendar!",
  },
  {
    icon: <Notepad size={60} />,
    label: "Lista de Espera",
    description:
      "Agora não precisa mais ficar enviando whatsApp para seus clientes avisando que vagou horários!",
  },
];

export default function Advantage() {
  return (
    <div className="flex flex-col items-center justify-center w-full gap-10 h-fit">
      <div className="flex flex-col items-center gap-4">
        <strong className="text-lg text-primary">
          Aprimore a experiência de reserva.
        </strong>
        <strong className="text-4xl sm:text-3xl">
          Progresso de sua empresa
        </strong>
      </div>
      <div className="grid h-full grid-cols-3 gap-4 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 max-w-7xl">
        {advantages?.map((res) => (
          <div className="flex flex-col items-center justify-center gap-4 p-10 bg-white rounded-lg shadow-lg">
            <span>{res?.icon}</span>
            <strong className="text-xl">{res?.label}</strong>
            <span className="text-center">{res?.description}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
