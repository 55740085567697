import React from "react";
import AutoCompleteField from "./autocomplete.field";
import AutoCompleteMultipleField from "./autocompleteMultiple.field";
import CheckboxField from "./checkbox.field";
import DateField from "./date.field";
import DatetimeField from "./dateTime.field";
import InputField from "./input.field";
import NumberField from "./number.field";
import TextAreaField from "./textArea.field";
import TimerField from "./timer.field";
import TransferListField from "./tranferlist.field";
import UploadField from "./upload.field";
import ToggleField from "./toggle.field";
import CodeField from "./code.field";

export const InputTypes = {
  TEXT: "TEXT",
  NUMBER: "NUMBER",
  AUTOCOMPLETE: "AUTOCOMPLETE",
  CHECKBOX: "CHECKBOX",
  PASSWORD: "PASSWORD",
  UPLOAD: "UPLOAD",
  ARRAY: "ARRAY",
  AUTOCOMPLETE_MULTIPLE: "AUTOCOMPLETE_MULTIPLE",
  TRANSFERLIST: "TRANSFERLIST",
  CUSTOM_FIELD: "CUSTOM_FIELD",
  TEXTAREA: "TEXTAREA",
  DATE: "DATE",
  DATETIME: "DATETIME",
  TIMER: "TIMER",
  TOGGLE: "TOGGLE",
  CODE: "CODE",
};

function Input({ field = {}, field: { type }, loading }) {
  switch (type) {
    case InputTypes.TEXT:
      return <InputField attribute={field} loading={loading} />;
    case InputTypes.TIMER:
      return <TimerField attribute={field} loading={loading} />;
    case InputTypes.DATE:
      return <DateField attribute={field} loading={loading} />;
    case InputTypes.DATETIME:
      return <DatetimeField attribute={field} loading={loading} />;
    case InputTypes.TEXTAREA:
      return <TextAreaField attribute={field} loading={loading} />;
    case InputTypes.TRANSFERLIST:
      return <TransferListField attribute={field} />;
    case InputTypes.UPLOAD:
      return <UploadField attribute={field} loading={loading} />;
    case InputTypes.NUMBER:
      return <NumberField attribute={field} loading={loading} />;
    case InputTypes.AUTOCOMPLETE:
      return <AutoCompleteField attribute={field} loading={loading} />;
    case InputTypes.AUTOCOMPLETE_MULTIPLE:
      return <AutoCompleteMultipleField attribute={field} loading={loading} />;
    case InputTypes.TOGGLE:
      return <ToggleField attribute={field} loading={loading} />;
    case InputTypes.CHECKBOX:
      return <CheckboxField attribute={field} loading={loading} />;
    case InputTypes.CODE:
      return <CodeField attribute={field} loading={loading} />;
    case InputTypes.CUSTOM_FIELD:
      return React.createElement(field?.customField, {
        attribute: field,
        loading,
      });
    default:
      return <InputField attribute={field} loading={loading} />;
  }
}

export default Input;
