import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";

export default function Animation({
  animation,
  height,
  width,
  loop,
  onAction,
}) {
  return (
    <Player
      style={{
        height,
        width,
        cursor: "default",
      }}
      autoplay
      loop={loop}
      src={animation}
      onEvent={(event) => onAction && onAction(event)}
    />
  );
}
