import { Link } from "react-router-dom";
import { useAuth } from "../../hooks/use-auth";

export default function LinkButton({
  path,
  active,
  icon,
  className,
  routePrivate,
  onAction,
  name,
}) {
  const { user } = useAuth();

  if (routePrivate && !user?.id) {
    return (
      <div className="flex flex-col items-center justify-center">
        <button onClick={onAction} className={className}>
          <div
            className={`flex flex-col items-center justify-center ${
              active === path ? "text-primary" : "text-slate-500"
            }`}
          >
            {icon}
          </div>
        </button>
        <strong className="text-center text-white xs:hidden">{name}</strong>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center">
      <Link to={path} className={className}>
        <div
          className={`flex flex-col items-center justify-center ${
            active === path ? "text-primary" : "text-slate-500"
          }`}
        >
          {icon}
        </div>
      </Link>
      <strong className="text-center text-white xs:hidden">{name}</strong>
    </div>
  );
}
