import React from "react";

function getStatus(data) {
  return data?.active ? "border-green-600" : "border-red-600";
}

export default function CardEmployee({ onAction, data }) {
  return (
    <div
      onClick={onAction}
      className={`flex flex-col border-l-4 ${getStatus(
        data
      )} bg-white w-full rounded-md p-4 gap-4 cursor-pointer`}
    >
      <div className="flex items-center w-full gap-2 pb-4 border-b">
        <span className="text-slate-400">{data?.employeeType?.name}</span>
      </div>
      <div className="flex items-center justify-between w-full gap-4">
        <div className="w-14">
          <img
            src="/./ios/180.png"
            alt=""
            width={50}
            className="object-contain"
          />
        </div>
        <div className="flex flex-col w-full">
          <strong className="text-primary">{data?.name}</strong>
          <div className="flex items-center gap-2">
            <span className="text-slate-400">{data?.city?.name}</span>
            <span className="text-slate-400">-</span>
            <span className="text-slate-400">{data?.state?.uf}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
