import { useState } from "react";
import { useAuth } from "../../hooks/use-auth";
import Employees from "./views/employees";
import Appointments from "./views/appointments";

export default function Home() {
  const { user } = useAuth();
  const [buttonSideSelected, setButtonSideSelected] = useState("EMPLOYEES");

  const buttonSide = ({ options }) => {
    return options.map((res, i) => (
      <button
        key={i}
        className={`flex items-center justify-center w-full p-2 rounded-md border-2 ${
          buttonSideSelected === res.value
            ? "bg-primary text-white"
            : "bg-white  text-primary"
        }`}
        onClick={res.onAction}
      >
        {res.label}
      </button>
    ));
  };

  return (
    <>
      {user?.manager && (
        <div className="flex flex-col w-full h-full gap-4 p-4 overflow-hidden">
          <strong className="flex justify-between gap-4 p-4 text-center text-white bg-white rounded-md">
            {buttonSide({
              options: [
                {
                  label: "Atendentes",
                  onAction: () => {
                    setButtonSideSelected("EMPLOYEES");
                  },
                  value: "EMPLOYEES",
                },
                {
                  label: "Agendamentos",
                  onAction: () => {
                    setButtonSideSelected("APPOINTMENTS");
                  },
                  value: "APPOINTMENTS",
                },
              ],
            })}
          </strong>
        </div>
      )}
      {buttonSideSelected === "EMPLOYEES" && (
        <Employees buttonSideSelected={buttonSideSelected} />
      )}
      {buttonSideSelected === "APPOINTMENTS" && (
        <Appointments buttonSideSelected={buttonSideSelected} />
      )}
    </>
  );
}
