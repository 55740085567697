import React, { useMemo } from "react";
import CardPermission from "./card-permission";
import { InputTypes } from "../../../components/Input";
import { advanceScheduling } from "../../../hooks/use-permission";
import { useCompany } from "../../../hooks/use-company";
import { useMutation } from "@apollo/client";
import { UPDATE_PERMISSION_MUTATION } from "../queries";
import useException from "../../../hooks/use-exception";
import { useToaster } from "rsuite";
import MessageAlert, { TYPE_MSG } from "../../../components/Message/message";
import { useAuth } from "../../../hooks/use-auth";

function getValues(res) {
  switch (res.type) {
    case "CHECKBOX":
      return res.value === "false" ? false : true;
    case "AUTOCOMPLETE":
      return advanceScheduling?.find((doc) => doc.value === res.value);
    default:
      break;
  }
}

export default function Config() {
  const { loadUser, loading } = useAuth();
  const { company } = useCompany();
  const toaster = useToaster();
  const { setException } = useException();

  const [updatePermission, { loading: updateLoading }] = useMutation(
    UPDATE_PERMISSION_MUTATION
  );

  const formLoading = useMemo(
    () => loading || updateLoading,
    [loading, updateLoading]
  );

  return (
    <div className="flex flex-col items-center w-full h-full gap-4 p-4 overflow-auto bg-white rounded-md">
      {company?.permissions?.map((res) => (
        <CardPermission
          loading={formLoading}
          key={res?.id}
          name={res?.name}
          value={getValues(res)}
          title={res?.title}
          description={res?.description}
          fields={[
            {
              type: InputTypes[res?.type],
              label: res?.label,
              name: res?.name,
              width: "w-full",
              options: res.type === "AUTOCOMPLETE" && advanceScheduling,
              onChange: async (val) => {
                try {
                  let value;
                  if (typeof val === "boolean") {
                    value = val.toString();
                  }

                  if (typeof val === "object") {
                    value = val.value;
                  }

                  await updatePermission({
                    variables: {
                      permission: {
                        id: res?.id,
                        value,
                      },
                    },
                  });

                  toaster.push(
                    MessageAlert(
                      "Permissão atualizada com sucesso.",
                      TYPE_MSG.SUCCESS
                    )
                  );
                  loadUser();
                } catch (error) {
                  setException(error);
                }
              },
            },
          ]}
        />
      ))}
    </div>
  );
}
