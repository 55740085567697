import { useEffect, useMemo, useState } from "react";
import { useField, useFormikContext } from "formik";

import {
  Divider,
  Grid,
  Card,
  List,
  CardHeader,
  Checkbox,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ButtonIcon from "../Button/IconButton";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

function TransferListField({
  attribute: { name, disabled, list = [], index, leftLabel, rightLabel },
  loading,
}) {
  const [field] = useField(name);
  const { values, setFieldValue } = useFormikContext();

  const disabledWrapper = () => {
    if (loading) {
      return true;
    }

    return typeof disabled === "function" ? disabled(values, index) : disabled;
  };

  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [labels, setLabels] = useState({});

  const right = useMemo(() => {
    if (!field.value) {
      return [];
    }
    return field.value;
  }, [field.value]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  useEffect(() => {
    setLabels(
      list.reduce((res, val) => {
        res[val.id] = val.label;
        return res;
      }, {})
    );
    setLeft(
      not(
        list.map(({ id }) => id),
        right
      )
    );
  }, [list, right]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setFieldValue(name, right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setFieldValue(name, not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title, items, side) => (
    <Card variant="outlined">
      <CardHeader
        className={`
          ${side === "left" ? "" : "flex-row-reverse"}
          flex w-full `}
        avatar={
          <div
            className={`${side !== "left" ? "flex w-full items-center" : ""}`}
          >
            <Checkbox
              onClick={handleToggleAll(items)}
              checked={
                numberOfChecked(items) === items.length && items.length !== 0
              }
              indeterminate={
                numberOfChecked(items) !== items.length &&
                numberOfChecked(items) !== 0
              }
              disabled={items.length === 0}
              inputProps={{ "aria-label": "all items selected" }}
            />
            {`${numberOfChecked(items)}/${items.length} selecionado `}
          </div>
        }
        action={
          <ButtonIcon
            color={`${side === "left" ? "bg-[#66BBBB]" : "bg-[#747484]"}`}
            name={side === "left" ? "right" : "left"}
            disabled={
              side === "left"
                ? disabledWrapper() || leftChecked.length === 0
                : disabledWrapper() || rightChecked.length === 0
            }
            onClick={side === "left" ? handleCheckedRight : handleCheckedLeft}
          />
        }
        title={title}
      />
      <Divider />
      <List
        className="flex flex-col w-full h-[calc(100vh-340px)] overflow-auto"
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText id={value} primary={labels[value]} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <>
      {!loading && (
        <>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            className="flex w-full flex-nowrap m-auto gap-1 "
          >
            <Grid className="flex-1" item>
              {customList(leftLabel, left, "left")}
            </Grid>
            <Grid className="flex-1" item>
              {customList(rightLabel, right)}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

export default TransferListField;
