import { MapPin } from "@phosphor-icons/react";
import React from "react";

export default function CardCompany({ name, description, cidade, onChange }) {
  return (
    <div
      className="flex flex-col flex-wrap justify-between gap-2 p-4 border rounded-md cursor-pointer h-fit"
      onClick={onChange}
    >
      <strong>{name}</strong>
      <span>{description}</span>
      <div className="flex flex-wrap items-center gap-2">
        <span className="text-xl text-orange-600">
          <MapPin />
        </span>
        <strong>{cidade}</strong>
      </div>
    </div>
  );
}
