import React from "react";
import * as Yup from "yup";
import AForm from "../../../components/Form";
import Button from "../../../components/Button";
import Spinner from "../../../components/Spinner";
import { InputTypes } from "../../../components/Input";
import FormFields from "../../../components/Form/form-fields";
import useException from "../../../hooks/use-exception";
import { useToaster } from "rsuite";
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD_MUTATION } from "../queries";
import MessageAlert, { TYPE_MSG } from "../../../components/Message/message";

function Form({ loading }) {
  return (
    <div className="flex flex-col items-center justify-center w-full gap-4 py-4">
      <FormFields
        loading={loading}
        fields={[
          {
            type: InputTypes.PASSWORD,
            label: "Senha",
            name: "one",
            width: "w-full",
          },
          {
            type: InputTypes.PASSWORD,
            label: "Digite novamente",
            name: "two",
            width: "w-full",
          },
        ]}
      />
      <div className="flex flex-col w-full gap-4">
        <Button
          label="Resetar"
          className="w-full p-3 text-white rounded-md bg-primary hover:bg-tertiary"
          type="submit"
          icon={<Spinner />}
          loading={loading}
        />
      </div>
    </div>
  );
}

export default function ResetPasswordForm({ onClose, phone }) {
  const { setException } = useException();
  const toaster = useToaster();

  const [resetPassword, { loading: resetLoading }] = useMutation(
    RESET_PASSWORD_MUTATION
  );

  async function onSubmit(values) {
    try {
      await resetPassword({
        variables: {
          phone,
          password: values.one,
        },
      });
      toaster.push(
        MessageAlert("Senha alterada com sucesso.", TYPE_MSG.SUCCESS)
      );
      onClose();
    } catch (error) {
      setException(error);
    }
  }

  return (
    <AForm
      initialValues={{ one: "", two: "" }}
      onSubmit={onSubmit}
      formComponent={Form}
      formProps={{ loading: resetLoading }}
      validationSchema={Yup.object({
        one: Yup.string().required("Campo obrigatório"),
        two: Yup.string().required("Campo obrigatório"),
      })}
    />
  );
}
