import { Tooltip } from "@mui/material";
import * as Yup from "yup";
import { Buildings, Key } from "@phosphor-icons/react";
import React, { useMemo } from "react";
import { UilSignout } from "@iconscout/react-unicons";
import AForm from "../../../components/Form";
import { InputTypes } from "../../../components/Input";
import Button from "../../../components/Button";
import APhoneMask from "../../../components/Mask/a-phone-number.mask";
import FormFields from "../../../components/Form/form-fields";
import { useMutation, useQuery } from "@apollo/client";
import { GET_CITIES, GET_STATES, UPDATE_PROFILE_MUTATION } from "../queries";
import { useFormikContext } from "formik";
import { useAuth } from "../../../hooks/use-auth";
import { useCompany } from "../../../hooks/use-company";
import { useNavigate } from "react-router-dom";
import { useToaster } from "rsuite";
import useException from "../../../hooks/use-exception";
import MessageAlert, { TYPE_MSG } from "../../../components/Message/message";
import moment from "moment";

const notificationOptions = [
  { id: 1, name: "1 Hora antes", value: 60 },
  { id: 2, name: "2 Horas antes", value: 120 },
  { id: 3, name: "6 Horas antes", value: 360 },
  { id: 4, name: "12 Horas antes", value: 720 },
  { id: 5, name: "24 Horas antes", value: 1440 },
];

function Form({ formLoading }) {
  const { values, initialValues } = useFormikContext();

  const { data: dataStates, loading: loadingStates } = useQuery(GET_STATES, {
    variables: {
      filter: { active: true },
    },
  });

  const { data: dataCities, loading: loadingCities } = useQuery(GET_CITIES, {
    variables: {
      filter: { stateId: values?.state?.id },
    },
    skip: !values?.state?.id,
  });

  const loadingQuerys = useMemo(
    () => formLoading || loadingStates || loadingCities,
    [formLoading, loadingStates, loadingCities]
  );

  return (
    <div className="flex flex-col items-center justify-center w-full h-full gap-4 p-4">
      <div className="flex flex-col items-center justify-center w-full h-full gap-4">
        <FormFields
          loading={loadingQuerys}
          fields={[
            {
              type: InputTypes.TEXT,
              label: "Whatsapp",
              name: "phone",
              width: "w-full",
              variant: "standard",
              mask: APhoneMask,
              disabled: true,
            },
            {
              type: InputTypes.TEXT,
              label: "Nome",
              name: "name",
              width: "w-full",
              variant: "standard",
            },
            {
              type: InputTypes.DATE,
              name: "birthdate",
              label: "Data nascimento",
              width: "w-full",
              variant: "standard",
            },
            {
              type: InputTypes.AUTOCOMPLETE,
              name: "state",
              label: "Estado",
              options: dataStates?.states || [],
              width: "w-full",
              variant: "standard",
            },
            {
              type: InputTypes.AUTOCOMPLETE,
              name: "city",
              label: "Cidade",
              options: dataCities?.cities || [],
              width: "w-full",
              variant: "standard",
            },
            {
              type: InputTypes.AUTOCOMPLETE,
              name: "notificationTimer",
              label: "Recebimento notificação",
              options: notificationOptions,
              width: "w-full",
              variant: "standard",
            },
          ]}
        />
      </div>
      <div
        className={`${
          initialValues === values ? "hidden" : "flex"
        } flex-col justify-end w-full gap-4`}
      >
        <Button
          label="Alterar mudanças"
          className="w-full p-3 text-white rounded-md bg-primary hover:bg-tertiary"
          type="submit"
          disabled={formLoading}
        />
      </div>
    </div>
  );
}

export default function ProfileForm({ onPassword }) {
  const { user, loadUser, logout } = useAuth();
  const { company } = useCompany();
  const navigate = useNavigate();
  const toaster = useToaster();
  const { setException } = useException();

  const [update, { loading }] = useMutation(UPDATE_PROFILE_MUTATION);

  async function onSubmit(values) {
    try {
      await update({
        variables: {
          user: {
            id: user?.id,
            name: values?.name,
            cityId: values?.city?.id,
            stateId: values?.state?.id,
            notificationTimer: values?.notificationTimer?.value,
            birthdate: moment(values?.birthdate).startOf("day"),
          },
        },
      });
      toaster.push(
        MessageAlert("Perfil atualizado com sucesso.", TYPE_MSG.SUCCESS)
      );
      loadUser();
    } catch (error) {
      setException(error);
    }
  }

  const initialValues = useMemo(() => {
    if (user) {
      return {
        name: user?.name,
        phone: user?.phone,
        state: user?.address?.state,
        city: user?.address?.city,
        notificationTimer: notificationOptions.find(
          (res) => res.value === user?.notificationTimer
        ),
        birthdate: moment(user?.birthdate).startOf("day"),
      };
    }
    return {
      name: "",
      phone: "",
      notificationTimer: { id: 4, name: "12 Horas antes", value: 720 },
    };
  }, [user]);

  const formLoading = useMemo(() => loading, [loading]);

  return (
    <div className="flex flex-col items-center w-full h-full gap-4 p-4 bg-white rounded-md">
      <div className="flex justify-between w-full p-4">
        <Tooltip title="Empresas" placement="top">
          <button
            onClick={() => {
              localStorage.removeItem("company");
              navigate("/company");
            }}
            className="text-primary"
          >
            <Buildings size={30} />
          </button>
        </Tooltip>
        <Tooltip title="Senha" placement="top">
          <button onClick={onPassword} className="text-primary">
            <Key size={30} />
          </button>
        </Tooltip>
        <Tooltip title="Sair" placement="top">
          <button
            onClick={() => {
              logout();
              navigate(`/${company?.schema}`);
            }}
            className="text-red-600"
          >
            <UilSignout size={30} />
          </button>
        </Tooltip>
      </div>
      <AForm
        onSubmit={onSubmit}
        initialValues={initialValues}
        formComponent={Form}
        validationSchema={Yup.object({
          name: Yup.string().required("Campo obrigatório"),
          phone: Yup.string().required("Campo obrigatório"),
          state: Yup.object().required("Campo obrigatório"),
          city: Yup.object().required("Campo obrigatório"),
          notificationTimer: Yup.object().required("Campo obrigatótio"),
          birthdate: Yup.date().required("Campo obrigatótio"),
        })}
        formProps={{ formLoading }}
      />
    </div>
  );
}
