import React, { useState } from "react";
import { useAuth } from "../../hooks/use-auth";
import ChangePasswordForm from "./views/change-password-form";
import ProfileForm from "./views/profile-form";
import Config from "./views/config";

export default function Profile() {
  const { user } = useAuth();
  const [open, setOpen] = useState();
  const [buttonSideSelected, setButtonSideSelected] = useState("PROFILE");

  const buttonSide = ({ options }) => {
    return options.map((res, i) => (
      <button
        key={i}
        className={`flex items-center justify-center w-full p-2 rounded-md border-2 ${
          buttonSideSelected === res.value
            ? "bg-primary text-white"
            : "bg-white  text-primary"
        }`}
        onClick={res.onAction}
      >
        {res.label}
      </button>
    ));
  };

  return (
    <div className="flex flex-col w-full h-[calc(100vh-54px)] gap-4 p-4 overflow-hidden">
      {(user?.manager || user?.master) &&
        user?.company?.id === user?.companyIdentifier && (
          <strong className="flex justify-between gap-4 p-4 text-center text-white bg-white rounded-md">
            {buttonSide({
              options: [
                {
                  label: "Perfil",
                  onAction: () => {
                    setButtonSideSelected("PROFILE");
                  },
                  value: "PROFILE",
                  manager: false,
                },
                {
                  label: "Configurações",
                  onAction: () => {
                    setButtonSideSelected("CONFIG");
                  },
                  value: "CONFIG",
                  manager: true,
                },
              ],
            })}
          </strong>
        )}
      {buttonSideSelected === "PROFILE" && (
        <ProfileForm onPassword={() => setOpen(true)} />
      )}

      {buttonSideSelected === "CONFIG" &&
        user?.manager &&
        user?.company?.id === user?.companyIdentifier && <Config />}

      {open && (
        <ChangePasswordForm open={open} onClose={() => setOpen(false)} />
      )}
    </div>
  );
}
