import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import FinanceCard from "./views/finance-card";
import { useMutation, useQuery } from "@apollo/client";
import {
  DELETE_ORDER,
  GET_APPOINTMENTS,
  GET_EMPLOYEE,
  GET_ORDERS,
  GET_ORDERS_TOTALS,
} from "./queries";
import { useAuth } from "../../hooks/use-auth";
import {
  ClipboardText,
  Eye,
  EyeSlash,
  Faders,
  Plus,
} from "@phosphor-icons/react";
import FinanceForm from "./views/finance-form";
import Animation from "../../Animation/Animation";
import Hand from "../../Animation/hand.json";
import useException from "../../hooks/use-exception";
import { getOperationName } from "@apollo/client/utilities";
import MessageAlert, { TYPE_MSG } from "../../components/Message/message";
import { useToaster } from "rsuite";
import AlertModal from "../../components/Modal/AlertModal";
import FinanceInvoicingCard from "./views/finance-invoicing";
import FinanceAppointmentsCard from "./views/finance-appointment-card";
import FinanceFilter from "./views/finance-filter";
import ProductList from "./views/product-list";
import ServiceList from "./views/service-list";

export default function Finance() {
  const { user } = useAuth();
  const toaster = useToaster();
  const { setException } = useException();
  const [openReport, setOpenReport] = useState();
  const [open, setOpen] = useState();
  const [openOrder, setOpenOrder] = useState();
  const [openAlert, setOpenAlert] = useState();
  const [id, setId] = useState();
  const [openFilter, setOpenFilter] = useState();
  const [openProductList, setOpenProductList] = useState();
  const [openServiceList, setOpenServiceList] = useState();
  const [nameFilter, setNameFilter] = useState();
  const [show, setShow] = useState(true);

  const [filter, setFilter] = useState({
    startDate: moment().startOf("month").startOf("day"),
    endDate: moment().endOf("month").endOf("day"),
    employeeId: user?.employeeIdentifier,
  });

  const { data: dataEmployee, loading: employeeLoading } = useQuery(
    GET_EMPLOYEE,
    {
      variables: {
        id: filter?.employeeId,
      },
      skip: !filter?.employeeId,
    }
  );

  useEffect(() => {
    if (!employeeLoading && dataEmployee) {
      setNameFilter(dataEmployee?.employee?.name);
    }
  }, [dataEmployee, employeeLoading]);

  const { data: dataOrderTotals, loading: orderTotalsLoading } = useQuery(
    GET_ORDERS_TOTALS,
    {
      variables: {
        filter,
      },
      skip: !filter,
    }
  );

  const [discartOrder, { loading: discartLoading }] = useMutation(DELETE_ORDER);

  async function onDiscart() {
    try {
      await discartOrder({
        variables: {
          id,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          getOperationName(GET_APPOINTMENTS),
          getOperationName(GET_ORDERS),
        ],
      });
      toaster.push(
        MessageAlert("Financeiro descartado com sucesso.", TYPE_MSG.SUCCESS)
      );
      setId();
      setOpenAlert(false);
      setOpenOrder(false);
    } catch (error) {
      setException(error);
    }
  }

  const formLoading = useMemo(
    () => discartLoading || orderTotalsLoading,
    [discartLoading, orderTotalsLoading]
  );

  return (
    <>
      <div className="flex flex-col w-full h-[calc(100vh-54px)] overflow-hidden">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col items-center gap-4 p-4 shadow bg-primary">
            <div className="flex items-center w-full">
              <strong className="w-full text-xl text-white">Financeiro</strong>
              <div className="flex gap-4">
                <button
                  onClick={() => {
                    setShow(!show);
                  }}
                  className="flex p-2 bg-white rounded-full"
                >
                  {!show ? (
                    <EyeSlash
                      size={20}
                      className="text-primary"
                      weight="bold"
                    />
                  ) : (
                    <Eye size={20} className="text-primary" weight="bold" />
                  )}
                </button>
                <button
                  onClick={() => setOpenFilter(true)}
                  className="flex p-2 bg-white rounded-full"
                >
                  <Faders size={20} className="text-primary" weight="bold" />
                </button>
                <button
                  onClick={() => setOpen(true)}
                  className="flex p-2 bg-white rounded-full"
                >
                  <Plus size={20} className="text-primary" weight="bold" />
                </button>
              </div>
            </div>
            <div className="flex items-center justify-between w-full gap-2 p-4 border border-white rounded-md text-primary bg-secondary">
              <div className="space-x-2">
                <strong>{moment(filter.startDate).format("DD-MM-YYYY")}</strong>
                <strong>-</strong>
                <strong>{moment(filter.endDate).format("DD-MM-YYYY")}</strong>
              </div>
              <div className="space-x-2">
                <strong>{nameFilter}</strong>
              </div>
            </div>
          </div>
        </div>
        {formLoading && (
          <div className="fixed flex items-center justify-center w-full h-full -translate-x-1/2 -translate-y-1/2 bg-opacity-50 top-1/2 left-1/2">
            <Animation width={150} height={150} animation={Hand} loop={true} />
          </div>
        )}
        {!formLoading && (
          <div className="flex flex-col w-full h-[calc(100vh-112px)] gap-4 px-4 overflow-auto py-4">
            {user?.manager && (
              <div className="grid w-full grid-cols-2 gap-4">
                <FinanceCard
                  value={dataOrderTotals?.orderTotals?.balance}
                  description="Saldo do período"
                  className={`flex flex-col w-full gap-4 p-4 text-green-600 bg-white rounded-md shadow ${
                    dataOrderTotals?.orderTotals?.balance < 0 && "text-red-600"
                  }`}
                  format
                  show={show}
                />
                <FinanceCard
                  value={dataOrderTotals?.orderTotals?.grossRevenue}
                  description="Faturamento bruto"
                  className="flex flex-col w-full gap-4 p-4 bg-white rounded-md shadow"
                  iconAction={<ClipboardText size={20} />}
                  action={() => {
                    setOpenOrder(true);
                  }}
                  format
                  show={show}
                />
              </div>
            )}
            <div className="grid w-full grid-cols-2 gap-4">
              <FinanceCard
                value={dataOrderTotals?.orderTotals?.totalComissions}
                description="Comissão do período"
                className={`flex flex-col w-full gap-4 p-4 bg-white rounded-md shadow ${
                  dataOrderTotals?.orderTotals?.totalComissions > 0 &&
                  "text-green-600"
                }`}
                format
                show={show}
              />
              <FinanceCard
                value={dataOrderTotals?.orderTotals?.consume}
                description="Consumo"
                className={`flex flex-col w-full gap-4 p-4  bg-white rounded-md shadow ${
                  dataOrderTotals?.orderTotals?.consume > 0 && "text-red-500"
                }`}
                format
                show={show}
              />
            </div>
            {user?.manager && (
              <div className="grid w-full grid-cols-2 gap-4">
                <FinanceCard
                  value={dataOrderTotals?.orderTotals?.receive}
                  description="Receitas"
                  className={`flex flex-col w-full gap-4 p-4  bg-white rounded-md shadow ${
                    dataOrderTotals?.orderTotals?.receive > 0 &&
                    "text-green-600"
                  }`}
                  format
                  show={show}
                />
                <FinanceCard
                  value={dataOrderTotals?.orderTotals?.expense}
                  description="Despesas"
                  className={`flex flex-col w-full gap-4 p-4  bg-white rounded-md shadow ${
                    dataOrderTotals?.orderTotals?.expense > 0 && "text-red-500"
                  }`}
                  format
                  show={show}
                />
              </div>
            )}
            {!!dataOrderTotals?.orderTotals?.totalAppointments?.totals &&
              user?.isSchedule && (
                <FinanceCard
                  label="Agendamentos"
                  value={
                    dataOrderTotals?.orderTotals?.totalAppointments?.totals
                  }
                  description=""
                  className="flex flex-col w-full gap-4 p-4 bg-white rounded-md shadow"
                  options={[
                    {
                      label: "Aguardando",
                      className:
                        "w-3 h-3 bg-yellow-500 rounded-full hover:bg-yellow-700 rounded-circle",
                      value:
                        dataOrderTotals?.orderTotals?.totalAppointments
                          ?.pending,
                    },
                    {
                      label: "Finalizados",
                      className:
                        "w-3 h-3 bg-green-500 rounded-full hover:bg-green-700 rounded-circle",
                      value:
                        dataOrderTotals?.orderTotals?.totalAppointments
                          ?.finished,
                    },
                    {
                      label: "Cancelados",
                      className:
                        "w-3 h-3 bg-red-500 rounded-full hover:bg-green-700 rounded-circle",
                      value:
                        dataOrderTotals?.orderTotals?.totalAppointments
                          ?.canceled,
                    },
                  ]}
                  iconAction={<ClipboardText size={20} />}
                  action={() => {
                    setOpenReport(true);
                  }}
                  show={show}
                />
              )}
            {user?.manager && (
              <div className="grid w-full grid-cols-2 gap-4">
                <FinanceCard
                  value={dataOrderTotals?.orderTotals?.totalProducts}
                  description="Total em produtos"
                  className="flex flex-col w-full gap-4 p-4 bg-white rounded-md shadow"
                  format
                  show={show}
                />
                <FinanceCard
                  value={dataOrderTotals?.orderTotals?.totalServices}
                  description="Total em serviços"
                  className="flex flex-col w-full gap-4 p-4 bg-white rounded-md shadow"
                  format
                  show={show}
                />
              </div>
            )}
            {!!dataOrderTotals?.orderTotals?.listProducts?.length && (
              <FinanceCard
                label="Produtos"
                value={dataOrderTotals?.orderTotals?.sumProducts || 0}
                description=""
                type="Vendido"
                ranking
                className="flex flex-col w-full gap-4 p-4 bg-white rounded-md shadow"
                options={dataOrderTotals?.orderTotals?.listProducts
                  ?.map((res, i) => ({
                    label: res.productName,
                    value: res.totalQuantity,
                    className: `w-3 h-3  ${
                      i === 0
                        ? "bg-amber-400"
                        : i === 1
                        ? "bg-stone-500"
                        : "bg-yellow-600"
                    } rounded-full rounded-circle`,
                  }))
                  ?.sort((a, b) => b.value - a.value)
                  ?.slice(0, 3)}
                iconAction={<ClipboardText size={20} />}
                action={() => {
                  setOpenProductList(true);
                }}
                show={show}
              />
            )}
            {!!dataOrderTotals?.orderTotals?.listServices?.length && (
              <FinanceCard
                label="Serviços"
                value={dataOrderTotals?.orderTotals?.sumServices || 0}
                description=""
                type="Vendido"
                ranking
                className="flex flex-col w-full gap-4 p-4 bg-white rounded-md shadow"
                options={dataOrderTotals?.orderTotals?.listServices
                  ?.map((res, i) => ({
                    label: res.serviceName,
                    value: res.totalQuantity,
                    className: `w-3 h-3  ${
                      i === 0
                        ? "bg-amber-400"
                        : i === 1
                        ? "bg-stone-500"
                        : "bg-yellow-600"
                    } rounded-full rounded-circle`,
                  }))
                  ?.sort((a, b) => b.value - a.value)
                  ?.slice(0, 3)}
                iconAction={<ClipboardText size={20} />}
                action={() => {
                  setOpenServiceList(true);
                }}
                show={show}
              />
            )}
            <div className="grid w-full h-auto grid-cols-1 gap-4">
              <FinanceCard
                value={dataOrderTotals?.orderTotals?.totalClients}
                description="Total de clientes"
                className="flex flex-col w-full gap-4 p-4 text-green-600 bg-white rounded-md shadow"
                show={show}
              />
            </div>
          </div>
        )}
      </div>
      {openReport && (
        <FinanceAppointmentsCard
          open={openReport}
          onClose={() => setOpenReport(false)}
          filter={filter}
        />
      )}
      {openOrder && (
        <FinanceInvoicingCard
          open={openOrder}
          onClose={() => setOpenOrder(false)}
          loading={formLoading}
          onDelete={(e) => {
            setId(e.id);
            setOpenAlert(true);
          }}
          filter={filter}
        />
      )}
      {openProductList && (
        <ProductList
          open={openProductList}
          onClose={() => setOpenProductList(false)}
          data={dataOrderTotals?.orderTotals?.listProducts?.map((res) => ({
            name: res.productName,
            quantity: res.totalQuantity,
          }))}
          loading={formLoading}
        />
      )}
      {openServiceList && (
        <ServiceList
          open={openServiceList}
          onClose={() => setOpenServiceList(false)}
          data={dataOrderTotals?.orderTotals?.listServices?.map((res) => ({
            name: res.serviceName,
            quantity: res.totalQuantity,
          }))}
          loading={formLoading}
        />
      )}
      {openAlert && (
        <AlertModal
          open={openAlert}
          cancelAction={() => {
            setOpenAlert(false);
            setId();
          }}
          title="Financeiro"
          message="Deseja deletar?"
          confirLabel="Sim"
          cancelLabel="Não"
          confirmAction={onDiscart}
        />
      )}
      {open && (
        <FinanceForm
          title="Financeiro"
          subTitle="Adicione receitas e despesas"
          open={open}
          onClose={() => setOpen(false)}
          employeeId={user?.employeeIdentifier}
        />
      )}
      {openFilter && (
        <FinanceFilter
          title="Filtro"
          subTitle="Lista de filtros"
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          filter={filter}
          manager={user?.manager}
          onFilter={(e) => {
            setFilter({
              startDate: moment(e?.startDate).startOf("day"),
              endDate: moment(e?.endDate).endOf("day"),
              employeeId: e?.employee?.id || user?.employeeIdentifier,
              manager: e?.manager,
            });
            setNameFilter(e?.employee?.name);
            setOpenFilter(false);
          }}
        />
      )}
    </>
  );
}
