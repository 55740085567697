import React, { useMemo } from "react";
import { useToaster } from "rsuite";
import * as Yup from "yup";
import {
  CREATE_EMPLOYEE_MUTATION,
  GET_CITIES,
  GET_EMPLOYEE,
  GET_EMPLOYEES,
  GET_EMPLOYEE_TYPES,
  GET_STATES,
  GET_USER_CLIENTS,
  UPDATE_EMPLOYEE_MUTATION,
} from "../../queries";
import { useFormikContext } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";
import FormFields from "../../../../components/Form/form-fields";
import { InputTypes } from "../../../../components/Input";
import APhoneMask from "../../../../components/Mask/a-phone-number.mask";
import Button from "../../../../components/Button";
import useException from "../../../../hooks/use-exception";
import MessageAlert, { TYPE_MSG } from "../../../../components/Message/message";
import Modal from "../../../../components/Modal";
import AForm from "../../../../components/Form";
import Spinner from "../../../../components/Spinner";
import { useAuth } from "../../../../hooks/use-auth";

function Form({ formLoading, isMaster }) {
  const { initialValues, values } = useFormikContext();

  const { data, loading } = useQuery(GET_EMPLOYEE_TYPES, {
    variables: {
      filter: { active: true },
    },
  });

  const { data: dataUser, loading: userLoading } = useQuery(GET_USER_CLIENTS, {
    variables: {
      filter: {
        active: true,
        companyIdentifier: 0,
        employeeIdentifier: 0,
      },
    },
  });

  const { data: dataStates, loading: loadingStates } = useQuery(GET_STATES, {
    variables: {
      filter: { active: true },
    },
  });

  const { data: dataCities, loading: loadingCities } = useQuery(GET_CITIES, {
    variables: {
      filter: { stateId: values?.state?.id },
    },
    skip: !values?.state?.id,
  });

  const loadingQuerys = useMemo(
    () =>
      formLoading || loadingStates || loadingCities || loading || userLoading,
    [formLoading, loadingStates, loadingCities, loading, userLoading]
  );

  return (
    <div className="flex flex-col w-full gap-4 py-4">
      <FormFields
        loading={loadingQuerys}
        fields={[
          {
            type: InputTypes.AUTOCOMPLETE,
            name: "userClient",
            label: "Usuário",
            itemValue: "id",
            itemLabel: "name",
            options: dataUser?.userClients || [],
            width: "w-full",
            disabled: initialValues?.userClient,
            // customRenderOption: (option, state) => (
            //   <button
            //     type="button"
            //     className="flex flex-col w-full px-4 py-2 hover:bg-slate-200"
            //     onClick={() => setFieldValue("userClient", state)}
            //   >
            //     <span>{option.key}</span>
            //   </button>
            // ),
          },
          {
            type: InputTypes.AUTOCOMPLETE,
            name: "employeeTypes",
            label: "Profissão",
            options: data?.employeeTypes || [],
            width: "w-full",
          },
          {
            type: InputTypes.TEXT,
            name: "name",
            label: "Nome",
          },
          {
            type: InputTypes.TEXT,
            name: "phone",
            label: "Whastapp",
            mask: APhoneMask,
          },
          {
            type: InputTypes.AUTOCOMPLETE,
            name: "state",
            label: "Estado",
            options: dataStates?.states || [],
            width: "w-full",
          },
          {
            type: InputTypes.AUTOCOMPLETE,
            name: "city",
            label: "Cidade",
            options: dataCities?.cities || [],
            width: "w-full",
          },
          {
            type: InputTypes.CHECKBOX,
            name: "activeAppointment",
            label: "Agenda",
            visible: !isMaster,
          },
          {
            type: InputTypes.CHECKBOX,
            name: "active",
            label: "Ativo",
            visible: !isMaster,
          },
        ]}
      />
      <div className="fixed flex flex-col gap-4 bottom-4 left-4 right-4">
        <Button
          label="Salvar"
          className="w-full p-3 text-white rounded-md bg-primary hover:bg-tertiary"
          type="submit"
          disabled={values === initialValues}
          loading={loadingQuerys}
          icon={<Spinner />}
        />
      </div>
    </div>
  );
}

export default function ModalEmployeeForm({ open, onClose, id }) {
  const toaster = useToaster();
  const { setException } = useException();
  const { user } = useAuth();

  const { data, loading } = useQuery(GET_EMPLOYEE, {
    variables: {
      id: Number(id) || null,
    },
    skip: !id,
  });

  const [create, { loading: createLoading }] = useMutation(
    CREATE_EMPLOYEE_MUTATION
  );

  const [update, { loading: updateLoading }] = useMutation(
    UPDATE_EMPLOYEE_MUTATION
  );

  const isMaster = useMemo(() => user?.master || user?.manager, [user]);

  async function onSubmit(values) {
    try {
      if (id) {
        await update({
          variables: {
            employee: {
              id: id,
              name: values.name,
              phone: values.phone,
              employeeTypeId: values.employeeType.id,
              active: values.active,
              stateId: values?.state?.id,
              cityId: values?.city?.id,
              activeAppointment: values?.activeAppointment,
              userId: values?.userClient?.id,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [getOperationName(GET_EMPLOYEES)],
        });
        toaster.push(MessageAlert("Atualizado com sucesso.", TYPE_MSG.SUCCESS));
      } else {
        await create({
          variables: {
            employee: {
              name: values.name,
              phone: values.phone,
              employeeTypeId: values.employeeTypes.id,
              active: values.active,
              stateId: values?.state?.id,
              cityId: values?.city?.id,
              activeAppointment: values?.activeAppointment,
              userId: values?.userClient?.id,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [getOperationName(GET_EMPLOYEES)],
        });
        toaster.push(MessageAlert("Cadastrado com sucesso.", TYPE_MSG.SUCCESS));
      }

      onClose();
    } catch (error) {
      setException(error);
    }
  }

  const formLoading = useMemo(
    () => loading || createLoading || updateLoading,
    [loading, createLoading, updateLoading]
  );

  const initialValues = useMemo(() => {
    if (!loading && data?.employee) {
      return {
        ...data?.employee,
        employeeTypes: data?.employee?.employeeType,
      };
    }
    return {
      name: "",
      phone: "",
      email: "",
      username: "",
      password: "",
      active: true,
      activeAppointment: false,
    };
  }, [data, loading]);

  return (
    <Modal
      title="Colaborador"
      subTitle="Dados do colaborador"
      open={open}
      onClose={onClose}
      img="/./ios/180.png"
    >
      <div className="flex h-full overflow-auto mb-[60px]">
        <AForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          formComponent={Form}
          formProps={{ formLoading, id, isMaster }}
          validationSchema={Yup.object({
            name: Yup.string().required("Campo obrigatório"),
            phone: Yup.string().required("Campo obrigatório"),
            employeeTypes: Yup.object().required("Campo obrigatório"),
          })}
        />
      </div>
    </Modal>
  );
}
