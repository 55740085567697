import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import AppointmentTimelineEmpty from "./appointment-timeline-empty";
import {
  DISCART_APPOINTMENT_MUTATION,
  GET_APPOINTMENTS,
  GET_APPOINTMENT_RECURRENCE,
} from "../queries";
import { useMutation, useQuery } from "@apollo/client";
import AlertModal from "../../../components/Modal/AlertModal";
import Spinner from "../../../components/Spinner";
import { getOperationName } from "@apollo/client/utilities";
import { useToaster } from "rsuite";
import MessageAlert, { TYPE_MSG } from "../../../components/Message/message";
import useException from "../../../hooks/use-exception";
import CardAppointment from "./card-appointment";
import { usePermission } from "../../../hooks/use-permission";
import { useAuth } from "../../../hooks/use-auth";

function compareDates(a, b) {
  const dateA = moment(a.date);
  const dateB = moment(b.date);

  const isAfterA = moment().isAfter(dateA);

  const isAfterB = moment().isAfter(dateB);

  if (isAfterA && !isAfterB) {
    return 1;
  } else if (!isAfterA && isAfterB) {
    return -1;
  } else {
    return 0;
  }
}

export default function EmployeeAppointment({ onDiscart, data }) {
  const { user } = useAuth();
  const [filter, setFilter] = useState();
  const [selected, setSelected] = useState();
  const [openModal, setOpenModal] = useState();
  const toaster = useToaster();
  const { setException } = useException();
  const blockSchedule = usePermission(3);
  const cancelSchedule = usePermission(6);

  const { data: dataRecurrence, loading } = useQuery(
    GET_APPOINTMENT_RECURRENCE,
    {
      variables: {
        filter,
      },
      skip: !filter,
    }
  );

  useEffect(() => {
    if (!loading && dataRecurrence?.appointmentRecurrence?.length) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, [loading, dataRecurrence]);

  const appointments = useMemo(() => {
    return data?.appointments
      ?.filter((res) => {
        if (!cancelSchedule) {
          return !res.canceledAt;
        }

        return res;
      })
      .filter((res) => {
        if (!blockSchedule) {
          return res.order?.type !== "BLOCKED";
        }
        return res;
      })
      ?.map((item) => ({
        ...item,
        active: user?.manager
          ? true
          : moment(item.date).isSameOrAfter(
              moment().format("YYYY-MM-DD HH:mm")
            ),
      }));
  }, [data?.appointments, cancelSchedule, blockSchedule, user?.manager]);

  const [discartAppointment, { loading: discartLoading }] = useMutation(
    DISCART_APPOINTMENT_MUTATION
  );

  async function onDiscartRecurrence(value) {
    try {
      await discartAppointment({
        variables: {
          appointment: {
            id: value.id,
            date: value.date,
            link: window.location.origin,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          getOperationName(GET_APPOINTMENT_RECURRENCE),
          getOperationName(GET_APPOINTMENTS),
        ],
      });
      toaster.push(
        MessageAlert("Agendamento cancelado com sucesso.", TYPE_MSG.SUCCESS)
      );
    } catch (error) {
      setException(error);
    }
  }

  return (
    <>
      {!appointments?.length ? (
        <div className="flex items-center justify-center">
          <AppointmentTimelineEmpty name="Nenhum agendamento encontrado até o momento." />
        </div>
      ) : (
        appointments?.sort(compareDates)?.map((res) => (
          <div key={res.id}>
            <CardAppointment
              data={res}
              onDiscart={() => onDiscart(res)}
              onDiscartRecurrence={() => {
                setFilter({
                  date: res.date,
                  employeeId: res?.employee?.id,
                  userId: res?.userClient?.id,
                  orderId: res?.order?.id,
                  appointmentId: res?.appointment?.id,
                });
              }}
            />
          </div>
        ))
      )}
      {openModal && (
        <AlertModal
          open={openModal}
          cancelAction={() => {
            setOpenModal(false);
            setFilter();
          }}
          title="Agendamento"
          message="Deseja cancelar algum agendamento?"
          cancelLabel="Ok"
          confirmAction={onDiscart}
          options={
            dataRecurrence?.appointmentRecurrence?.filter(
              (res) => !res.canceledAt
            ) || []
          }
          onDiscartAppointment={(e) => {
            setSelected(e);
            onDiscartRecurrence(e);
          }}
          icon={<Spinner />}
          loading={loading || discartLoading}
          selected={selected}
        />
      )}
    </>
  );
}
