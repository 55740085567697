import { gql } from "@apollo/client";

export const GET_EMPLOYEE_DETAIL = gql`
  query employee($id: Int) {
    employee(id: $id) {
      id
      name
      phone
      active
      createdAt
      updatedAt
      activeAppointment
    }
  }
`;

export const GET_OPERATIONS = gql`
  query operations($filter: OperationFilter) {
    operations(filter: $filter) {
      id
      dayWeek
      startDate
      startBrake
      endBrake
      endDate
      createdAt
      updatedAt
      employee {
        id
        name
      }
    }
  }
`;

export const GET_EMPLOYEE = gql`
  query employee($id: Int) {
    employee(id: $id) {
      id
      name
      phone
      active
      createdAt
      updatedAt
      activeAppointment
      employeeType {
        id
        name
      }
      service {
        id
        name
        duration
        price
        serviceFixed
        servicePercent
        active
      }
      state {
        id
        name
        uf
      }
      city {
        id
        name
      }
      userClient {
        id
        name
      }
    }
  }
`;

export const GET_EMPLOYEES = gql`
  query employees($filter: EmployeeFilter) {
    employees(filter: $filter) {
      id
      name
      phone
      active
      createdAt
      updatedAt
      activeAppointment
      employeeType {
        id
        name
      }
      service {
        id
      }
      operation {
        id
      }
      state {
        id
        name
        uf
      }
      city {
        id
        name
      }
    }
  }
`;

export const GET_USERS = gql`
  query userClients($filter: UserClientFilter) {
    userClients(filter: $filter) {
      id
      name
      phone
      createdAt
      updatedAt
    }
  }
`;

export const GET_APPOINTMENTS = gql`
  query appointments($filter: AppointmentFilter) {
    appointments(filter: $filter) {
      id
      date
      canceledAt
      createdAt
      updatedAt
      employee {
        id
        name
      }
      userClient {
        id
        name
      }
      order {
        price
        type
        totalProduct
        totalService
        totalDuration
      }
    }
  }
`;

export const GET_SERVICES = gql`
  query services($filter: ServiceFilter) {
    services(filter: $filter) {
      id
      name
      duration
      price
      createdAt
      updatedAt
      comission
      serviceFixed
      servicePercent
      active
    }
  }
`;

export const GET_DURATION = gql`
  query serviceDuration($filter: ServiceFilter) {
    serviceDuration(filter: $filter) {
      duration
    }
  }
`;

export const GET_SERVICE = gql`
  query service($id: Int) {
    service(id: $id) {
      id
      name
      duration
      price
      comission
      serviceFixed
      servicePercent
      active
    }
  }
`;

export const GET_STATES = gql`
  query states($filter: StateFilter) {
    states(filter: $filter) {
      id
      name
    }
  }
`;

export const GET_CITIES = gql`
  query cities($filter: CityFilter) {
    cities(filter: $filter) {
      id
      name
    }
  }
`;

export const GET_EMPLOYEE_TYPES = gql`
  query employeeTypes($filter: EmployeeTypeFilter) {
    employeeTypes(filter: $filter) {
      id
      name
      active
      createdAt
      updatedAt
    }
  }
`;

export const GET_USER_CLIENTS = gql`
  query userClients($filter: UserClientFilter) {
    userClients(filter: $filter) {
      id
      name
      active
      companyIdentifier
      employeeIdentifier
      createdAt
      updatedAt
      phone
    }
  }
`;

export const CREATE_APPOINTMENT_LOCK_MUTATION = gql`
  mutation createAppointmentLock($appointment: AppointmentInput) {
    createAppointmentLock(appointment: $appointment)
  }
`;

export const CREATE_APPOINTMENT_MUTATION = gql`
  mutation createAppointment($appointment: AppointmentInput) {
    createAppointment(appointment: $appointment) {
      id
    }
  }
`;

export const CREATE_APPOINTMENT_RECURRENCE_MUTATION = gql`
  mutation createAppointmentRecurrence($appointment: AppointmentInput) {
    createAppointmentRecurrence(appointment: $appointment) {
      date
      scheduled
    }
  }
`;

export const CREATE_WAITING_LIST_MUTATION = gql`
  mutation createWaitingList($waitingList: WaitingListInput) {
    createWaitingList(waitingList: $waitingList) {
      id
    }
  }
`;

export const CREATE_EMPLOYEE_MUTATION = gql`
  mutation createEmployee($employee: EmployeeInput) {
    createEmployee(employee: $employee) {
      id
    }
  }
`;

export const UPDATE_EMPLOYEE_MUTATION = gql`
  mutation updateEmployee($employee: EmployeeInput) {
    updateEmployee(employee: $employee) {
      id
    }
  }
`;

export const CREATE_OR_UPDATE_OPERATION_MUTATION = gql`
  mutation createOrUpdateOperation(
    $operation: [OperationInput]
    $employeeId: Int
  ) {
    createOrUpdateOperation(operation: $operation, employeeId: $employeeId)
  }
`;

export const CREATE_SERVICE_MUTATION = gql`
  mutation createService($service: ServiceInput, $employeeId: Int) {
    createService(service: $service, employeeId: $employeeId)
  }
`;

export const UPDATE_SERVICE_MUTATION = gql`
  mutation updateService($service: ServiceInput, $employeeId: Int) {
    updateService(service: $service, employeeId: $employeeId)
  }
`;

export const DISCART_SERVICE_MUTATION = gql`
  mutation discartService($id: Int) {
    discartService(id: $id)
  }
`;

export const DISCART_APPOINTMENT_MUTATION = gql`
  mutation discartAppointment($appointment: AppointmentInput) {
    discartAppointment(appointment: $appointment) {
      id
    }
  }
`;
