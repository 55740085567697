import React, { useMemo, useState } from "react";
import { useAuth } from "../../../hooks/use-auth";
import { GET_POINTS_GROUP } from "../queries";
import { useQuery } from "@apollo/client";
import unaccent from "../../../utils/unaccent";
import { MagnifyingGlass } from "@phosphor-icons/react";
import Animation from "../../../Animation/Animation";
import Hand from "../../../Animation/hand.json";
import CardPoint from "../../../components/Card/card-point";
import ModalPointList from "./modal-point-list";

export default function PointList({ buttonSideSelected }) {
  const { user } = useAuth();
  const [searchText, setSearchText] = useState();
  const [selected, setSelected] = useState();
  const [showPointList, setShowPointList] = useState();

  const isMasterOrManager = useMemo(
    () => user?.master || user?.manager,
    [user]
  );

  const { data, loading } = useQuery(GET_POINTS_GROUP, {
    variables: {
      filter: {
        companyId: isMasterOrManager ? user?.company?.id : null,
        userId: !isMasterOrManager ? user?.id : null,
      },
    },
    skip: buttonSideSelected !== "POINTS",
  });

  const items = useMemo(() => {
    return data?.pointsGroup?.filter((item) =>
      searchText
        ? unaccent(item?.name?.toLowerCase())?.includes(
            unaccent(searchText?.toLowerCase())
          )
        : item
    );
  }, [data?.pointsGroup, searchText]);

  const formLoading = useMemo(() => loading, [loading]);
  return (
    <>
      <div className="flex flex-col w-full h-full gap-4 p-4 overflow-hidden">
        <div className="flex justify-between w-full">
          <div className="flex flex-col w-full text-slate-400">
            <strong className="text-xl">Encontre pelo nome</strong>
          </div>
        </div>
        <div className="flex items-center w-full h-full gap-2 rounded-md">
          <div className="flex items-center w-full gap-2 p-3 text-white rounded-md bg-primary">
            <input
              className="w-full text-white bg-transparent outline-none placeholder:text-white"
              placeholder="Buscar pelo nome"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <MagnifyingGlass size={20} />
          </div>
        </div>
        <div className="flex flex-col max-h-[calc(100vh-221px)] overflow-auto gap-2 pr-4 ">
          {formLoading ? (
            <div className="fixed flex items-center justify-center w-full h-full -translate-x-1/2 -translate-y-1/2 bg-black bg-opacity-50 top-1/2 left-1/2 ">
              <Animation
                width={150}
                height={150}
                animation={Hand}
                loop={true}
              />
            </div>
          ) : (
            items?.map((item) => (
              <CardPoint
                key={item.id}
                data={item}
                isMasterOrManager={isMasterOrManager}
                onShowPoint={() => {
                  setShowPointList(true);
                  setSelected(item);
                }}
              />
            ))
          )}
        </div>
        {showPointList && (
          <ModalPointList
            selected={selected}
            open={showPointList}
            onClose={() => {
              setShowPointList(false);
              setSelected();
            }}
          />
        )}
      </div>
    </>
  );
}
