import React from "react";
import LinkButton from "./LinkButton";
import { List } from "@phosphor-icons/react";

export default function Header({ links = [], onClick, menuOpen, onMenuOpen }) {
  return (
    <div className="flex items-center justify-between w-full xl:max-w-7xl">
      <div className="flex items-center w-full gap-4">
        <img
          src="./ageendae.png"
          alt="Logo Ageendae"
          className="object-cover w-48 cursor-pointer"
          onClick={onClick}
        />
      </div>
      <div className="flex items-center justify-center w-full gap-8 sm:hidden md:hidden lg:hidden">
        {links?.map((item, i) => (
          <LinkButton
            key={i}
            path={item.path}
            label={item.label}
            action={item.action}
          />
        ))}
      </div>
      <button
        className="hidden text-primary sm:flex md:flex lg:flex"
        onClick={onMenuOpen}
      >
        <List size={30} />
      </button>
      <button
        onClick={onMenuOpen}
        className={`fixed top-[84px] left-0 p-4 text-primary gap-4 items-center ${
          menuOpen ? "flex" : "hidden"
        } flex-col w-full bg-secondary`}
      >
        {links?.map((item, i) => (
          <LinkButton
            key={i}
            path={item.path}
            label={item.label}
            action={item.action}
          />
        ))}
      </button>
      <button
        onClick={() =>
          window.open(
            "https://api.whatsapp.com/send?phone=5547984832451&text=Quero%20testar%20o%20aplicativo%20de%20agendamento.",
            "_blank"
          )
        }
        className="flex justify-center px-4 py-2 text-white rounded-md sm:hidden md:hidden lg:hidden h-fit w-fit bg-primary whitespace-nowrap"
      >
        <strong>Teste grátis por 30 dias</strong>
      </button>
    </div>
  );
}
