import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, EffectCube, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
const resources = [
  {
    title: "Funcionários",
    imageUrl: "./mobileEmployee.png",
  },
  {
    title: "Serviços",
    imageUrl: "./mobileService.png",
  },
  {
    title: "Agendamento",
    imageUrl: "./mobileAppointment.png",
  },
  {
    title: "Agenda",
    imageUrl: "./mobile.png",
  },
];

export function CarouselCustomNavigation() {
  return (
    <Swiper
      effect={"coverflow"}
      grabCursor={true}
      centeredSlides={true}
      slidesPerView={"auto"}
      coverflowEffect={{
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      }}
      pagination={true}
      modules={[EffectCoverflow, Pagination]}
    >
      {resources?.map((res) => (
        <SwiperSlide>
          <img
            src={res.imageUrl}
            alt={res.title}
            className="object-contain h-[500px] w-full"
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
