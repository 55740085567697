import { gql } from "@apollo/client";

export const GET_COMPANIES_BUSINESS = gql`
  query companyByBusiness($filter: CompanyFilter) {
    companyByBusiness(filter: $filter) {
      id
      name
      description
      timezone
      city {
        id
        name
      }
      state {
        id
        name
        uf
      }
      schema
    }
  }
`;

export const GET_STATES = gql`
  query states($filter: StateFilter) {
    states(filter: $filter) {
      id
      name
    }
  }
`;

export const GET_CITIES = gql`
  query cities($filter: CityFilter) {
    cities(filter: $filter) {
      id
      name
    }
  }
`;
