import React from "react";
import { UilMultiply } from "@iconscout/react-unicons";
import { Drawer } from "rsuite";

export default function Modal({
  title,
  subTitle,
  open,
  onClose,
  children,
  img,
  placement = "bottom",
}) {
  return (
    <Drawer
      backdrop="static"
      open={open}
      onClose={onClose}
      placement={placement}
      style={{
        display: "inline-block",
        backgroundColor: "transparent",
        minHeight: "100vh",
      }}
    >
      <div className="flex flex-col bg-white h-auto max-h-[85%] rounded-tl-[50px] fixed bottom-0 w-full">
        <div className="flex w-full gap-2">
          <div className="flex ml-10 -mt-10 rounded-md w-28 h-28">
            <img
              src={img}
              alt=""
              width={112}
              height={112}
              className="object-cover"
            />
          </div>
          <div className="flex flex-col flex-1 p-4">
            <strong className="text-primary">{title}</strong>
            <span>{subTitle}</span>
          </div>
        </div>
        <button
          onClick={onClose}
          className="absolute flex items-center justify-center w-10 h-10 bg-white rounded-full shadow-md -top-6 right-5"
        >
          <UilMultiply size={20} weight="fill" className="text-primary" />
        </button>
        <div className="flex flex-col h-full gap-4 p-4 overflow-auto">
          {children}
        </div>
      </div>
    </Drawer>
  );
}
