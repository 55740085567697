import { gql } from "@apollo/client";

export const GET_PEOPLE_APPOINTMENTS = gql`
  query peopleAppointments($filter: AppointmentFilter) {
    peopleAppointments(filter: $filter) {
      id
      date
      canceledAt
      createdAt
      updatedAt
      company {
        id
        name
      }
      employee {
        id
        name
      }
      userClient {
        id
        name
        phone
      }
      order {
        price
        type
        orderService {
          id
          name
          duration
          quantity
          price
          plan
          service {
            id
            name
          }
        }
      }
    }
  }
`;
