import { TextField } from "@mui/material";
import { styled } from "@mui/styles";
import { useField } from "formik";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#575757",
    fontSize: 14,
  },
  "& label": {
    color: "#575757",
    fontSize: 14,
  },
  "& input": {
    color: "#575757",
    fontSize: 14,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#575757",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#575757",
    },
    "&:hover fieldset": {
      borderColor: "#575757",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#575757",
    },
    "&:after fieldset": {
      borderColor: "#575757",
    },
  },
});

function NumberField({
  attribute: {
    label,
    name,
    type,
    disabled,
    visibled,
    onChange,
    width = "w-full",
    visible = false,
  },
}) {
  const [field, meta] = useField(name);
  return (
    <div className={`${visible ? "hidden" : "flex"} ${width}`}>
      {!visibled && (
        <CssTextField
          {...field}
          error={!!meta.error}
          helperText={meta.error}
          type={type}
          label={label}
          name={name}
          variant="outlined"
          fullWidth
          disabled={disabled}
          visibled={visibled}
          onChange={(e) => {
            if (onChange) {
              onChange(e);
            }
            field.onChange(e);
          }}
          InputLabelProps={{
            shrink: !!field.value,
          }}
        />
      )}
    </div>
  );
}

export default NumberField;
