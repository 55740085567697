import React from "react";
import { useField, useFormikContext } from "formik";

function ToggleField({
  attribute: {
    options = [],
    id,
    name,
    label,
    required,
    disabled,
    index,
    onChange,
  },
  loading,
}) {
  const [field, meta] = useField(name);
  const { values, setFieldValue } = useFormikContext();

  const disabledWrapper = () => {
    if (loading) {
      return true;
    }

    return typeof disabled === "function" ? disabled(values, index) : disabled;
  };

  return (
    <strong className="flex justify-between w-full gap-4 text-center text-white bg-white rounded-md">
      {!loading &&
        options &&
        options?.map(
          ({ value, label: toggleLabel, disabled: optionDisabled }, i) => (
            <button
              {...field}
              key={i}
              disabled={optionDisabled || disabledWrapper()}
              type="button"
              className={`flex items-center justify-center w-full p-2 rounded-md border-2 ${
                field.value === value
                  ? "bg-primary text-white"
                  : "bg-white  text-primary"
              }`}
              onClick={() => {
                if (required && value === null) {
                  return;
                }

                setFieldValue(name, value);
                if (onChange) {
                  onChange(value, values, index);
                }
              }}
            >
              <span>{toggleLabel}</span>
            </button>
          )
        )}
    </strong>
  );
}

export default ToggleField;
