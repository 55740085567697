import { ArrowLeft } from "@phosphor-icons/react";
import React, { useMemo } from "react";
import * as Yup from "yup";
import AForm from "../../components/Form";
import Modal from "../../components/Modal";
import { useAuth } from "../../hooks/use-auth";
import Button from "../../components/Button";
import { InputTypes } from "../../components/Input";
import APhoneMask from "../../components/Mask/a-phone-number.mask";
import FormFields from "../../components/Form/form-fields";
import useException from "../../hooks/use-exception";
import { useToaster } from "rsuite";
import { CREATE_USER_MUTATION } from "./queries";
import { useMutation } from "@apollo/client";
import MessageAlert, { TYPE_MSG } from "../../components/Message/message";
import Spinner from "../../components/Spinner";

function Form({ queryLoading, onLogin }) {
  return (
    <div className="flex flex-col items-center justify-center w-full gap-4">
      <FormFields
        loading={queryLoading}
        fields={[
          {
            type: InputTypes.TEXT,
            label: "Nome",
            name: "name",
            width: "w-full",
          },
          {
            type: InputTypes.TEXT,
            label: "Whastapp",
            name: "phone",
            width: "w-full",
            mask: APhoneMask,
          },
          {
            type: InputTypes.PASSWORD,
            label: "Senha",
            name: "password",
            width: "w-full",
          },
        ]}
      />
      <div className="flex flex-col w-full gap-2">
        <Button
          label="Cadastrar"
          className="w-full p-3 text-white rounded-md bg-primary hover:bg-tertiary"
          type="submit"
          icon={<Spinner />}
          loading={queryLoading}
        />

        <Button
          label="Já possui conta?"
          className="flex items-center justify-center w-full gap-4 p-3 bg-gray-200 rounded-md"
          onAction={onLogin}
          icon={<ArrowLeft size={20} />}
          type="button"
        />
      </div>
    </div>
  );
}

export default function Register({ open, onClose, title, subTitle, onLogin }) {
  const { loading } = useAuth();
  const toaster = useToaster();
  const { setException } = useException();

  const [create, { loading: createLoading }] =
    useMutation(CREATE_USER_MUTATION);

  async function onSubmit(values) {
    try {
      await create({
        variables: {
          user: {
            name: values.name,
            phone: values.phone,
            password: values.password,
            active: true,
          },
        },
      });
      toaster.push(MessageAlert("Conta criada com sucesso.", TYPE_MSG.SUCCESS));
      onLogin();
    } catch (error) {
      setException(error);
    }
  }

  const queryLoading = useMemo(
    () => loading || createLoading,
    [loading, createLoading]
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
      subTitle={subTitle}
      img="/./ios/192.png"
    >
      <div className="flex flex-col items-center justify-center p-4">
        <AForm
          initialValues={{ username: "", phone: "", password: "" }}
          onSubmit={onSubmit}
          formComponent={Form}
          formProps={{ queryLoading, onLogin }}
          validationSchema={Yup.object({
            name: Yup.string().required("Campo obrigatório"),
            phone: Yup.string().required("Campo obrigatório"),
            password: Yup.string().required("Campo obrigatório"),
          })}
        />
      </div>
    </Modal>
  );
}
