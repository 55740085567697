import React from "react";
import { useField, useFormikContext } from "formik";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import styled from "@emotion/styled";

const CssTextField = styled(DesktopTimePicker)({
  "& label.Mui-focused": {
    color: "#C7DFE9",
    fontSize: 12,
  },

  "& label": {
    color: "#64748B",
    fontSize: 12,
  },
  "& input": {
    color: "#64748B",
    fontSize: 12,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#C7DFE9",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#64748B",
    },
    "&:hover fieldset": {
      borderColor: "#C7DFE9",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#C7DFE9",
    },
    "&:after fieldset": {
      borderColor: "#C7DFE9",
    },
  },
});

function DatetimeField({
  attribute: {
    name,
    label,
    disabled,
    width,
    views = ["hours", "minutes", "seconds"],
    format = "HH:mm:ss",
  },
  loading,
}) {
  const [field, meta] = useField(name);
  const { setFieldValue, setFieldTouched, values } = useFormikContext();

  const disabledWrapper = () => {
    if (loading) {
      return true;
    }

    return typeof disabled === "function" ? disabled(values) : disabled;
  };

  return (
    <div className={`flex ${width}`}>
      {!loading && (
        <CssTextField
          {...field}
          label={label}
          ampm={false}
          views={views}
          value={field?.value}
          format={format}
          disabled={disabledWrapper()}
          inputVariant="filled"
          error={!!meta.error}
          helperText={meta.error}
          onChange={(date) => {
            setFieldValue(name, date, true);
            setFieldTouched(name, true, false);
          }}
          id={name.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "")}
          data-testid={name.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "")}
        />
      )}
    </div>
  );
}

export default DatetimeField;
