import { useQuery } from "@apollo/client";
import React, { useMemo, useState } from "react";
import { GET_SUPPLIERS } from "../queries";
import unaccent from "../../../utils/unaccent";
import { useAuth } from "../../../hooks/use-auth";
import { MagnifyingGlass, Plus } from "@phosphor-icons/react";
import Animation from "../../../Animation/Animation";
import Hand from "../../../Animation/hand.json";
import CardProduct from "../../../components/Card/card-product";
import SupplierForm from "./supplier-form";

export default function SupplierList({ buttonSideSelected }) {
  const { user, loading } = useAuth();
  const [id, setId] = useState();
  const [searchText, setSearchText] = useState();
  const [openSupplierForm, setOpenSupplierForm] = useState();

  const { data, loading: loadingSupplier } = useQuery(GET_SUPPLIERS, {
    skip: buttonSideSelected !== "SUPPLIER",
  });

  const isMasterOrManager = useMemo(
    () => (!loading && user?.master) || user?.manager,
    [user, loading]
  );

  const items = useMemo(() => {
    return data?.suppliers?.filter((item) =>
      searchText
        ? unaccent(item?.name?.toLowerCase())?.includes(
            unaccent(searchText?.toLowerCase())
          )
        : item
    );
  }, [data, searchText]);

  const formLoading = useMemo(
    () => loading || loadingSupplier,
    [loading, loadingSupplier]
  );

  return (
    <>
      <div className="flex flex-col w-full h-full gap-4 p-4 overflow-hidden">
        <div className="flex justify-between w-full">
          <div className="flex flex-col w-full text-slate-400">
            <strong className="text-xl">Encontre o fornecedor</strong>
          </div>
        </div>
        <div className="flex items-center w-full h-full gap-2 rounded-md">
          <div className="flex items-center w-full gap-2 p-3 text-white rounded-md bg-primary">
            <input
              className="w-full text-white bg-transparent outline-none placeholder:text-white"
              placeholder="Buscar pelo nome"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <MagnifyingGlass size={20} />
          </div>
          {isMasterOrManager && (
            <button
              onClick={() => setOpenSupplierForm(true)}
              className="flex items-center h-[45px] p-3 text-white rounded-md bg-primary"
            >
              <Plus size={20} />
            </button>
          )}
        </div>
        <div className="flex flex-col max-h-[calc(100vh-221px)] overflow-auto gap-2 pr-4 ">
          {formLoading ? (
            <div className="fixed flex items-center justify-center w-full h-full -translate-x-1/2 -translate-y-1/2 bg-black bg-opacity-50 top-1/2 left-1/2 ">
              <Animation
                width={150}
                height={150}
                animation={Hand}
                loop={true}
              />
            </div>
          ) : (
            items?.map((item) => (
              <CardProduct
                key={item.id}
                data={item}
                type={buttonSideSelected}
                onEdit={() => {
                  setId(item.id);
                  setOpenSupplierForm(true);
                }}
              />
            ))
          )}
        </div>
        {openSupplierForm && (
          <SupplierForm
            id={id}
            open={openSupplierForm}
            onClose={() => {
              setOpenSupplierForm(false);
            }}
          />
        )}
      </div>
    </>
  );
}
