import React from "react";
import NumberFormat from "react-number-format";

function PercentageMask(props) {
  const { inputRef, onChange, value, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      value={value}
      suffix=" %"
      decimalScale={2}
      inputMode="decimal"
      decimalSeparator=","
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value:
              values.floatValue === 0
                ? values.floatValue?.toString()
                : values.floatValue,
          },
        });
      }}
    />
  );
}

export default PercentageMask;
