import React from "react";
import { Skeleton } from "@mui/material";
import formatCurrency from "../../../utils/formatCurrency";
import { Medal } from "@phosphor-icons/react";

export default function FinanceCard({
  label,
  value,
  className,
  description,
  options = [],
  iconAction,
  action,
  type = "Agendamentos",
  format = false,
  show,
  ranking,
}) {
  return (
    <div className={className}>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          {!show && (
            <Skeleton
              variant="rectangular"
              height={28}
              width={100}
              className="rounded-md"
            />
          )}
          {show && (
            <>
              <strong className="text-2xl">
                {format ? formatCurrency(value) : value}
              </strong>
              <strong>{label}</strong>
            </>
          )}
        </div>
        <button onClick={action}>{iconAction}</button>
      </div>
      {options.length > 0 ? (
        <>
          {options?.map((doc, i) => (
            <div key={i} className="flex items-center gap-4">
              {!ranking ? (
                <span className={doc.className} />
              ) : (
                <Medal
                  size={20}
                  className={`${
                    i === 0
                      ? "text-amber-400"
                      : i === 1
                      ? "text-stone-500"
                      : "text-yellow-600"
                  }`}
                />
              )}
              <div className="flex flex-col w-full">
                <strong>{doc.label}</strong>
                <div className="flex items-center justify-start gap-1">
                  {show ? (
                    <>
                      <span>{doc?.value}</span>
                      <span className="mt-1 text-sm">{type}</span>
                    </>
                  ) : (
                    <>
                      <Skeleton
                        variant="rectangular"
                        width={24}
                        height={24}
                        className="rounded-md"
                      />
                      <Skeleton
                        variant="rectangular"
                        width={60}
                        height={24}
                        className="rounded-md"
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <strong className="font-thin text-gray-500">{description}</strong>
      )}
    </div>
  );
}
