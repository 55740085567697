import React from "react";
import Animation from "../../../Animation/Animation";
import Site from "../../../Animation/site.json";

export default function Home() {
  return (
    <div className="flex items-center justify-center w-full h-full sm:flex-col md:flex-col lg:flex-col">
      <div className="flex flex-col justify-center w-full h-full gap-2 sm:items-center md:items-center lg:items-center ">
        <strong className="text-lg text-primary sm:text-center md:text-center lg:text-center">
          Aplicativo de Agendamento On-line
        </strong>
        <strong className="sm:text-center text-7xl sm:text-3xl md:text-center lg:text-center md:text-3xl lg:text-3xl">
          Simplifique o processo de agendamento dos seus serviços.
        </strong>
        <span className="text-lg sm:text-center md:text-center lg:text-center">
          Já considerou a possibilidade dos seus clientes agendarem horários de
          forma autônoma, sem a necessidade de contato direto pelo WhatsApp?
          Agora isso se tornou uma realidade!
        </span>
        <button
          onClick={() =>
            window.open(
              "https://api.whatsapp.com/send?phone=5547984832451&text=Quero%20testar%20o%20aplicativo%20de%20agendamento.",
              "_blank"
            )
          }
          className="flex items-center justify-center px-4 py-2 mt-4 text-white rounded-md h-fit w-fit bg-primary whitespace-nowrap"
        >
          <strong>Teste grátis por 30 dias</strong>
        </button>
      </div>
      <div className="flex flex-col justify-center w-full h-full">
        <Animation animation={Site} loop={true} />
      </div>
    </div>
  );
}
