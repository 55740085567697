import React, { useMemo, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useToaster } from "rsuite";
import { useNavigate } from "react-router-dom";
import {
  GET_CITIES,
  GET_COMPANIES,
  GET_COMPANIES_BUSINESS,
  GET_STATES,
} from "./queries";
import Search from "./components/Search";
import AutoComplete from "./components/Autocomplete";
import Button from "./components/Button";
import CardCompany from "./components/CardCompany";
import MessageAlert, { TYPE_MSG } from "../../components/Message/message";

export default function Company() {
  const toaster = useToaster();
  const navigate = useNavigate();
  const [name, setName] = useState();
  const [state, setState] = useState();
  const [city, setCity] = useState();

  const [getCompanies, { data: dataCompanies, loading: loadingCompanies }] =
    useLazyQuery(GET_COMPANIES_BUSINESS);

  const { data: dataStates, loading: loadingStates } = useQuery(GET_STATES, {
    variables: {
      filter: { active: true },
    },
  });

  const { data: dataCities, loading: loadingCities } = useQuery(GET_CITIES, {
    variables: {
      filter: { stateId: state },
    },
    skip: !state,
  });

  const queryLoading = useMemo(
    () => loadingStates || loadingCities || loadingCompanies,
    [loadingStates, loadingCities, loadingCompanies]
  );

  return (
    <>
      <div className="flex flex-col w-screen h-screen">
        <div className="flex flex-col items-center justify-center w-full pb-4 h-fit bg-secondary">
          <div className="flex flex-col items-center justify-center p-4">
            <strong className="text-lg text-orange-600">
              Encontre o estabelecimento
            </strong>
          </div>
          <div className="flex flex-wrap w-3/4 gap-4 p-4 bg-white rounded-md sm:flex-col sm:flex-nowrap">
            <Search
              label="Nome"
              onChange={(values) => setName(values.target.value)}
            />
            <AutoComplete
              data={dataStates?.states?.map((item) => ({
                id: item.id,
                label: item.name,
              }))}
              loading={queryLoading}
              label="Estado"
              onChange={(_, values) => setState(values?.id)}
            />
            <AutoComplete
              data={dataCities?.cities?.map((item) => ({
                id: item.id,
                label: item.name,
              }))}
              loading={queryLoading}
              label="Cidade"
              onChange={(_, values) => setCity(values?.id)}
              disabled={!state}
            />
            <Button
              onChange={() =>
                getCompanies({
                  variables: {
                    filter: {
                      name,
                      stateId: state,
                      cityId: city,
                      active: true,
                    },
                  },
                })
              }
            />
          </div>
        </div>
        <div className="flex flex-col h-full gap-4 p-4 overflow-hidden">
          <div className="flex items-center justify-center w-full">
            <strong className="text-lg text-orange-600">
              Estabelecimentos
            </strong>
          </div>
          <div className="grid h-auto gap-4 overflow-auto sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
            {dataCompanies?.companyByBusiness?.map((item, i) => (
              <CardCompany
                key={i}
                name={item.name}
                cidade={`${item.city?.name} - ${item.state?.uf}`}
                description={item.description}
                schema={item.schema}
                onChange={() => {
                  toaster.push(
                    MessageAlert(
                      "Redirecionando para empresa.",
                      TYPE_MSG.SUCCESS
                    )
                  );

                  localStorage.setItem("company", item.schema);
                  navigate(`/${item.schema}`);
                }}
              />
            ))}
          </div>
        </div>
        <button
          onClick={() => {
            window.open(`https://wa.me/message/JS7BDQR6CO5JB1`);
          }}
          className="fixed p-3 bg-green-800 rounded-full bottom-8 right-8 animate-bounce"
        >
          <img src="./imgs/whatsapp.svg" width={25} alt="" />
        </button>
      </div>
    </>
  );
}
