import React, { useMemo } from "react";
import * as Yup from "yup";
import Modal from "../../../components/Modal";
import AForm from "../../../components/Form";
import MessageAlert, { TYPE_MSG } from "../../../components/Message/message";
import { useAuth } from "../../../hooks/use-auth";
import useException from "../../../hooks/use-exception";
import { useToaster } from "rsuite";
import { InputTypes } from "../../../components/Input";
import FormFields from "../../../components/Form/form-fields";
import { useFormikContext } from "formik";
import Button from "../../../components/Button";
import { useMutation } from "@apollo/client";
import { UPDATE_PROFILE_MUTATION } from "../queries";

function Form({ formLoading }) {
  const { values, initialValues } = useFormikContext();

  return (
    <div className="flex flex-col items-center justify-center w-full h-full gap-4 p-4">
      <div className="flex flex-col justify-center w-full h-full gap-4 items-cente">
        <FormFields
          loading={formLoading}
          fields={[
            {
              type: InputTypes.PASSWORD,
              label: "Senha atual",
              name: "currentPassword",
              width: "w-full",
            },
            {
              type: InputTypes.PASSWORD,
              label: "Nova senha",
              name: "password",
              width: "w-full",
            },
          ]}
        />
      </div>
      <div
        className={`${
          initialValues === values ? "hidden" : "flex"
        } flex-col justify-end w-full gap-4`}
      >
        <Button
          label="Salvar"
          className="w-full p-3 text-white rounded-md bg-primary hover:bg-tertiary"
          type="submit"
          disabled={formLoading}
        />
      </div>
    </div>
  );
}

export default function ChangePasswordForm({ open, onClose }) {
  const { loadUser, user } = useAuth();
  const { setException } = useException();
  const toaster = useToaster();

  const [update, { loading }] = useMutation(UPDATE_PROFILE_MUTATION);

  const formLoading = useMemo(() => loading, [loading]);

  async function onSubmit(values) {
    try {
      await update({
        variables: {
          user: {
            id: user?.id,
            currentPassword: values?.currentPassword,
            password: values?.password,
          },
        },
      });
      toaster.push(
        MessageAlert("Senha atualizada com sucesso.", TYPE_MSG.SUCCESS)
      );
      loadUser();
      onClose();
    } catch (error) {
      setException(error);
    }
  }

  return (
    <Modal
      title="Senha"
      subTitle="Alterar senha"
      open={open}
      onClose={onClose}
      img="/./ios/180.png"
    >
      <AForm
        onSubmit={onSubmit}
        initialValues={{ currentPassword: "", password: "" }}
        formComponent={Form}
        validationSchema={Yup.object({
          currentPassword: Yup.string().required("Campo obrigatório"),
          password: Yup.string().required("Campo obrigatório"),
        })}
        formProps={{ formLoading }}
      />
    </Modal>
  );
}
