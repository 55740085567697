import React from "react";
import Modal from "../../../components/Modal";

export default function ServiceList({ open, onClose, data }) {
  console.log(data);
  return (
    <>
      <Modal
        title="Classificação"
        subTitle="Lista de produtos"
        open={open}
        onClose={onClose}
        img="/./ios/180.png"
      >
        <div className="flex flex-col w-full h-auto gap-2 ">
          {data
            ?.sort((a, b) => b.quantity - a.quantity)
            ?.map((item, i) => (
              <div
                key={i}
                className={`flex w-full border-l-8 ${
                  i <= 2 ? "border-green-600" : "border-primary"
                } rounded-md shadow bg-secondary`}
              >
                <div className="flex items-center p-4">
                  <strong>#{i + 1}</strong>
                </div>
                <div className="flex items-center w-full p-4">
                  <strong className="w-full">{item.name}</strong>
                </div>
                <div className="flex items-center p-4">
                  <strong className="w-full">{item.quantity}</strong>
                </div>
              </div>
            ))}
        </div>
      </Modal>
    </>
  );
}
