import React, { useMemo } from "react";
import * as Yup from "yup";
import FormFields from "../../../components/Form/form-fields";
import { InputTypes } from "../../../components/Input";
import Button from "../../../components/Button/index";
import Modal from "../../../components/Modal";
import AForm from "../../../components/Form";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { GET_EMPLOYEES } from "../queries";
import Spinner from "../../../components/Spinner";

function Form({ manager, employees, formLoading }) {
  return (
    <div className="flex flex-col items-center justify-center w-full gap-4 pt-2">
      <FormFields
        loading={formLoading}
        width="grid grid-cols-[1fr,1fr] gap-4 w-full border-b pb-4 justify-items-end"
        fields={[
          {
            type: InputTypes.AUTOCOMPLETE,
            label: "Profissional",
            name: "employee",
            width: "w-full",
            options: employees || [],
            visible: !manager,
          },
          {
            type: InputTypes.DATE,
            label: "Data início",
            name: "startDate",
            width: "w-full",
          },
          {
            type: InputTypes.DATE,
            label: "Data fim",
            name: "endDate",
            width: "w-full",
          },
          {
            type: InputTypes.CHECKBOX,
            label: "Gestor",
            name: "manager",
            width: "w-full",
            visible: !manager,
          },
        ]}
      />
      <div className="flex flex-col w-full gap-4">
        <Button
          label="Filtrar"
          className="w-full p-3 text-white rounded-md bg-primary hover:bg-tertiary"
          type="submit"
          icon={<Spinner />}
          loading={formLoading}
          disabled={formLoading}
        />
      </div>
    </div>
  );
}

export default function FinanceFilter({
  open,
  onClose,
  onFilter,
  title,
  subTitle,
  filter,
  manager,
}) {
  const { data, loading } = useQuery(GET_EMPLOYEES, {
    variables: {
      filter: { active: true },
    },
    skip: !manager,
  });

  async function onSubmit(values) {
    onFilter(values);
  }

  const formLoading = useMemo(() => loading, [loading]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title}
      subTitle={subTitle}
      img="/./ios/192.png"
    >
      <div className="flex flex-col items-center h-full gap-4 p-4">
        <AForm
          initialValues={{
            startDate: moment(filter.startDate),
            endDate: moment(filter.endDate),
            employee: data?.employees?.find(
              (res) => res.id === filter?.employeeId
            ),
            manager: filter?.manager,
          }}
          onSubmit={onSubmit}
          formComponent={Form}
          formProps={{
            manager,
            employees: data?.employees,
            formLoading,
          }}
          validationSchema={Yup.object().shape({
            startDate: Yup.string().required("Campo obrigatório"),
            endDate: Yup.string().required("Campo obrigatório"),
          })}
        />
      </div>
    </Modal>
  );
}
